import { useQuery } from "react-query";
import { HoldService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseHoldUnholdListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  isHold?: boolean | any,
  projectCode?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseHoldUnholdList",
      sorting,
      skipCount,
      maxResultCount,
      isHold,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        HoldService.getApiInboundApiAppHoldHoldUnholdProjectList(
          sorting,
          skipCount,
          maxResultCount,
          isHold,
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseHoldUnholdLicensePlateListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  projectId: string,
  isHold?: boolean,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  crateNumber?: string
) => {
  const responseData = useQuery(
    [
      "getHoldUnholdLicensePlateDetailsView",
      projectId,
      isHold,
      skuPartNumber,
      licensePlateNumber,
      crateNumber,
    ],
    async () => {
      if (projectId) {
        return await handleRequest(
          HoldService.getApiInboundApiAppHoldHoldUnholdLPListByProject(
            projectId,
            isHold,
            skuPartNumber?.trim(),
            licensePlateNumber?.trim(),
            crateNumber?.trim()
          )
        );
      }
    },
    {
      enabled: !!projectId,
    }
  );

  return responseData;
};
