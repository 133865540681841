import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { LocationService } from "../../services/drop-wms-location-services";

export const useGetLocationListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  displayName: string,
  locationTypeId: any,
  warehouseMapId: string,
  baseStationId: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getLocationList",
      sorting,
      skipCount,
      maxResultCount,
      displayName,
      locationTypeId,
      warehouseMapId,
      baseStationId,
    ],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocation1(
          sorting,
          skipCount,
          maxResultCount,
          displayName?.trim(),
          locationTypeId,
          warehouseMapId,
          baseStationId
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetAllLocationListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllLocationList"],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocationLocations()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetLocationDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["getLocationDetailView", id],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocation(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetLocationTypeListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getLocationTypeList"],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocationLocationTypes()
      );
    },
    {
      keepPreviousData: true,
    }
  );
  return responseListData;
};

export const useGetLocationsListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  locationTypeId?: any,
  locationName?: string
) => {
  const responseListData = useQuery(
    ["getAllLocationsList", locationTypeId, locationName],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocationLocations(locationTypeId, locationName?.trim())
      );
    },
    {
      keepPreviousData: true,
      enabled: !!locationTypeId,
    }
  );
  return responseListData;
};

export const useGetLocationsListPaginationData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  locationTypeId?: any,
  locationName?: string
) => {
  const { skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    ["getAllPaginatedLocationsList", skipCount, maxResultCount, locationTypeId, locationName],
    async () => {
      return await handleRequest(
        LocationService.getApiLocationApiAppLocationLocationList(skipCount, maxResultCount, locationTypeId, locationName?.trim())
      );
    },
    {
      keepPreviousData: true,
      enabled: !!locationTypeId,
    }
  );
  return responseListData;
};