/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_OutboundService_Interfaces_Handhelds_UpdateLPOutboundQCChecklistDto } from '../models/Drop_WMS_OutboundService_Interfaces_Handhelds_UpdateLPOutboundQCChecklistDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LPOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LPOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutboundQCChecklistListDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutboundQCChecklistListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HandheldOutboundQcChecklistService {

    /**
     * @param skipCount
     * @param maxResultCount
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutboundQCChecklistListDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundQCChecklistProjectForOutboundQCChecklist(
        skipCount?: number,
        maxResultCount?: number,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutboundQCChecklistListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/project-for-outbound-qCChecklist',
            query: {
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LPOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundQCChecklistLPOrderDetailByOrderId(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LPOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/l-pOrder-detail-by-order-id/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderDetailId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundQCChecklistLPOutboundQCChecklistByOrderDetailId(
        orderDetailId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/l-pOutbound-qCChecklist-by-order-detail-id/{orderDetailId}',
            path: {
                'orderDetailId': orderDetailId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static putApiOutboundApiAppHandheldOutboundQCChecklistLPOutboundQCChecklist(
        requestBody?: Drop_WMS_OutboundService_Interfaces_Handhelds_UpdateLPOutboundQCChecklistDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/l-pOutbound-qCChecklist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppHandheldOutboundQCChecklistBulkUpdateLPOutboundQCChecklist(
        requestBody?: Array<Drop_WMS_OutboundService_Interfaces_Handhelds_UpdateLPOutboundQCChecklistDto>,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/bulk-update-lPOutbound-qCChecklist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderDetailId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundQCChecklistOutboundQCPhotoList(
        orderDetailId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/outbound-qCPhoto-list/{orderDetailId}',
            path: {
                'orderDetailId': orderDetailId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppHandheldOutboundQCChecklistUploadOutboundQCPhoto(
        formData?: {
            OrderDetailId: string;
            Image: Blob;
            Remarks?: string;
        },
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/upload-outbound-qCPhoto',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param outboundQcPhotoId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiOutboundApiAppHandheldOutboundQCChecklistOutboundQCPhoto(
        outboundQcPhotoId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/outbound/api/app/handheld-outbound-qCChecklist/outbound-qCPhoto/{outboundQCPhotoId}',
            path: {
                'outboundQCPhotoId': outboundQcPhotoId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
