import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import pingBg from "../src/assets/images/pink_background.png";
import Navbar from "./components/Navbar";
import Spinners from "./components/Spinner";
import { InnerContainer } from "./constants/style/Common.styled";
import {
  useDropWmsAdministrationApi,
  useDropWmsIdentityApi,
  useGetWarehouseLogoData,
} from "./hooks";
import { selectToken, setCredentials } from "./pages/auth/authSlice";
import {
  selectAuthRoutesList,
  setRouteList,
} from "./pages/configuration/routeSlice";
import RoutesContainer from "./routes/RoutesContainer";
import AuthService from "./services/authService";
import { AbpApplicationConfigurationService } from "./services/drop-wms-identity-services";
import { getFilteredPermissionData } from "./utils";
import {
  selectDesktopView,
  setDesktopView,
} from "./pages/configuration/viewSlice";
import { toast } from "react-toastify";
import ToastComponent from "./components/Toast";

function App() {
  let location = useLocation();
  const authService = new AuthService();
  const { isLoading, handleRequest } = useDropWmsIdentityApi();
  const { handleRequest: handelLogoRequest } = useDropWmsAdministrationApi();
  const userAuthList = useSelector(selectAuthRoutesList);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const isDesktop = useSelector(selectDesktopView);
  const isHandheldScreenMediaQuery = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user) {
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
        }
      })
      .catch((err) => {
        if (!err) authService.signOutRedirectCallback();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      handleRequest(
        AbpApplicationConfigurationService.getApiIdentityApiAbpApplicationConfiguration()
      )
        .then((response) => {
          const { desktopPermissionsGroups, handheldPermissionsGroups } =
            getFilteredPermissionData(response?.auth?.grantedPolicies);
          let isHandheldUser =
            Object.keys(handheldPermissionsGroups).length > 0;
          let isDesktopUser = Object.keys(desktopPermissionsGroups).length > 0;
          const desktopRouteList = Object.keys(desktopPermissionsGroups).map(
            (item) => item
          );
          const handheldRouteList = Object.keys(desktopPermissionsGroups).map(
            (item) => item
          );

          dispatch(
            setRouteList({
              currentUserAuthList: response || {},
              currentUserRoutesList: desktopRouteList || [],
              currentUserHandheldRoutesList: handheldRouteList || [],
            })
          );

          let desktop = true;
          if (window.sessionStorage.getItem("desktop") !== null) {
            desktop = window.sessionStorage.getItem("desktop") === "true";
          } else {
            if (isHandheldUser && isDesktopUser) {
              if (isHandheldScreenMediaQuery) {
                desktop = false;
              }
              // no need of else condition as is Desktop is true by default
            } else if (isHandheldUser) {
              desktop = false;
            } else if (isDesktopUser) {
              desktop = true;
            }
          }
          window.sessionStorage.setItem("desktop", `${desktop}`);
          dispatch(
            setDesktopView({
              desktopView: desktop,
            })
          );
          setIsLoadingData(false);
        })
        .catch((error) => {
          toast.error(
            <ToastComponent label="Error" message={error} />
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token && userAuthList.currentUser?.isAuthenticated === false) {
      authService.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthList]);

  const { data: logoData, status } = useGetWarehouseLogoData(
    handelLogoRequest,
    token
  );

  useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflow = "unset";
  }, [location]);

  // need to check user permission as well check if user have handheld permission or not
  // if they have handheld permission force user device to handheld
  // let isDesktop =
  //  !isDesktopOrLaptop || !isHandheldUser ? true : false;
  // let isNotHandheldDevice = !useSelector(selectHandheldView)

  return (
    <div>
      {isLoading ? (
        <Spinners containerClassName="h-screen" />
      ) : (
        <>
          {isDesktop ? (
            <Fragment>
              <Navbar
                isAuth={authService.isAuthenticated()}
                logoData={{ logoData, status }}
              />
              <InnerContainer img={pingBg}>
                <RoutesContainer
                  isDesktop={isDesktop}
                  isLoadingData={isLoadingData}
                />
              </InnerContainer>
            </Fragment>
          ) : (
            <Fragment>
              <RoutesContainer
                isDesktop={isDesktop}
                isLoadingData={isLoadingData}
              />
            </Fragment>
          )}
        </>
      )}
    </div>
  );
}

export default App;
