import { useQuery } from "react-query";
import { FunctionalTransferOutReportService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetFunctionalTransferReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  toWarehouse?: string,
  locationName?: string,
  customerName?: string,
  customerCountry?: string,
  orderNumber?: string,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  tagMac?: string,
  crateNumber?: string,
  tote?: string,
  pickedFromDate?: string,
  pickedToDate?: string,
  pickedBy?: string,
  vasConfirmedFromDate?: string,
  vasConfirmedToDate?: string,
  vasConfirmedBy?: string,
  outboundQcChecklistCompletedFromDate?: string,
  outboundQcChecklistCompletedToDate?: string,
  outboundQcChecklistCompletedBy?: string,
  shippedFromDate?: string,
  shippedToDate?: string,
  shippedBy?: string,
  projectCode?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getFunctionalTransferReportList",
      sorting,
      skipCount,
      maxResultCount,
      toWarehouse,
      locationName,
      customerName,
      customerCountry,
      orderNumber,
      skuPartNumber,
      licensePlateNumber,
      tagMac,
      crateNumber,
      tote,
      pickedFromDate,
      pickedToDate,
      pickedBy,
      vasConfirmedFromDate,
      vasConfirmedToDate,
      vasConfirmedBy,
      outboundQcChecklistCompletedFromDate,
      outboundQcChecklistCompletedToDate,
      outboundQcChecklistCompletedBy,
      shippedFromDate,
      shippedToDate,
      shippedBy,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        FunctionalTransferOutReportService?.getApiOutboundApiAppFunctionalTransferOutReportReport(
          sorting,
          skipCount,
          maxResultCount,
          toWarehouse?.trim(),
          locationName?.trim(),
          customerName?.trim(),
          customerCountry?.trim(),
          orderNumber?.trim(),
          skuPartNumber?.trim(),
          licensePlateNumber?.trim(),
          tagMac?.trim(),
          crateNumber?.trim(),
          tote?.trim(),
          pickedFromDate,
          pickedToDate,
          pickedBy?.trim(),
          vasConfirmedFromDate,
          vasConfirmedToDate,
          vasConfirmedBy?.trim(),
          outboundQcChecklistCompletedFromDate,
          outboundQcChecklistCompletedToDate,
          outboundQcChecklistCompletedBy?.trim(),
          shippedFromDate,
          shippedToDate,
          shippedBy?.trim(),
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};
