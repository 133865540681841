import { useQuery } from "react-query";
import { InboundService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseCrateWithoutTagListsData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  projectCode?: string,
  licensePlateNumber?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseCrateWithoutTagLists",
      sorting,
      skipCount,
      maxResultCount,
      projectCode,
      licensePlateNumber,
    ],
    async () => {
      return await handleRequest(
        InboundService.getApiInboundApiAppInboundLPWithoutTag(
          sorting,
          skipCount,
          maxResultCount,
          projectCode?.trim(),
          licensePlateNumber?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseCrateWithoutTagDetailListsData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  projectId: string,
  isTagged?: boolean,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  crateNumber?: string
) => {
  const responseData = useQuery(
    [
      "getWarehouseCrateWithoutTagDetailLists",
      projectId,
      isTagged,
      skuPartNumber,
      licensePlateNumber,
      crateNumber,
    ],
    async () => {
      return await handleRequest(
        InboundService.getApiInboundApiAppInboundLicensePlateWithoutTagDetail(
          projectId,
          isTagged,
          skuPartNumber?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim()
        )
      );
    },
    {
      enabled: !!projectId,
      keepPreviousData: false,
    }
  );

  return responseData;
};
