import { useQuery } from "react-query";

import { TableParamsType } from "../../types/common";
import { InboundService } from "../../services/drop-wms-inbound-services";

export const useGetWarehouseInboundListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  asnId?: string,
  asnNumber?: string,
  projectId?: string,
  projectCode?: string,
  tagId?: string,
  tagMac?: string,
  skuId?: string,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  lineNumber?: number,
  tote?: string,
  crateNumber?: string,
  shippedOut?: any,
  isInboundQcChecklistCompleted?: any,
  isPutawayCompleted?: any,
  shipperCustomerName?: string,
  shipperLocation?: string,
  customerId?: string,
  customerName?: string,
  customerLocation?: string,
  freightForwarder?: string,
  isAllocated?: any,
  isHold?: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseInboundList",
      sorting,
      skipCount,
      maxResultCount,
      asnId,
      asnNumber,
      projectId,
      projectCode,
      tagId,
      tagMac,
      skuId,
      skuPartNumber,
      licensePlateNumber,
      lineNumber,
      tote,
      crateNumber,
      shippedOut,
      isInboundQcChecklistCompleted,
      isPutawayCompleted,
      shipperCustomerName,
      shipperLocation,
      customerId,
      customerName,
      customerLocation,
      freightForwarder,
      isAllocated,
      isHold,
    ],
    async () => {
      return await handleRequest(
        InboundService.getApiInboundApiAppInboundLicensePlateList(
          sorting,
          skipCount,
          maxResultCount,
          asnId,
          asnNumber,
          projectId,
          projectCode,
          tagId,
          tagMac,
          skuId,
          skuPartNumber,
          licensePlateNumber,
          lineNumber,
          tote,
          crateNumber,
          shippedOut,
          isInboundQcChecklistCompleted,
          isPutawayCompleted,
          shipperCustomerName,
          shipperLocation,
          customerId,
          customerName,
          customerLocation,
          freightForwarder,
          isAllocated,
          isHold
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseInboundASNListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const responseData = useQuery(
    ["getWarehouseInboundDetailsView", id],
    async () => {
      if (id) {
        return await handleRequest(
          InboundService.getApiInboundApiAppInboundLicensePlate(id)
        );
      }
    },
    {
      enabled: !!id,
    }
  );

  return responseData;
};

export const useGetWarehouseInboundASNListsData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  asnNumber?: string,
  asnStatus?: any,
  projectCode?: string,
  licensePlateNumber?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseInboundASNLists",
      sorting,
      skipCount,
      maxResultCount,
      asnNumber,
      asnStatus,
      projectCode,
      licensePlateNumber,
    ],
    async () => {
      return await handleRequest(
        InboundService.getApiInboundApiAppInboundASNList(
          sorting,
          skipCount,
          maxResultCount,
          asnNumber?.trim(),
          asnStatus,
          projectCode?.trim(),
          licensePlateNumber?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseInboundASNDetailListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  asnId?: any,
  licensePlateNumber?: string
) => {
  const responseData = useQuery(
    ["getWarehouseInboundASNDetailsList", asnId, licensePlateNumber],
    async () => {
      if (asnId) {
        return await handleRequest(
          InboundService.getApiInboundApiAppInboundLicensePlateDetail(
            asnId,
            licensePlateNumber?.trim()
          )
        );
      }
    },
    {
      enabled: !!asnId,
      keepPreviousData: false,
    }
  );

  return responseData;
};

export const useGetWarehouseInboundASNDetailQCChecklistListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  licensePlateId: string
) => {
  const responseData = useQuery(
    ["getWarehouseInboundASNDetailQCChecklistList", licensePlateId],
    async () => {
      if (licensePlateId) {
        return await handleRequest(
          InboundService.getApiInboundApiAppInboundLPInboundQCChecklist(
            licensePlateId
          )
        );
      }
    },
    {
      enabled: !!licensePlateId,
      keepPreviousData: false,
    }
  );

  return responseData;
};
