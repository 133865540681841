/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Interfaces_WarehouseRoles_WarehouseRoleCreateUpdateDto } from '../models/Drop_WMS_Interfaces_WarehouseRoles_WarehouseRoleCreateUpdateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseUserRoleDto_Drop_WMS_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseUserRoleDto_Drop_WMS_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseRoleService {

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppWarehouseRole(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/warehouse-role/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiIdentityApiAppWarehouseRole(
id: string,
requestBody?: Drop_WMS_Interfaces_WarehouseRoles_WarehouseRoleCreateUpdateDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/identity/api/app/warehouse-role/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiIdentityApiAppWarehouseRole(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/identity/api/app/warehouse-role/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param name 
     * @param status 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppWarehouseRole1(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
name?: string,
status?: boolean,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/warehouse-role',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'Name': name,
                'Status': status,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppWarehouseRole(
requestBody?: Drop_WMS_Interfaces_WarehouseRoles_WarehouseRoleCreateUpdateDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseRoleDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/warehouse-role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseUserRoleDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppWarehouseRoleRoles(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Interfaces_WarehouseRoles_WarehouseUserRoleDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/warehouse-role/roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
