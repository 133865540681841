import { ComponentsPermissionNames } from "../constants/innerRoutesList";

const CheckMenuPermissions = (
  permissionsGroups: any,
  permissions: any
): boolean => {
  const permissionNames = permissions
    .map(
      (p: any) =>
        ComponentsPermissionNames.find((c: any) => c.url === p.url)?.name
    )
    .filter(Boolean);
  const availablePermissions = Object.keys(permissionsGroups).filter(
    (keys: string) =>
      permissionNames.includes(keys) &&
      !permissionsGroups[keys]?.[0]?.key.includes("Handheld")
  );

  return availablePermissions.length > 0;
};

export default CheckMenuPermissions;
