import { useQuery } from "react-query";
import { HandheldSpotterService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetHandheldSpotterByProject = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { filter } = tableInfo; //Filter by Project#
  const responseData = useQuery(
    ["getHandheldSpotterByProject", filter],
    async () => {
      return await handleRequest(
        HandheldSpotterService.getApiOutboundApiAppHandheldSpotterSpotterProjectList(
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetHandheldSpotterByOrder = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string
) => {
  const responseData = useQuery(
    ["getHandheldSpotterByOrder", orderId],
    async () => {
      return await handleRequest(
        HandheldSpotterService.getApiOutboundApiAppHandheldSpotterOrderDetail(
          orderId
        )
      );
    },
    {
      enabled: !!orderId,
      keepPreviousData: true,
    }
  );
  return responseData;
};
