import { useQuery } from "react-query";
import { TransitInventoryReportService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetTransitInventoryReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  skuPartNumber: string,
  locationName: string,
  licensePlateNumber: string,
  crateNumber: string,
  tote: string,
  asnNumber: string,
  receiptDate: string,
  projectCode: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getTransitInventoryReportList",
      sorting,
      skipCount,
      maxResultCount,
      skuPartNumber,
      locationName,
      licensePlateNumber,
      crateNumber,
      tote,
      asnNumber,
      receiptDate,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        TransitInventoryReportService?.getApiOutboundApiAppTransitInventoryReportReport(
          sorting,
          skipCount,
          maxResultCount,
          skuPartNumber?.trim(),
          locationName?.trim(),
          licensePlateNumber?.trim(),
          crateNumber.trim(),
          tote.trim(),
          asnNumber?.trim(),
          receiptDate,
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};
