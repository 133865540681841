import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';

type NotifyState = {
  notify: boolean;
};

const notifySlice = createSlice({
  name: 'notify',
  initialState: { notify: false } as NotifyState,
  reducers: {
    setNotify: (state, { payload: { notify } }: PayloadAction<{ notify: boolean }>) => {
      state.notify = notify;
    },
  },
});

export const { setNotify } = notifySlice.actions;

export default notifySlice.reducer;

export const selectNotify = (state: RootState) => state?.notify?.notify;
