/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_InboundService_Interfaces_LicensePlates_CreateLicensePlateDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlates_CreateLicensePlateDto';
import type { Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_CreateLicensePlateWithoutTagDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_CreateLicensePlateWithoutTagDto';
import type { Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_UpdateLicensePlateTagDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_UpdateLicensePlateTagDto';
import type { Drop_WMS_InboundService_Interfaces_SKUs_SKUCreateUpdateDto } from '../models/Drop_WMS_InboundService_Interfaces_SKUs_SKUCreateUpdateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_ContinueWithNewASNDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_ContinueWithNewASNDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_ASNs_ASNByProjectCodeDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_ASNs_ASNByProjectCodeDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HandheldInboundService {

    /**
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_ASNs_ASNByProjectCodeDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundASNListByProjectCode(
        projectCode: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_ASNs_ASNByProjectCodeDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound/a-sNList-by-project-code',
            query: {
                'projectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_ContinueWithNewASNDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundContinueWithNewASn(
        projectCode: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_ContinueWithNewASNDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound/continue-with-new-aSN',
            query: {
                'projectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundLicensePlate(
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlates_CreateLicensePlateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound/license-plate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundLicensePlateWithoutTag(
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_CreateLicensePlateWithoutTagDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound/license-plate-without-tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundSKU(
        requestBody?: Drop_WMS_InboundService_Interfaces_SKUs_SKUCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound/s-kU',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param lpNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundLicensePlateWithoutTagByLPNumber(
        lpNumber: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound/license-plate-without-tag-by-lPNumber',
            query: {
                'lpNumber': lpNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiInboundApiAppHandheldInboundTagForLicensePlateWithoutTag(
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_UpdateLicensePlateTagDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/inbound/api/app/handheld-inbound/tag-for-license-plate-without-tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param partNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundSKUByPartNumber(
        partNumber: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_SKUs_SKUDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound/s-kUBy-part-number',
            query: {
                'partNumber': partNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param asnId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundCompleteAsn(
        asnId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound/complete-asn/{asnId}',
            path: {
                'asnId': asnId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
