import { useQuery } from "react-query";
import { DashboardService } from "../../services/drop-wms-inbound-services";

import { DashboardService as DashboardOutboundService } from "../../services/drop-wms-outbound-services";

export const useGetWarehouseInboundDashboardData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getWarehouseInboundDashboardData"],
    async () => {
      return await handleRequest(
        DashboardService?.getApiInboundApiAppDashboardCount()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseInboundDashboardReportData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  fromDate?: string | any,
  toDate?: string | any
) => {
  const responseListData = useQuery(
    ["getWarehouseInboundDashboardReportData", fromDate, toDate],
    async () => {
      return await handleRequest(
        DashboardService?.getApiInboundApiAppDashboardReport(fromDate, toDate)
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseInboundDashboardInventoryReportData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  fromDate?: string | any,
  toDate?: string | any
) => {
  const responseListData = useQuery(
    ["getWarehouseInboundDashboardInventoryReportData", fromDate, toDate],
    async () => {
      return await handleRequest(
        DashboardService?.getApiInboundApiAppDashboardInventoryReport(
          fromDate,
          toDate
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseOutboundDashboardData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getWarehouseOutboundDashboardData"],
    async () => {
      return await handleRequest(
        DashboardOutboundService?.getApiOutboundApiAppDashboardCount()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseOutboundDashboardReportData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  fromDate?: string | any,
  toDate?: string | any
) => {
  const responseListData = useQuery(
    ["getWarehouseOutboundDashboardReportData", fromDate, toDate],
    async () => {
      return await handleRequest(
        DashboardOutboundService?.getApiOutboundApiAppDashboardReport(
          fromDate,
          toDate
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
