/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_AdministrationService_Interfaces_Warehouses_CreateWarehouseDto } from '../models/Drop_WMS_AdministrationService_Interfaces_Warehouses_CreateWarehouseDto';
import type { Drop_WMS_AdministrationService_Interfaces_Warehouses_UpdateWarehouseDto } from '../models/Drop_WMS_AdministrationService_Interfaces_Warehouses_UpdateWarehouseDto';
import type { Drop_WMS_Shared_Hosting_Enums_WarehouseType } from '../models/Drop_WMS_Shared_Hosting_Enums_WarehouseType';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_GetWarehouseDto_Drop_WMS_AdministrationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_GetWarehouseDto_Drop_WMS_AdministrationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Nullable_1OfOfSystem_Guid } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Nullable_1OfOfSystem_Guid';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Nullable_1OfOfSystem_Guid Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseWarehouseTenantId(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Nullable_1OfOfSystem_Guid> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse/{id}/warehouse-tenant-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouse(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiAdministrationApiAppWarehouse(
        id: string,
        requestBody?: Drop_WMS_AdministrationService_Interfaces_Warehouses_UpdateWarehouseDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/administration/api/app/warehouse/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param displayName
     * @param code
     * @param warehouseTypeId
     * @param status
     * @param warehouseOwnerId
     * @param warehouseOwnerName
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseByFilter(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        displayName?: string,
        code?: string,
        warehouseTypeId?: Drop_WMS_Shared_Hosting_Enums_WarehouseType,
        status?: boolean,
        warehouseOwnerId?: string,
        warehouseOwnerName?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse/by-filter',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'DisplayName': displayName,
                'Code': code,
                'WarehouseTypeId': warehouseTypeId,
                'Status': status,
                'WarehouseOwnerId': warehouseOwnerId,
                'WarehouseOwnerName': warehouseOwnerName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseTransferWarehouses(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse/transfer-warehouses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAdministrationApiAppWarehouse(
        requestBody?: Drop_WMS_AdministrationService_Interfaces_Warehouses_CreateWarehouseDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_WarehouseDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/administration/api/app/warehouse',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_GetWarehouseDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseWarehouses(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_Warehouses_GetWarehouseDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse/warehouses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
