/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapImageBlobDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapImageBlobDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_OutboundLocationDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_OutboundLocationDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Maps_GetMapDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Maps_GetMapDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MapService {

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppMap(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/map/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiLocationApiAppMap(
id: string,
formData?: {
DisplayName: string;
Status: boolean;
Image?: Blob;
},
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/location/api/app/map/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiLocationApiAppMap(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/location/api/app/map/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param displayName 
     * @param status 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppMap1(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
displayName?: string,
status?: boolean,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/map',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'DisplayName': displayName,
                'Status': status,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiLocationApiAppMap(
formData?: {
DisplayName: string;
Status: boolean;
Image: Blob;
},
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/location/api/app/map',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Maps_GetMapDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppMapMaps(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Maps_GetMapDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/map/maps',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapImageBlobDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppMapWarehouseMapImage(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_MapImageBlobDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/map/{id}/warehouse-map-image',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param locationId 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_OutboundLocationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppMapOutboundLocationMap(
locationId: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Maps_OutboundLocationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/map/outbound-location-map/{locationId}',
            path: {
                'locationId': locationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
