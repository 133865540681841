/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_InboundService_Interfaces_Handhelds_UpdateLPInboundQCChecklistDto } from '../models/Drop_WMS_InboundService_Interfaces_Handhelds_UpdateLPInboundQCChecklistDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistImageBlobDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistImageBlobDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_InboundLPDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_InboundLPDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HandheldInboundQcChecklistService {

    /**
     * @param skipCount
     * @param maxResultCount
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_InboundLPDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundQCChecklistIncompleteInboundLPList(
        skipCount?: number,
        maxResultCount?: number,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_InboundLPDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/incomplete-inbound-lPList',
            query: {
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'LicensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistImageBlobDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundQCChecklistInboundQCChecklistImage(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistImageBlobDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/{id}/inbound-qCChecklist-image',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundQCChecklistLPInboundQCChecklistByLPNumber(
        licensePlateNumber: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/l-pInbound-qCChecklist-by-lPNumber',
            query: {
                'licensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param licensePlateId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHandheldInboundQCChecklistLPInboundQCChecklistByLP(
        licensePlateId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/l-pInbound-qCChecklist-by-lP/{licensePlateId}',
            path: {
                'licensePlateId': licensePlateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static putApiInboundApiAppHandheldInboundQCChecklistLPInboundQCChecklist(
        requestBody?: Drop_WMS_InboundService_Interfaces_Handhelds_UpdateLPInboundQCChecklistDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/l-pInbound-qCChecklist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHandheldInboundQCChecklistBulkUpdateLPInboundQCChecklist(
        requestBody?: Array<Drop_WMS_InboundService_Interfaces_Handhelds_UpdateLPInboundQCChecklistDto>,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/handheld-inbound-qCChecklist/bulk-update-lPInbound-qCChecklist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
