import { useQuery } from "react-query";
import { InventoryReportService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetInventoryReportSummary = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tagMac?: string,
  skuPartNumber?: string,
  locationName?: string,
  licensePlateNumber?: string,
  crateNumber?: string,
  asnLineNumber?: number | any,
  receiptDate?: string,
  projectCode?: string
) => {
  const responseData = useQuery(
    [
      "getInventoryReportSummary",
      tagMac,
      skuPartNumber,
      locationName,
      licensePlateNumber,
      crateNumber,
      asnLineNumber,
      receiptDate,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        InventoryReportService.getApiInboundApiAppInventoryReportSummary(
          tagMac?.trim(),
          skuPartNumber?.trim(),
          locationName?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim(),
          asnLineNumber?.trim(),
          receiptDate,
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetInventoryReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  tagMac: string,
  skuPartNumber: string,
  locationName: string,
  licensePlateNumber: string,
  crateNumber: string,
  asnLineNumber: number | any,
  receiptDate: string,
  projectCode: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getInventoryReportList",
      sorting,
      skipCount,
      maxResultCount,
      tagMac,
      skuPartNumber,
      locationName,
      licensePlateNumber,
      crateNumber,
      asnLineNumber,
      receiptDate,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        InventoryReportService.getApiInboundApiAppInventoryReportReport(
          sorting,
          skipCount,
          maxResultCount,
          tagMac?.trim(),
          skuPartNumber?.trim(),
          locationName?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim(),
          asnLineNumber?.trim(),
          receiptDate,
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};
