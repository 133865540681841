/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDetailLPDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDetailLPDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountScanDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountScanDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CycleCountService {

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppCycleCount(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/cycle-count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param cycleCountNumber
     * @param cycleCountStatus
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountScanDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountCycleCountList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        cycleCountNumber?: string,
        cycleCountStatus?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountScanDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/cycle-count-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'CycleCountNumber': cycleCountNumber,
                'CycleCountStatus': cycleCountStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param skuPartNumber
     * @param projectCode
     * @param licensePlateNumber
     * @param tagMac
     * @param systemLocation
     * @param physicalLocation
     * @param isScanned
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDetailLPDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountCycleCountDetailLPList(
        cycleCountId: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        skuPartNumber?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        systemLocation?: string,
        physicalLocation?: string,
        isScanned?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDetailLPDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/cycle-count-detail-lPList',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'CycleCountId': cycleCountId,
                'SKUPartNumber': skuPartNumber,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'SystemLocation': systemLocation,
                'PhysicalLocation': physicalLocation,
                'IsScanned': isScanned,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppCycleCountOpenCycleCount(
        cycleCountId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/cycle-count/open-cycle-count/{cycleCountId}',
            path: {
                'cycleCountId': cycleCountId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppCycleCountCloseCycleCount(
        cycleCountId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_CycleCountDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/cycle-count/close-cycle-count/{cycleCountId}',
            path: {
                'cycleCountId': cycleCountId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountExportPdf(
        cycleCountId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/export-pdf/{cycleCountId}',
            path: {
                'cycleCountId': cycleCountId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @param skuPartNumber
     * @param projectCode
     * @param licensePlateNumber
     * @param tagMac
     * @param systemLocation
     * @param physicalLocation
     * @param isScanned
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountExportCycleCountDetailExcel(
        cycleCountId: string,
        skuPartNumber?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        systemLocation?: string,
        physicalLocation?: string,
        isScanned?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/export-cycle-count-detail-excel',
            query: {
                'CycleCountId': cycleCountId,
                'SKUPartNumber': skuPartNumber,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'SystemLocation': systemLocation,
                'PhysicalLocation': physicalLocation,
                'IsScanned': isScanned,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @param skuPartNumber
     * @param projectCode
     * @param licensePlateNumber
     * @param tagMac
     * @param systemLocation
     * @param physicalLocation
     * @param isScanned
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountExportCycleCountDetailPdf(
        cycleCountId: string,
        skuPartNumber?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        systemLocation?: string,
        physicalLocation?: string,
        isScanned?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/export-cycle-count-detail-pdf',
            query: {
                'CycleCountId': cycleCountId,
                'SKUPartNumber': skuPartNumber,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'SystemLocation': systemLocation,
                'PhysicalLocation': physicalLocation,
                'IsScanned': isScanned,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cycleCountId
     * @param skuPartNumber
     * @param projectCode
     * @param licensePlateNumber
     * @param tagMac
     * @param systemLocation
     * @param physicalLocation
     * @param isScanned
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppCycleCountExportCycleCountDetailCsv(
        cycleCountId: string,
        skuPartNumber?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        systemLocation?: string,
        physicalLocation?: string,
        isScanned?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/cycle-count/export-cycle-count-detail-csv',
            query: {
                'CycleCountId': cycleCountId,
                'SKUPartNumber': skuPartNumber,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'SystemLocation': systemLocation,
                'PhysicalLocation': physicalLocation,
                'IsScanned': isScanned,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
