import { useQuery } from "react-query";
import { WarehouseOwnerService } from "../../services/drop-wms-administration-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseOwnerListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  warehouseOwnerName?: string,
  storerKey?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getWarehouseOwnerList",
      sorting,
      skipCount,
      maxResultCount,
      warehouseOwnerName,
      storerKey,
    ],
    async () => {
      return await handleRequest(
        WarehouseOwnerService.getApiAdministrationApiAppWarehouseOwner1(
          sorting,
          skipCount,
          maxResultCount,
          warehouseOwnerName?.trim(),
          storerKey?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseOwnerDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseOwnerDetailsView", id],
    async () => {
      return await handleRequest(
        WarehouseOwnerService.getApiAdministrationApiAppWarehouseOwner(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetAllWarehouseOwnerListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllWarehouseOwnerList"],
    async () => {
      return await handleRequest(
        WarehouseOwnerService.getApiAdministrationApiAppWarehouseOwnerWarehouseOwners()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
