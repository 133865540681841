/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShippedOutTransferredOrderService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param orderNumber
     * @param orderDate
     * @param orderDeliveryDate
     * @param customerName
     * @param customerCountry
     * @param customerLocation
     * @param freightForwarderName
     * @param salesOrder
     * @param schenkerdoRef
     * @param bluestain
     * @param vas
     * @param pullback
     * @param transferredFromWarehouse
     * @param transferredBy
     * @param receivedBy
     * @param shippedOutBy
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppShippedOutTransferredOrderOrderList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        orderNumber?: string,
        orderDate?: string,
        orderDeliveryDate?: string,
        customerName?: string,
        customerCountry?: string,
        customerLocation?: string,
        freightForwarderName?: string,
        salesOrder?: string,
        schenkerdoRef?: string,
        bluestain?: boolean,
        vas?: boolean,
        pullback?: boolean,
        transferredFromWarehouse?: string,
        transferredBy?: string,
        receivedBy?: string,
        shippedOutBy?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/shipped-out-transferred-order/order-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'OrderNumber': orderNumber,
                'OrderDate': orderDate,
                'OrderDeliveryDate': orderDeliveryDate,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'CustomerLocation': customerLocation,
                'FreightForwarderName': freightForwarderName,
                'SalesOrder': salesOrder,
                'SchenkerdoRef': schenkerdoRef,
                'Bluestain': bluestain,
                'VAS': vas,
                'Pullback': pullback,
                'TransferredFromWarehouse': transferredFromWarehouse,
                'TransferredBy': transferredBy,
                'ReceivedBy': receivedBy,
                'ShippedOutBy': shippedOutBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @param sorting
     * @param projectCode
     * @param licensePlateNumber
     * @param isMagnetized
     * @param isUrgent
     * @param skuPartNumber
     * @param crateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppShippedOutTransferredOrderOrderDetail(
        orderId: string,
        sorting?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        isMagnetized?: boolean,
        isUrgent?: boolean,
        skuPartNumber?: string,
        crateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_TransferredOrders_TransferredOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/shipped-out-transferred-order/order-detail',
            query: {
                'Sorting': sorting,
                'OrderId': orderId,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'IsMagnetized': isMagnetized,
                'IsUrgent': isUrgent,
                'SKUPartNumber': skuPartNumber,
                'CrateNumber': crateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
