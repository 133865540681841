/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitDailyOutgoingReportDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitDailyOutgoingReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransitDailyOutgoingReportService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param pickedFromDate
     * @param pickedToDate
     * @param pickedBy
     * @param outboundQcChecklistCompletedFromDate
     * @param outboundQcChecklistCompletedToDate
     * @param outboundQcChecklistCompletedBy
     * @param shippedFromDate
     * @param shippedToDate
     * @param shippedBy
     * @param projectCode
     * @param vasConfirmedFromDate
     * @param vasConfirmedToDate
     * @param vasConfirmedBy
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitDailyOutgoingReportDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitDailyOutgoingReportReport(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        pickedFromDate?: string,
        pickedToDate?: string,
        pickedBy?: string,
        outboundQcChecklistCompletedFromDate?: string,
        outboundQcChecklistCompletedToDate?: string,
        outboundQcChecklistCompletedBy?: string,
        shippedFromDate?: string,
        shippedToDate?: string,
        shippedBy?: string,
        projectCode?: string,
        vasConfirmedFromDate?: string,
        vasConfirmedToDate?: string,
        vasConfirmedBy?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitDailyOutgoingReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-daily-outgoing-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'PickedFromDate': pickedFromDate,
                'PickedToDate': pickedToDate,
                'PickedBy': pickedBy,
                'OutboundQCChecklistCompletedFromDate': outboundQcChecklistCompletedFromDate,
                'OutboundQCChecklistCompletedToDate': outboundQcChecklistCompletedToDate,
                'OutboundQCChecklistCompletedBy': outboundQcChecklistCompletedBy,
                'ShippedFromDate': shippedFromDate,
                'ShippedToDate': shippedToDate,
                'ShippedBy': shippedBy,
                'ProjectCode': projectCode,
                'VASConfirmedFromDate': vasConfirmedFromDate,
                'VASConfirmedToDate': vasConfirmedToDate,
                'VASConfirmedBy': vasConfirmedBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param pickedFromDate
     * @param pickedToDate
     * @param pickedBy
     * @param outboundQcChecklistCompletedFromDate
     * @param outboundQcChecklistCompletedToDate
     * @param outboundQcChecklistCompletedBy
     * @param shippedFromDate
     * @param shippedToDate
     * @param shippedBy
     * @param projectCode
     * @param vasConfirmedFromDate
     * @param vasConfirmedToDate
     * @param vasConfirmedBy
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitDailyOutgoingReportExportExcel(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        pickedFromDate?: string,
        pickedToDate?: string,
        pickedBy?: string,
        outboundQcChecklistCompletedFromDate?: string,
        outboundQcChecklistCompletedToDate?: string,
        outboundQcChecklistCompletedBy?: string,
        shippedFromDate?: string,
        shippedToDate?: string,
        shippedBy?: string,
        projectCode?: string,
        vasConfirmedFromDate?: string,
        vasConfirmedToDate?: string,
        vasConfirmedBy?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-daily-outgoing-report/export-excel',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'PickedFromDate': pickedFromDate,
                'PickedToDate': pickedToDate,
                'PickedBy': pickedBy,
                'OutboundQCChecklistCompletedFromDate': outboundQcChecklistCompletedFromDate,
                'OutboundQCChecklistCompletedToDate': outboundQcChecklistCompletedToDate,
                'OutboundQCChecklistCompletedBy': outboundQcChecklistCompletedBy,
                'ShippedFromDate': shippedFromDate,
                'ShippedToDate': shippedToDate,
                'ShippedBy': shippedBy,
                'ProjectCode': projectCode,
                'VASConfirmedFromDate': vasConfirmedFromDate,
                'VASConfirmedToDate': vasConfirmedToDate,
                'VASConfirmedBy': vasConfirmedBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param pickedFromDate
     * @param pickedToDate
     * @param pickedBy
     * @param outboundQcChecklistCompletedFromDate
     * @param outboundQcChecklistCompletedToDate
     * @param outboundQcChecklistCompletedBy
     * @param shippedFromDate
     * @param shippedToDate
     * @param shippedBy
     * @param projectCode
     * @param vasConfirmedFromDate
     * @param vasConfirmedToDate
     * @param vasConfirmedBy
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitDailyOutgoingReportExportPdf(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        pickedFromDate?: string,
        pickedToDate?: string,
        pickedBy?: string,
        outboundQcChecklistCompletedFromDate?: string,
        outboundQcChecklistCompletedToDate?: string,
        outboundQcChecklistCompletedBy?: string,
        shippedFromDate?: string,
        shippedToDate?: string,
        shippedBy?: string,
        projectCode?: string,
        vasConfirmedFromDate?: string,
        vasConfirmedToDate?: string,
        vasConfirmedBy?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-daily-outgoing-report/export-pdf',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'PickedFromDate': pickedFromDate,
                'PickedToDate': pickedToDate,
                'PickedBy': pickedBy,
                'OutboundQCChecklistCompletedFromDate': outboundQcChecklistCompletedFromDate,
                'OutboundQCChecklistCompletedToDate': outboundQcChecklistCompletedToDate,
                'OutboundQCChecklistCompletedBy': outboundQcChecklistCompletedBy,
                'ShippedFromDate': shippedFromDate,
                'ShippedToDate': shippedToDate,
                'ShippedBy': shippedBy,
                'ProjectCode': projectCode,
                'VASConfirmedFromDate': vasConfirmedFromDate,
                'VASConfirmedToDate': vasConfirmedToDate,
                'VASConfirmedBy': vasConfirmedBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param pickedFromDate
     * @param pickedToDate
     * @param pickedBy
     * @param outboundQcChecklistCompletedFromDate
     * @param outboundQcChecklistCompletedToDate
     * @param outboundQcChecklistCompletedBy
     * @param shippedFromDate
     * @param shippedToDate
     * @param shippedBy
     * @param projectCode
     * @param vasConfirmedFromDate
     * @param vasConfirmedToDate
     * @param vasConfirmedBy
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitDailyOutgoingReportExportCsv(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        pickedFromDate?: string,
        pickedToDate?: string,
        pickedBy?: string,
        outboundQcChecklistCompletedFromDate?: string,
        outboundQcChecklistCompletedToDate?: string,
        outboundQcChecklistCompletedBy?: string,
        shippedFromDate?: string,
        shippedToDate?: string,
        shippedBy?: string,
        projectCode?: string,
        vasConfirmedFromDate?: string,
        vasConfirmedToDate?: string,
        vasConfirmedBy?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-daily-outgoing-report/export-csv',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'PickedFromDate': pickedFromDate,
                'PickedToDate': pickedToDate,
                'PickedBy': pickedBy,
                'OutboundQCChecklistCompletedFromDate': outboundQcChecklistCompletedFromDate,
                'OutboundQCChecklistCompletedToDate': outboundQcChecklistCompletedToDate,
                'OutboundQCChecklistCompletedBy': outboundQcChecklistCompletedBy,
                'ShippedFromDate': shippedFromDate,
                'ShippedToDate': shippedToDate,
                'ShippedBy': shippedBy,
                'ProjectCode': projectCode,
                'VASConfirmedFromDate': vasConfirmedFromDate,
                'VASConfirmedToDate': vasConfirmedToDate,
                'VASConfirmedBy': vasConfirmedBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
