import { useQuery } from "react-query";
import { HandheldInboundService } from "../../services/drop-wms-inbound-services";

export const useGetHandheldInboundListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  projectCode: string
) => {
  const responseListData = useQuery(
    ["getProjectCodeList", projectCode],
    async () => {
      return await handleRequest(
        HandheldInboundService.getApiInboundApiAppHandheldInboundASNListByProjectCode(
          projectCode.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetHandheldInboundLicensePlateListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  licensePlate: string
) => {
  const responseListData = useQuery(
    ["getLicensePlateList", licensePlate],
    async () => {
      return await handleRequest(
        HandheldInboundService.getApiInboundApiAppHandheldInboundLicensePlateWithoutTagByLPNumber(
          licensePlate.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
