import { TypographyType } from "./../../types/common";
import { rem } from "./../../utils/style";
import styled, { css } from "styled-components";
import { COLORS } from "../../constants/style/Color";
import { Display } from "../../constants/style/Common.styled";

interface TextType {
  variant: TypographyType;
  color?: string;
  fontWeight?: 400 | 500 | 600 | 700;
}

export const Text = styled(Display)<TextType>`
  color: ${(props) => (props.color ? props.color : COLORS.Black)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  justify-content: flex-start;

  ${(props) => {
    if (props.variant === "h1") {
      return css`
        font-size: ${rem(36)};
        line-height: ${rem(40)};
      `;
    }

    if (props.variant === "h2") {
      return css`
        font-size: ${rem(32)};
        line-height: ${rem(38)};
      `;
    }

    if (props.variant === "h3") {
      return css`
        font-size: ${rem(26)};
        line-height: ${rem(30)};
      `;
    }

    if (props.variant === "h4") {
      return css`
        font-size: ${rem(22)};
        line-height: ${rem(25)};
      `;
    }

    if (props.variant === "h5") {
      return css`
        font-size: ${rem(20)};
        line-height: ${rem(24)};
      `;
    }

    if (props.variant === "h6") {
      return css`
        font-size: ${rem(16)};
        line-height: ${rem(18)};
      `;
    }

    if (props.variant === "p") {
      return css`
        font-size: ${rem(36)};
        line-height: ${rem(40)};
      `;
    }

    if (props.variant === "body") {
      return css`
        font-size: ${rem(16)};
        line-height: ${rem(22)};
      `;
    }

    if (props.variant === "body-field") {
      return css`
        font-size: ${rem(16)};
        line-height: ${rem(16)};
      `;
    }

    if (props.variant === "body-s") {
      return css`
        font-size: ${rem(14)};
        line-height: ${rem(18)};
      `;
    }

    if (props.variant === "body-xs") {
      return css`
        font-size: ${rem(12)};
        line-height: ${rem(14)};
      `;
    }

    if (props.variant === "body-xxs") {
      return css`
        font-size: ${rem(10)};
        line-height: ${rem(12)};
      `;
    }

    if (props.variant === "body-uppercase") {
      return css`
        font-size: ${rem(12)};
        line-height: ${rem(16)};
        text-transform: uppercase !important;
        letter-spacing: 2px;
      `;
    }
  }};
`;
