import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFilteredPermissionData } from '.';
import { ComponentsPermissionNames } from '../constants/innerRoutesList';
import { selectAuthRoutesList } from '../pages/configuration/routeSlice';
import { ModalStatusType } from '../types/common';

interface Props {
  type?: ModalStatusType;
  urlName?: string;
  children: React.ReactNode;
}

// Checks Permission
const CheckPermissions = ({ type, urlName, children }: Props) => {
  const location = useLocation();
  const url = location.pathname.split('/');

  const userAuthList = useSelector(selectAuthRoutesList);
  const { desktopPermissionsGroups } = getFilteredPermissionData(
    userAuthList?.auth?.grantedPolicies ?? {}
  );
  const getUrlName = urlName
    ? ComponentsPermissionNames?.find((item) => item.url === urlName)?.name
    : ComponentsPermissionNames?.find((item) => item.url === url[url.length - 1])?.name;
  const hasPermission =
    getUrlName &&
    desktopPermissionsGroups[getUrlName]?.some((item) => item.text === type);
  return <>{type ? hasPermission && children : children}</>;
};

export default CheckPermissions;
