import ReactDOM from "react-dom/client";
import App from "./App";
import "material-symbols";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/style/main.css";
import GlobalStyle from "./globalStyles";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: Infinity,
      retry: true,
      retryDelay: 300,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
        <Provider store={store}>
          <ToastContainer
            autoClose={3000}
            draggable={false}
            pauseOnHover={true}
            pauseOnFocusLoss={false}
            toastClassName={"toast-container"}
            hideProgressBar={false}
            position={"top-right"}
            closeButton={() => (
              <span className="material-symbols-outlined">close</span>
            )}
          />
          <GlobalStyle />
          <App />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </Provider>
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
