import { COLORS } from '../../constants/style/Color';
import { Spinner } from './Spinner.styled';

type SpinnersType = {
  borderColor?: string;
  className?: string;
  containerClassName?: string;
  size?: 'sm' | 'md' | 'lg';
};

const Spinners = ({ borderColor, className, containerClassName, size, ...rest }: SpinnersType) => {
  return (
    <div className={`flex items-center justify-center ${containerClassName || ''}`}>
      <Spinner
        borderColor={borderColor ?? COLORS.Orange}
        size={size ?? 'lg'}
        className={className}
        {...rest}
      />
    </div>
  );
};

export default Spinners;
