import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto } from '../../services/drop-wms-administration-services';
import type { RootState } from '../../store/store';

type RouteState = {
  currentUserRoutesList: Array<any>;
  currentUserHandheldRoutesList: Array<any>;
  currentUserAuthList: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto;
};

const slice = createSlice({
  name: 'routes',
  initialState: {
    currentUserRoutesList: [],
    currentUserHandheldRoutesList: [],
    currentUserAuthList: {},
  } as RouteState,
  reducers: {
    setRouteList: (
      state,
      {
        payload: { currentUserRoutesList, currentUserHandheldRoutesList, currentUserAuthList },
      }: PayloadAction<{ currentUserRoutesList: Array<any>; currentUserHandheldRoutesList: Array<any>; currentUserAuthList: any }>
    ) => {
      state.currentUserAuthList = currentUserAuthList;
      state.currentUserRoutesList = currentUserRoutesList;
      state.currentUserHandheldRoutesList = currentUserHandheldRoutesList;
    },
  },
});

export const { setRouteList } = slice.actions;

export default slice.reducer;

export const selectAuthRoutesList = (state: RootState) => state?.route.currentUserAuthList;

export const selectUserRoutesList = (state: RootState) => state?.route.currentUserRoutesList;
export const selectUserHandheldRoutesList = (state: RootState) => state?.route.currentUserHandheldRoutesList;