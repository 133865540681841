import { SkeletonCircleMain, SkeletonWrapper } from "./Skeleton.Styled";

interface SkeletonCircleType {
  size?:
    | "sm"
    | "md"
    | "lg"
    | "table"
    | "pie-chart"
    | "line-chart"
    | "table-report";
  shape?: "square" | "rounded" | "circle";
  className?: string;
  containerClassName?: string;
}

const SkeletonCircle = ({
  size = "table",
  shape = "rounded",
  className,
  containerClassName,
}: SkeletonCircleType) => {
  return (
    <SkeletonWrapper className={containerClassName}>
      <SkeletonCircleMain size={size} shape={shape} className={className} />
    </SkeletonWrapper>
  );
};

export default SkeletonCircle;
