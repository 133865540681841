import { useQuery } from "react-query";
import { TransitTransferInReportService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetTransitTransferInReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  warehouse?: string,
  locationName?: string,
  customerName?: string,
  customerCountry?: string,
  orderNumber?: string,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  crateNumber?: string,
  tote?: string,
  receivedFromDate?: string,
  receivedToDate?: string,
  receivedBy?: string,
  putawayFromDate?: string,
  putawayToDate?: string,
  putawayBy?: string,
  projectCode?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getTransitInventoryReportList",
      sorting,
      skipCount,
      maxResultCount,
      warehouse,
      locationName,
      customerName,
      customerCountry,
      orderNumber,
      skuPartNumber,
      licensePlateNumber,
      crateNumber,
      tote,
      receivedFromDate,
      receivedToDate,
      receivedBy,
      putawayFromDate,
      putawayToDate,
      putawayBy,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        TransitTransferInReportService?.getApiOutboundApiAppTransitTransferInReportReport(
          sorting,
          skipCount,
          maxResultCount,
          warehouse?.trim(),
          locationName?.trim(),
          customerName?.trim(),
          customerCountry?.trim(),
          orderNumber?.trim(),
          skuPartNumber?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim(),
          tote?.trim(),
          receivedFromDate,
          receivedToDate,
          receivedBy?.trim(),
          putawayFromDate,
          putawayToDate,
          putawayBy?.trim(),
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};
