/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventorySummaryReportDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventorySummaryReportDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventoryReportDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventoryReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InventoryReportService {

    /**
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param asnLineNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventorySummaryReportDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInventoryReportSummary(
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        asnLineNumber?: number,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventorySummaryReportDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inventory-report/summary',
            query: {
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'AsnLineNumber': asnLineNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param asnLineNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventoryReportDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInventoryReportReport(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        asnLineNumber?: number,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_InventoryReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inventory-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'AsnLineNumber': asnLineNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param asnLineNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInventoryReportExportExcel(
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        asnLineNumber?: number,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inventory-report/export-excel',
            query: {
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'AsnLineNumber': asnLineNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param asnLineNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInventoryReportExportPdf(
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        asnLineNumber?: number,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inventory-report/export-pdf',
            query: {
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'AsnLineNumber': asnLineNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param asnLineNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInventoryReportExportCsv(
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        asnLineNumber?: number,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inventory-report/export-csv',
            query: {
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'AsnLineNumber': asnLineNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
