/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto } from '../models/Drop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto';
import type { Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateDto';
import type { Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateListDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateListDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_BulkReceiveLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_BulkReceiveLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_BulkUpdateLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_BulkUpdateLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateHistoryDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateHistoryDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_ASNOutputDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_ASNOutputDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_LPWithoutTagDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_LPWithoutTagDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_DesktopLicensePlateDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_DesktopLicensePlateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InboundService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLicensePlate(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/{id}/license-plate',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiInboundApiAppInboundLicensePlate(
        id: string,
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/inbound/api/app/inbound/{id}/license-plate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param asnId
     * @param asnNumber
     * @param projectId
     * @param projectCode
     * @param tagId
     * @param tagMac
     * @param skuId
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param lineNumber
     * @param tote
     * @param crateNumber
     * @param shippedOut
     * @param isInboundQcChecklistCompleted
     * @param isPutawayCompleted
     * @param shipperCustomerName
     * @param shipperLocation
     * @param customerId
     * @param customerName
     * @param customerLocation
     * @param freightForwarder
     * @param isAllocated
     * @param isHold
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLicensePlateList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        asnId?: string,
        asnNumber?: string,
        projectId?: string,
        projectCode?: string,
        tagId?: string,
        tagMac?: string,
        skuId?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        lineNumber?: number,
        tote?: string,
        crateNumber?: string,
        shippedOut?: boolean,
        isInboundQcChecklistCompleted?: boolean,
        isPutawayCompleted?: boolean,
        shipperCustomerName?: string,
        shipperLocation?: string,
        customerId?: string,
        customerName?: string,
        customerLocation?: string,
        freightForwarder?: string,
        isAllocated?: boolean,
        isHold?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/license-plate-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'AsnId': asnId,
                'AsnNumber': asnNumber,
                'ProjectId': projectId,
                'ProjectCode': projectCode,
                'TagId': tagId,
                'TagMac': tagMac,
                'SKUId': skuId,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'LineNumber': lineNumber,
                'Tote': tote,
                'CrateNumber': crateNumber,
                'ShippedOut': shippedOut,
                'IsInboundQCChecklistCompleted': isInboundQcChecklistCompleted,
                'IsPutawayCompleted': isPutawayCompleted,
                'ShipperCustomerName': shipperCustomerName,
                'ShipperLocation': shipperLocation,
                'CustomerId': customerId,
                'CustomerName': customerName,
                'CustomerLocation': customerLocation,
                'FreightForwarder': freightForwarder,
                'IsAllocated': isAllocated,
                'IsHold': isHold,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppInboundBulkUpdateLicensePlate(
        formData?: {
            File: Blob;
        },
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/inbound/bulk-update-license-plate',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_BulkUpdateLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundBulkUpdateLPTemplate(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_BulkUpdateLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/bulk-update-lPTemplate',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param asnNumber
     * @param asnStatus
     * @param projectCode
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_ASNOutputDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundASNList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        asnNumber?: string,
        asnStatus?: boolean,
        projectCode?: string,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_ASNOutputDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/a-sNList',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'AsnNumber': asnNumber,
                'AsnStatus': asnStatus,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param asnId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppInboundCompleteAsn(
        asnId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/inbound/complete-asn/{asnId}',
            path: {
                'asnId': asnId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param asnId
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLicensePlateDetail(
        asnId: string,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/license-plate-detail/{asnId}',
            path: {
                'asnId': asnId,
            },
            query: {
                'licensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppInboundBulkUpdateLicensePlates(
        requestBody?: Array<Drop_WMS_InboundService_Interfaces_LicensePlates_UpdateLicensePlateListDto>,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/inbound/bulk-update-license-plates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param asnId
     * @param asnNumber
     * @param projectId
     * @param projectCode
     * @param tagId
     * @param tagMac
     * @param skuId
     * @param skuPartNumber
     * @param locationId
     * @param locationName
     * @param licensePlateNumber
     * @param tote
     * @param fromDate
     * @param toDate
     * @param putawayStaus
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_DesktopLicensePlateDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundDesktopLicensePlate(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        asnId?: string,
        asnNumber?: string,
        projectId?: string,
        projectCode?: string,
        tagId?: string,
        tagMac?: string,
        skuId?: string,
        skuPartNumber?: string,
        locationId?: string,
        locationName?: string,
        licensePlateNumber?: string,
        tote?: string,
        fromDate?: string,
        toDate?: string,
        putawayStaus?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_DesktopLicensePlateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/desktop-license-plate',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'AsnId': asnId,
                'AsnNumber': asnNumber,
                'ProjectId': projectId,
                'ProjectCode': projectCode,
                'TagId': tagId,
                'TagMac': tagMac,
                'SKUId': skuId,
                'SKUPartNumber': skuPartNumber,
                'LocationId': locationId,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'Tote': tote,
                'FromDate': fromDate,
                'ToDate': toDate,
                'PutawayStaus': putawayStaus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param licensePlateId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateHistoryDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLicensePlateHistory(
        licensePlateId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateHistoryDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/license-plate-history/{licensePlateId}',
            path: {
                'licensePlateId': licensePlateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param licensePlateId
     * @returns Drop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLPInboundQCChecklist(
        licensePlateId: string,
    ): CancelablePromise<Array<Drop_WMS_InboundService_Interfaces_Handhelds_LicensePlateInboundQCChecklistDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/l-pInbound-qCChecklist/{licensePlateId}',
            path: {
                'licensePlateId': licensePlateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param projectCode
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_LPWithoutTagDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLPWithoutTag(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        projectCode?: string,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_LPWithoutTagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/l-pWithout-tag',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param projectId
     * @param isTagged
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundLicensePlateWithoutTagDetail(
        projectId: string,
        isTagged?: boolean,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlatesWithoutTag_LicensePlateWithoutTagDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/license-plate-without-tag-detail',
            query: {
                'ProjectId': projectId,
                'IsTagged': isTagged,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_BulkReceiveLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundBulkReceiveLPTemplate(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Desktops_BulkReceiveLPExcelBlobDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound/bulk-receive-lPTemplate',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppInboundBulkReceiveLicensePlate(
        formData?: {
            File: Blob;
        },
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/inbound/bulk-receive-license-plate',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
