/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_LocationService_Interfaces_BaseStations_CreateUpdateBaseStationDto } from '../models/Drop_WMS_LocationService_Interfaces_BaseStations_CreateUpdateBaseStationDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BaseStationService {

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppBaseStation(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/base-station/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiLocationApiAppBaseStation(
id: string,
requestBody?: Drop_WMS_LocationService_Interfaces_BaseStations_CreateUpdateBaseStationDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/location/api/app/base-station/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiLocationApiAppBaseStation(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/location/api/app/base-station/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param ip 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppBaseStation1(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
ip?: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/base-station',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'IP': ip,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiLocationApiAppBaseStation(
requestBody?: Drop_WMS_LocationService_Interfaces_BaseStations_CreateUpdateBaseStationDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/location/api/app/base-station',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppBaseStationBaseStations(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_BaseStations_BaseStationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/base-station/base-stations',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
