/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OutgoingEdiLogService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiEdiApiAppOutgoingEdiLog(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/edi/api/app/outgoing-edi-log/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param taskId
     * @param ediType
     * @param requestStatus
     * @param requestedAt
     * @param responseStatus
     * @param respondedAt
     * @param isAcknowledged
     * @param acknowledgedAt
     * @param projectCode
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiEdiApiAppOutgoingEdiLog1(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        taskId?: string,
        ediType?: string,
        requestStatus?: boolean,
        requestedAt?: string,
        responseStatus?: boolean,
        respondedAt?: string,
        isAcknowledged?: boolean,
        acknowledgedAt?: string,
        projectCode?: string,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_EdiService_Interfaces_OutgoingEdiLogs_OutgoingEdiLogDto_Drop_WMS_EdiService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/edi/api/app/outgoing-edi-log',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TaskId': taskId,
                'EdiType': ediType,
                'RequestStatus': requestStatus,
                'RequestedAt': requestedAt,
                'ResponseStatus': responseStatus,
                'RespondedAt': respondedAt,
                'IsAcknowledged': isAcknowledged,
                'AcknowledgedAt': acknowledgedAt,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiEdiApiAppOutgoingEdiLogEdiTypes(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/edi/api/app/outgoing-edi-log/edi-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiEdiApiAppOutgoingEdiLogResendEdi(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/edi/api/app/outgoing-edi-log/{id}/resend-edi',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
