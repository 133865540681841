/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutputDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutputDto_Drop_WMS_OutboundService_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HandheldOutboundService {

    /**
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutputDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundInitializePickedOrderList(
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_ProjectOutputDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound/initialize-picked-order-list',
            query: {
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldOutboundOrderDetailByOrderId(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_LicensePlateOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-outbound/order-detail-by-order-id/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderDetailId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppHandheldOutboundPicked(
        orderDetailId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/handheld-outbound/picked/{orderDetailId}',
            path: {
                'orderDetailId': orderDetailId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderDetailId
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppHandheldOutboundSentToStaging(
        orderDetailId: string,
        licensePlateNumber: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/handheld-outbound/sent-to-staging/{orderDetailId}',
            path: {
                'orderDetailId': orderDetailId,
            },
            query: {
                'licensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
