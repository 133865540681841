import styled, { css } from "styled-components";
import { TypographyType } from "./../../types/common";
import { rem } from "./../../utils/style";

interface SkeletonLineMainType {
  size: TypographyType | "table";
  width: string;
}

interface SkeletonCircleMainType {
  size:
    | "sm"
    | "md"
    | "lg"
    | "table"
    | "pie-chart"
    | "line-chart"
    | "table-report";
  shape: "square" | "rounded" | "circle";
}

export const SkeletonWrapper = styled.div`
  width: 100%;
`;

export const SkeletonMain = styled.span`
  display: inline-block;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.09);
  position: relative;

  @-webkit-keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20px,
      rgba(255, 255, 255, 0.2) 60px,
      rgba(255, 255, 255, 0)
    );
    -webkit-animation: shimmer 1.5s infinite;
    animation: shimmer 1.5s infinite;
    content: "";
  }
`;

export const SkeletonLineMain = styled(SkeletonMain)<SkeletonLineMainType>`
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : `${rem(64)}`)};

  ${(props) => {
    if (props.size === "h1") {
      return css`
        height: ${rem(36)};
        line-height: ${rem(40)};
      `;
    }

    if (props.size === "h2") {
      return css`
        height: ${rem(32)};
        line-height: ${rem(38)};
      `;
    }

    if (props.size === "h3") {
      return css`
        height: ${rem(26)};
        line-height: ${rem(30)};
      `;
    }

    if (props.size === "h4") {
      return css`
        height: ${rem(22)};
        line-height: ${rem(25)};
      `;
    }

    if (props.size === "h5") {
      return css`
        height: ${rem(20)};
        line-height: ${rem(24)};
      `;
    }

    if (props.size === "h6") {
      return css`
        height: ${rem(16)};
        line-height: ${rem(18)};
      `;
    }

    if (props.size === "p") {
      return css`
        height: ${rem(36)};
        line-height: ${rem(40)};
      `;
    }

    if (props.size === "body") {
      return css`
        height: ${rem(16)};
        line-height: ${rem(22)};
      `;
    }

    if (props.size === "body-field") {
      return css`
        height: ${rem(16)};
        line-height: ${rem(16)};
      `;
    }

    if (props.size === "body-s") {
      return css`
        height: ${rem(14)};
        line-height: ${rem(18)};
      `;
    }

    if (props.size === "body-xs") {
      return css`
        height: ${rem(12)};
        line-height: ${rem(14)};
      `;
    }

    if (props.size === "body-xxs") {
      return css`
        height: ${rem(10)};
        line-height: ${rem(12)};
      `;
    }
    if (props.size === "table") {
      return css`
        height: ${rem(28)};
        line-height: ${rem(28)};
      `;
    }
  }};
`;

export const SkeletonCircleMain = styled(SkeletonMain)<SkeletonCircleMainType>`
  border-radius: 5px;

  ${(props) => {
    if (props.size === "sm") {
      return css`
        height: ${rem(16)};
        width: ${rem(16)};
      `;
    }

    if (props.size === "md") {
      return css`
        height: ${rem(30)};
        width: ${rem(30)};
      `;
    }

    if (props.size === "lg") {
      return css`
        height: ${rem(40)};
        width: ${rem(40)};
      `;
    }

    if (props.size === "table-report") {
      return css`
        height: ${rem(100)};
        width: ${rem(245)};
      `;
    }

    if (props.size === "table") {
      return css`
        height: ${rem(28)};
        width: ${rem(28)};
      `;
    }

    if (props.size === "pie-chart") {
      return css`
        height: ${rem(440)};
        width: ${rem(440)};
      `;
    }

    if (props.size === "line-chart") {
      return css`
        height: ${rem(440)};
        width: ${rem(320)};
      `;
    }
  }};

  ${(props) => {
    if (props.shape === "circle") {
      return css`
        border-radius: 50%;
      `;
    }

    if (props.shape === "rounded") {
      return css`
        border-radius: 10%;
      `;
    }

    if (props.shape === "square") {
      return css`
        border-radius: 0px;
      `;
    }
  }};
`;
