import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'
import { IDENTITY_CONFIG } from '../../utils/authConst'

type AuthState = {
  isAuthenticated: boolean | null
  token: string | null
  user: any
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: {}, isAuthenticated: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { isAuthenticated, token, user } }: PayloadAction<{ isAuthenticated: boolean; token: string; user: object }>
    ) => {
      state.isAuthenticated = isAuthenticated
      state.token = token
      state.user = user
    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated

export const selectToken = (state: RootState) => {
  const oidcClientData = localStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`)
  const jsonData = oidcClientData && JSON.parse(oidcClientData)
  return jsonData?.access_token
};

export const selectUser = (state: RootState) => state?.auth?.user