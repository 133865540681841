/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistCreateUpdateDto } from '../models/Drop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistCreateUpdateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OutboundQcChecklistService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundQCChecklist(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiOutboundApiAppOutboundQCChecklist(
        id: string,
        requestBody?: Drop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/outbound/api/app/outbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static deleteApiOutboundApiAppOutboundQCChecklist(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/outbound/api/app/outbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param displayText
     * @param displayOrder
     * @param status
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundQCChecklist1(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        displayText?: string,
        displayOrder?: number,
        status?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound-qCChecklist',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'DisplayText': displayText,
                'DisplayOrder': displayOrder,
                'Status': status,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppOutboundQCChecklist(
        requestBody?: Drop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Dtos_OutboundQCChecklists_OutboundQCChecklistDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/outbound-qCChecklist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
