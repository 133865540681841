import { useQuery } from "react-query";

import { ShippedOutTransferredOrderService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseShippedOutTransferredOrderListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  orderNumber?: string,
  orderDate?: string,
  orderDeliveryDate?: string,
  customerName?: string,
  customerCountry?: string,
  customerLocation?: string,
  freightForwarderName?: string,
  salesOrder?: string,
  schenkerdoRef?: string,
  bluestain?: boolean,
  vas?: boolean,
  pullback?: boolean,
  transferredFromWarehouse?: string,
  transferredBy?: string,
  receivedBy?: string,
  shippedOutBy?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseShippedOutTransferredOrderList",
      sorting,
      skipCount,
      maxResultCount,
      orderNumber,
      orderDate,
      orderDeliveryDate,
      customerName,
      customerCountry,
      customerLocation,
      freightForwarderName,
      salesOrder,
      schenkerdoRef,
      bluestain,
      vas,
      pullback,
      transferredFromWarehouse,
      transferredBy,
      receivedBy,
      shippedOutBy,
    ],
    async () => {
      return await handleRequest(
        ShippedOutTransferredOrderService?.getApiOutboundApiAppShippedOutTransferredOrderOrderList(
          sorting,
          skipCount,
          maxResultCount,
          orderNumber?.trim(),
          orderDate,
          orderDeliveryDate,
          customerName?.trim(),
          customerCountry?.trim(),
          customerLocation?.trim(),
          freightForwarderName?.trim(),
          salesOrder?.trim(),
          schenkerdoRef?.trim(),
          bluestain,
          vas,
          pullback,
          transferredFromWarehouse,
          transferredBy,
          receivedBy,
          shippedOutBy
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseShippedOutTransferredOrderDetailsListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string,
  tableInfo: TableParamsType,
  projectCode?: string,
  licensePlateNumber?: string,
  isMagnetized?: boolean,
  isUrgent?: boolean,
  skuPartNumber?: string,
  crateNumber?: string
) => {
  const { sorting } = tableInfo;

  const responseData = useQuery(
    [
      "getShippedOutTransferredOrderDetailsView",
      orderId,
      sorting,
      projectCode,
      licensePlateNumber,
      isMagnetized,
      isUrgent,
      skuPartNumber,
      crateNumber,
    ],
    async () => {
      return await handleRequest(
        ShippedOutTransferredOrderService?.getApiOutboundApiAppShippedOutTransferredOrderOrderDetail(
          orderId,
          sorting,
          projectCode?.trim(),
          licensePlateNumber?.trim(),
          isMagnetized,
          isUrgent,
          skuPartNumber?.trim(),
          crateNumber?.trim()
        )
      );
    },
    {
      enabled: !!orderId,
    }
  );

  return responseData;
};
