import { useQuery } from "react-query";
import { WarehouseLogoService } from "../../services/drop-wms-administration-services";

export const useGetWarehouseLogoData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  token: any
) => {
  const responseData = useQuery(["warehouseLogoDataView"], async () => {
    if (token) {
      return await handleRequest(
        WarehouseLogoService.getApiAdministrationApiAppWarehouseLogoWarehouseLogo()
      );
    }
  });

  return responseData;
};
