import * as routeUrl from "../routes/routeUrl";

export const ComponentsPermissionNames = [
  {
    url: routeUrl.HOME.url,
    name: "Dashboards",
  },
  {
    url: routeUrl.TAGS.url,
    name: "Tags",
  },
  {
    url: routeUrl.SKUS.url,
    name: "SKUs",
  },
  {
    url: routeUrl.CUSTOMER.url,
    name: "Customers",
  },
  {
    url: routeUrl.INBOUND_QC_CHECKLIST.url,
    name: "InboundQCChecklists",
  },
  {
    url: routeUrl.OUTBOUND_QC_CHECKLIST.url,
    name: "OutboundQCChecklists",
  },
  {
    url: routeUrl.WAREHOUSE_ROLE.url,
    name: "WarehouseRoles",
  },
  {
    url: routeUrl.WAREHOUSE_USER.url,
    name: "WarehouseUsers",
  },

  {
    url: routeUrl.BASE_STATION.url,
    name: "BaseStations",
  },
  {
    url: routeUrl.MAP.url,
    name: "Maps",
  },
  {
    url: routeUrl.LOCATION.url,
    name: "Locations",
  },
  {
    url: routeUrl.WAREHOUSE_OWNER.url,
    name: "WarehouseOwners",
  },
  {
    url: routeUrl.WAREHOUSE.url,
    name: "Warehouses",
  },
  {
    url: routeUrl.WAREHOUSE_INBOUND.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.WAREHOUSE_PUTAWAY.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.WAREHOUSE_INBOUND_CRATE_WITHOUT_TAG.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.WAREHOUSE_HOLD_UNHOLD.url,
    name: "Holds",
  },
  {
    url: routeUrl.WAREHOUSE_OUTBOUND_ORDER.url,
    name: "Outbounds",
  },
  {
    url: routeUrl.WAREHOUSE_EDI_INCOMING_LOG.url,
    name: "IncomingEdiLogs",
  },
  {
    url: routeUrl.WAREHOUSE_EDI_OUTGOING_LOG.url,
    name: "OutgoingEdiLogs",
  },
  {
    url: routeUrl.OVERALL_INVENTORY_REPORT.url,
    name: "OverallInventoryReports",
  },
  {
    url: routeUrl.INVENTORY_REPORT.url,
    name: "InventoryReports",
  },
  {
    url: routeUrl.DAILY_RECEIVING_REPORT.url,
    name: "DailyReceivingReports",
  },
  {
    url: routeUrl.DAILY_OUTGOING_REPORT.url,
    name: "DailyOutgoingReports",
  },
  {
    url: routeUrl.TRANSFER_ORDER_REPORT.url,
    name: "TransferReports",
  },
  {
    url: routeUrl.TRANSFER_OUT_FUNCTIONAL_ORDER_REPORT.url,
    name: "FunctionalTransferOutReports",
  },
  {
    url: routeUrl.WAREHOUSE_CYCLE_COUNT.url,
    name: "CycleCounts",
  },
  {
    url: routeUrl.WAREHOUSE_CYCLE_COUNT_PRINT_PDF.url,
    name: "CycleCounts",
  },
  {
    url: routeUrl.TRANSIT_PENDING_ORDER.url,
    name: "PendingTransferredOrders",
  },
  {
    url: routeUrl.TRANSIT_RECEIVED_ORDER.url,
    name: "ReceivedTransferredOrders",
  },
  {
    url: routeUrl.TRANSIT_SHIPPED_OUT_ORDER.url,
    name: "ShippedOutTransferredOrders",
  },
  {
    url: routeUrl.TRANSIT_INVENTORY_REPORT.url,
    name: "TransitInventoryReports",
  },
  {
    url: routeUrl.TRANSIT_TRANSFER_IN_REPORT.url,
    name: "TransitTransferInReports",
  },
  {
    url: routeUrl.TRANSIT_OUTGOING_REPORT.url,
    name: "TransitDailyOutgoingReports",
  },
  //Handheld List
  {
    url: routeUrl.HANDHELD_INBOUND.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.HANDHELD_INBOUND_CHECKLIST.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.HANDHELD_PUT_AWAY.url,
    name: "Inbounds",
  },
  {
    url: routeUrl.HANDHELD_OUTBOUND.url,
    name: "Outbounds",
  },
  {
    url: routeUrl.HANDHELD_OUTBOUND_VAS_CONFIRM.url,
    name: "Outbounds",
  },
  {
    url: routeUrl.HANDHELD_OUTBOUND_CHECKLIST.url,
    name: "Outbounds",
  },
  {
    url: routeUrl.HANDHELD_SPOTTER.url,
    name: "Outbounds",
  },
  {
    url: routeUrl.HANDHELD_CYCLE_COUNT.url,
    name: "Inbounds",
  },
];
