import { useQuery } from "react-query";
import { DailyReceivingReportService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetDailyReceivingReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  locationName?: string,
  customerName?: string,
  customerCountry?: string,
  asnNumber?: string,
  skuPartNumber?: string,
  licensePlateNumber?: string,
  tagMac?: string,
  crateNumber?: string,
  tote?: string,
  receiveFromDate?: string,
  receiveToDate?: string,
  receivedBy?: string,
  inboundQcChecklistCompletedFromDate?: string,
  inboundQcChecklistCompletedToDate?: string,
  inboundQcChecklistCompletedBy?: string,
  putawayFromDate?: string,
  putawayToDate?: string,
  putawayBy?: string,
  projectCode?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getDailyReceivingReportList",
      sorting,
      skipCount,
      maxResultCount,
      locationName,
      customerName,
      customerCountry,
      asnNumber,
      skuPartNumber,
      licensePlateNumber,
      tagMac,
      crateNumber,
      tote,
      receiveFromDate,
      receiveToDate,
      receivedBy,
      inboundQcChecklistCompletedFromDate,
      inboundQcChecklistCompletedToDate,
      inboundQcChecklistCompletedBy,
      putawayFromDate,
      putawayToDate,
      putawayBy,
      projectCode,
    ],
    async () => {
      return await handleRequest(
        DailyReceivingReportService.getApiInboundApiAppDailyReceivingReportReport(
          sorting,
          skipCount,
          maxResultCount,
          locationName?.trim(),
          customerName?.trim(),
          customerCountry?.trim(),
          asnNumber?.trim(),
          skuPartNumber?.trim(),
          licensePlateNumber?.trim(),
          tagMac?.trim(),
          crateNumber?.trim(),
          tote?.trim(),
          receiveFromDate,
          receiveToDate,
          receivedBy?.trim(),
          inboundQcChecklistCompletedFromDate,
          inboundQcChecklistCompletedToDate,
          inboundQcChecklistCompletedBy?.trim(),
          putawayFromDate,
          putawayToDate,
          putawayBy?.trim(),
          projectCode?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetDailyReceivingReportUsersData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseData = useQuery(
    ["getDailyReceivingReportUsersDataList"],
    async () => {
      return await handleRequest(
        DailyReceivingReportService.getApiInboundApiAppDailyReceivingReportUsers()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};
