import { useQuery } from "react-query";
import { CustomerService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetCustomerListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  customerName: string,
  customerCode: string,
  vasRequired: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const customerListData = useQuery(
    [
      "getCustomerList",
      sorting,
      skipCount,
      maxResultCount,
      customerName,
      customerCode,
      vasRequired,
    ],
    async () => {
      return await handleRequest(
        CustomerService.getApiInboundApiAppCustomer1(
          sorting,
          skipCount,
          maxResultCount,
          customerName?.trim(),
          customerCode?.trim(),
          vasRequired
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return customerListData;
};

export const useGetCustomerDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["customerDetailsView", id],
    async () => {
      return await handleRequest(
        CustomerService.getApiInboundApiAppCustomer(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetAllCustomerListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllCustomerList"],
    async () => {
      return await handleRequest(
        CustomerService.getApiInboundApiAppCustomerCustomer()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
