import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { OverallInventoryReportService as OverallInventoryInboundReportService } from "../../services/drop-wms-inbound-services";
import { OverallInventoryReportService as OverallInventoryOutboundReportService } from "../../services/drop-wms-outbound-services";

export const useGetWarehouseInboundOverallInventoryReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  tenantId: string,
  warehouseTypeId: any,
  tagMac?: string,
  skuPartNumber?: string,
  locationName?: string,
  projectCode?: string,
  licensePlateNumber?: string,
  crateNumber?: string,
  tote?: string,
  asnNumber?: string,
  receiptFromDate?: string,
  receiptToDate?: string,
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      `getWarehouseInboundOverallInventoryReport${tenantId}`,
      sorting,
      skipCount,
      maxResultCount,
      tenantId,
      tagMac,
      skuPartNumber,
      locationName,
      projectCode,
      licensePlateNumber,
      crateNumber,
      tote,
      asnNumber,
      receiptFromDate,
      receiptToDate,
    ],
    async () => {
      return await handleRequest(
        OverallInventoryInboundReportService.getApiInboundApiAppOverallInventoryReportReport(
          tenantId,
          sorting,
          skipCount,
          maxResultCount,
          tagMac?.trim(),
          skuPartNumber?.trim(),
          locationName?.trim(),
          projectCode?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim(),
          tote?.trim(),
          asnNumber?.trim(),
          receiptFromDate,
          receiptToDate
        )
      );
    },
    {
      // keepPreviousData: true,
      enabled: !!tenantId && warehouseTypeId === 1,
    }
  );

  return responseData;
};

export const useGetWarehouseOutboundOverallInventoryReport = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  tenantId: string,
  warehouseTypeId: any,
  tagMac?: string,
  skuPartNumber?: string,
  locationName?: string,
  projectCode?: string,
  licensePlateNumber?: string,
  crateNumber?: string,
  tote?: string,
  asnNumber?: string,
  receiptFromDate?: string,
  receiptToDate?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      `getWarehouseOutboundOverallInventoryReport${tenantId}`,
      sorting,
      skipCount,
      maxResultCount,
      tenantId,
      tagMac,
      skuPartNumber,
      locationName,
      projectCode,
      licensePlateNumber,
      crateNumber,
      tote,
      asnNumber,
      receiptFromDate,
      receiptToDate,
    ],
    async () => {
      return await handleRequest(
        OverallInventoryOutboundReportService.getApiOutboundApiAppOverallInventoryReportReport(
          tenantId,
          sorting,
          skipCount,
          maxResultCount,
          tagMac?.trim(),
          skuPartNumber?.trim(),
          locationName?.trim(),
          projectCode?.trim(),
          licensePlateNumber?.trim(),
          crateNumber?.trim(),
          tote?.trim(),
          asnNumber?.trim(),
          receiptFromDate,
          receiptToDate
        )
      );
    },
    {
      // keepPreviousData: true,
      enabled: !!tenantId && warehouseTypeId === 2,
    }
  );

  return responseData;
};
