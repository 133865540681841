import { useQuery } from "react-query";

import { TableParamsType } from "../../types/common";
import { CycleCountService } from "../../services/drop-wms-inbound-services";

export const useGetWarehouseCycleCountListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  cycleCountNumber?: string,
  cycleCountStatus?: boolean
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseCycleCountList",
      sorting,
      skipCount,
      maxResultCount,
      cycleCountNumber,
      cycleCountStatus,
    ],
    async () => {
      return await handleRequest(
        CycleCountService.getApiInboundApiAppCycleCountCycleCountList(
          sorting,
          skipCount,
          maxResultCount,
          cycleCountNumber?.trim(),
          cycleCountStatus
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetWarehouseCycleCountLicensePlateListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  cycleCountId: string,
  skuPartNumber?: string,
  projectCode?: string,
  licensePlateNumber?: string,
  tagMac?: string,
  systemLocation?: string,
  physicalLocation?: string,
  isScanned?: boolean
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;

  const responseData = useQuery(
    [
      "getCycleCountLicensePlateDetailsView",
      cycleCountId,
      sorting,
      skipCount,
      maxResultCount,
      skuPartNumber,
      projectCode,
      licensePlateNumber,
      tagMac,
      systemLocation,
      physicalLocation,
      isScanned
    ],
    async () => {
      if (cycleCountId) {
        return await handleRequest(
          CycleCountService.getApiInboundApiAppCycleCountCycleCountDetailLPList(
            cycleCountId,
            sorting,
            skipCount,
            maxResultCount,
            skuPartNumber?.trim(),
            projectCode?.trim(),
            licensePlateNumber?.trim(),
            tagMac?.trim(),
            systemLocation?.trim(),
            physicalLocation?.trim(),
            isScanned
          )
        );
      }
    },
    {
      enabled: !!cycleCountId,
    }
  );

  return responseData;
};
