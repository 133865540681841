import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { WarehouseService } from "../../services/drop-wms-administration-services";

export const useGetWarehouseListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  displayName?: string,
  code?: string,
  warehouseTypeId?: number,
  status?: boolean,
  warehouseOwnerId?: string,
  warehouseOwnerName?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getWarehouseList",
      sorting,
      skipCount,
      maxResultCount,
      displayName,
      code,
      warehouseTypeId,
      status,
      warehouseOwnerId,
      warehouseOwnerName,
    ],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouseByFilter(
          sorting,
          skipCount,
          maxResultCount,
          displayName?.trim(),
          code?.trim(),
          warehouseTypeId,
          status,
          warehouseOwnerId,
          warehouseOwnerName?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseOwnerDetailsView", id],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouse(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetWarehouseTenantIdData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const responseData = useQuery(
    ["warehouseTenantIdDataView", id],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouseWarehouseTenantId(
          id
        )
      );
    },
    {
      enabled: !!id,
    }
  );

  return responseData;
};

export const useGetWarehouseTenantDetailsData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const responseData = useQuery(
    ["warehouseTenantIdDataView", id],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouse(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return responseData;
};

export const useGetWarehouseTransferListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getWarehouseTransferList"],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouseTransferWarehouses()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseDataAdministration = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getWarehouseDataAdministration"],
    async () => {
      return await handleRequest(
        WarehouseService.getApiAdministrationApiAppWarehouseWarehouses()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
