/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InboundQcChecklistService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundQCChecklist(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiInboundApiAppInboundQCChecklist(
        id: string,
        formData?: {
            DisplayText: string;
            DisplayOrder: number;
            Status: boolean;
            Image?: Blob;
        },
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/inbound/api/app/inbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiInboundApiAppInboundQCChecklist(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/inbound/api/app/inbound-qCChecklist/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param displayText
     * @param displayOrder
     * @param status
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppInboundQCChecklist1(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        displayText?: string,
        displayOrder?: number,
        status?: boolean,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/inbound-qCChecklist',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'DisplayText': displayText,
                'DisplayOrder': displayOrder,
                'Status': status,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppInboundQCChecklist(
        formData?: {
            DisplayText: string;
            DisplayOrder: number;
            Status: boolean;
            Image: Blob;
        },
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_InboundQCChecklists_InboundQCChecklistDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/inbound-qCChecklist',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
