import { useQuery } from "react-query";
import { WarehouseRoleService } from "../../services/drop-wms-identity-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseRoleListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  name?: string,
  status?: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    ["getWarehouseRoleList", sorting, skipCount, maxResultCount, name, status],
    async () => {
      return await handleRequest(
        WarehouseRoleService.getApiIdentityApiAppWarehouseRole1(
          sorting,
          skipCount,
          maxResultCount,
          name?.trim(),
          status
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetAllWarehouseRoleListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllWarehouseRoleList"],
    async () => {
      return await handleRequest(
        WarehouseRoleService.getApiIdentityApiAppWarehouseRoleRoles()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseRoleDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseRoleDetailsView", id],
    async () => {
      return await handleRequest(
        WarehouseRoleService.getApiIdentityApiAppWarehouseRole(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};
