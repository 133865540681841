import { ReactNode } from "react";
import { HandheldAlertPopupContainer } from "./HandheldAlertPopup.styled";

import { Transition } from "react-transition-group";
import { COLORS } from "../../constants/style/Color";
import Button from "../Button";
import Heading from "../Typography";

type HandheldAlertPopupType = {
  isShow: boolean;
  closeModal: () => void;
  handleSubmit?: any;
  title?: string | ReactNode;
  loading?: boolean;
  note?: string;
  message?: string;
};

const HandheldAlertPopup = ({
  isShow,
  title,
  closeModal,
  handleSubmit,
  loading,
  note,
  message,
}: HandheldAlertPopupType) => {
  return (
    <Transition in={isShow} timeout={250}>
      {(state) => (
        <HandheldAlertPopupContainer>
          <div className={`modalBackdrop modalBackdrop-${state}`}>
            <div className={`sideBar sideBar-${state} `}>
              <div className="popupDialog">
                <div className="popupContent">
                  <Heading
                    variant="h5"
                    className="mb-5"
                    title={`${
                      message ? message : `Are you sure you want to ${title}?`
                    } `}
                  />
                  {note && (
                    <Heading
                      variant="body"
                      className="mb-5"
                      title={`Note: ${note}.`}
                      color={COLORS?.Red}
                    />
                  )}
                  <div className="popupButtons">
                    <Button
                      variant="primary-dark"
                      title="Close"
                      onClick={() => {
                        closeModal();
                      }}
                    />
                    <Button
                      variant="primary"
                      title={message ? "Reload" : "Confirm"}
                      onClick={() => {
                        handleSubmit();
                      }}
                      icon={
                        <span className="material-symbols-outlined">
                          check_circle
                        </span>
                      }
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HandheldAlertPopupContainer>
      )}
    </Transition>
  );
};

export default HandheldAlertPopup;
