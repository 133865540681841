import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

type ViewState = {
  desktopView: boolean
}

const handHeldslice = createSlice({
  name: 'view',
  initialState: { desktopView: true } as ViewState,
  reducers: {
    setDesktopView: (
      state,
      { payload: { desktopView } }: PayloadAction<{ desktopView: boolean; }>
    ) => {
      state.desktopView = desktopView
    },
  },
})

export const { setDesktopView } = handHeldslice.actions

export default handHeldslice.reducer

export const selectDesktopView = (state: RootState) => state?.view?.desktopView