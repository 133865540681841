import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { OutgoingEdiLogService } from "../../services/drop-wms-edi-services";

export const useGetEdiOutgoingLogListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  taskId?: string,
  ediType?: string,
  requestStatus?: boolean,
  requestedAt?: string,
  responseStatus?: boolean,
  respondedAt?: string,
  isAcknowledged?: boolean,
  acknowledgedAt?: string,
  projectCode?: string,
  licensePlateNumber?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getEdiOutgoingLogList",
      sorting,
      skipCount,
      maxResultCount,
      taskId,
      ediType,
      requestStatus,
      requestedAt,
      responseStatus,
      respondedAt,
      isAcknowledged,
      acknowledgedAt,
      projectCode,
      licensePlateNumber,
    ],
    async () => {
      return await handleRequest(
        OutgoingEdiLogService.getApiEdiApiAppOutgoingEdiLog1(
          sorting,
          skipCount,
          maxResultCount,
          taskId?.trim(),
          ediType,
          requestStatus,
          requestedAt,
          responseStatus,
          respondedAt,
          isAcknowledged,
          acknowledgedAt,
          projectCode?.trim(),
          licensePlateNumber?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetEdiOutgoingLogDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseEdiOutgoingLogDetailView", id],
    async () => {
      return await handleRequest(
        OutgoingEdiLogService.getApiEdiApiAppOutgoingEdiLog(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetEdiTypeListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getEdiTypeList"],
    async () => {
      return await handleRequest(
        OutgoingEdiLogService.getApiEdiApiAppOutgoingEdiLogEdiTypes()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
