import React from 'react';
import { NotificationWrapper, NotificationContent } from './toast.styled';

export interface ToastProps {
  label?: string;
  message: string;
}

const ToastComponent = React.memo(({ label, message }: ToastProps) => {
  return (
    <>
      <NotificationWrapper labelType={label}>
        <div className='flex gap-x-4 items-center'>
          {label === 'Success' ? (
            <span className='material-symbols-outlined'>check_circle</span>
          ) : label === 'Error' ? (
            <span className='material-symbols-outlined'>block</span>
          ) : (
            <span className='material-symbols-outlined'>error</span>
          )}

          <NotificationContent labelType={label}>
            <p className='notification-title'>
              {label} {label !== 'Info' && 'Message'}
            </p>
            <p className='notification-message'>{message}</p>
          </NotificationContent>
        </div>
      </NotificationWrapper>
    </>
  );
});

export default ToastComponent;
