import styled from "styled-components";
import { getViewHeight, rem } from "../../utils/style";
import { COLORS } from "./Color";

interface Types {
  alignItems?: any;
  justifyContent?: any;
  leading?: any;
  bgColor?: any;
  img?: any;
  paddingInfo?: any;
}

export const Display = styled.div<Types>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
`;

export const ImportBlock = styled.div`
  margin: ${rem(25)} 0 ${rem(18)} 0;
`;

export const InvitationBlock = styled.div`
  display: flex;
  gap: ${rem(14)};
  margin: ${rem(18)} 0 ${rem(10)} 0;
`;

export const PageHeader = styled.div`
  margin-bottom: ${rem(25)};

  h3 {
    margin-bottom: ${rem(5)};
  }

  span {
    color: ${COLORS.Gray};
  }
  .inline-div {
    display: inline-flex;
  }
  .dynamic-number {
    display: inline-flex;
    margin-left: 12px;
  }
`;

export const PageSearchHeader = styled.div`
  margin-bottom: ${rem(24)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperContainer = styled.div<Types>`
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  &:before {
    content: "";
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${props.img})`};
    height: ${getViewHeight()};
  }

  @media (min-width: 768px) {
    padding: 37px;
  }

  @media (min-width: 1200px) {
    padding: ${(props) =>
      props.paddingInfo ? props.paddingInfo : "43px 120px"};
  }
  .tableII {
    margin-top: 20px;
  }

  &.cycle-count-pdf-wrapper {
    padding-top: 0;
    padding-bottom: 8px;
    .pdf-wrapper {
      &-back-btn {
        margin: 8px 0;
      }
      display: flex;
      flex-direction: column;
      height: calc(100vh - 134px);
    }
  }
`;

export const Container = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: calc(100% - 220px);
  }
`;

export const InnerContainer = styled.div<Types>`
  /* min-height: ${getViewHeight("67px")}; */
  position: relative;
  top: 67px;

  &:before {
    content: "";
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(250, 108, 20, 0.09);
    background: -webkit-linear-gradient(
      169deg,
      rgba(250, 108, 20, 0.09) 0%,
      rgba(250, 108, 20, 0) 50%,
      #ffffff 100%
    );
    background: linear-gradient(
      169deg,
      rgba(250, 108, 20, 0.09) 0%,
      rgba(250, 108, 20, 0) 50%,
      #ffffff 100%
    );
    /* background: ${(props) => `url(${props.img}) no-repeat`}; */
    height: ${getViewHeight()};
  }
`;

export const HandheldContainer = styled.div<Types>`
  /* min-height: ${getViewHeight("67px")}; */
  padding: 50px 18px 18px 18px;

  .form-item-wrapper {
    margin-bottom: 26px;
  }
  .handheld-menu-modal {
    .backdrop {
      top: 50px !important;
    }
  }
`;

export const ErrorMessageWrapper = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  color: ${COLORS.Gray};
  margin-top: ${rem(6)};
  transition: all 0.2s linear;
`;

export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
export const GoBackBlock = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
  cursor: pointer;
  .goBack {
    transition: all 0.2s linear;
  }
  .material-symbols-outlined {
    transition: all 0.2s linear;
    font-size: 20px;
    padding-right: 5px;
  }
  &:hover {
    color: ${COLORS.Orange};
    .material-symbols-outlined {
      transform: translateX(-2px);
    }
    .goBack {
      text-decoration: underline;
      color: ${COLORS.Orange};
    }
  }
`;
export const CardBlock = styled.div`
  background: ${COLORS.White};
  border: ${rem(1)} solid ${COLORS.CulturedWhite};
  border-radius: ${rem(6)};
  margin-top: ${rem(32)};
  margin-bottom: ${rem(12)};
`;

export const ParticipantCard = styled.div`
  padding: ${rem(9)} ${rem(12)} ${rem(42)} ${rem(8)};
  margin-top: ${rem(32)} 0 ${rem(12)} 0;
  .cardTagCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-form {
    padding: ${rem(26)} ${rem(12)} ${rem(0)} ${rem(24)};

    .employeeAddress {
      padding-bottom: ${rem(12)};
    }
  }
`;

export const ResetFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${rem(6)};
  margin-top: ${rem(8)};
  cursor: pointer;
  gap: ${rem(2)};
  transition: all 0.2s linear;
  &,
  & span {
    color: ${COLORS.Gray};
  }

  &:hover,
  &:hover span {
    color: ${COLORS.Orange};
  }
`;

export const PasswordDetailsContainer = styled.div`
  padding: ${rem(16)};
  background-color: ${COLORS.Magnolia};
  border: ${rem(1)} solid rgba(123, 83, 209, 0.25);
  border-radius: ${rem(6)};
`;

export const HeadingMain = styled.h5`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.color ? props.color : `${COLORS.Blue}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;
