export const COLORS = {
  IrisBlue: "#3D57FF",
  Blue: "#071360",
  Red: "#BA2323",
  LightRed: "#FFE0E0",
  Green: "#13A83D",
  LightGreen: "#E5EFE8",
  Magnolia: "#F0F2FF",
  Orange: "#fb6c13",
  VLightOrange: "#F8881F2C",
  LightOrange: "#f8891f",
  DarkOrange: "#fd4712",
  VvLightOrange: "#F8881F1F",
  Iris: "#384DD6",
  LightOrangeBG: "#fef1e4",

  // Neutral Colors
  White: "#FFFFFF",
  Black: "#161616",
  Gray: "#595959",
  Silver: "#717171",
  LightGray: "#D7D7D7",
  SpanishGray: "#969696",
  OffWhite: "#F3F3F3",
  CulturedWhite: "#EDEDED",

  NewPurple: "#8B83BA",

  // Custom Colors (RGBA)
  GrayBorder: "#e5e7eb",
  Gray10: "#d2d2d2",
  Gray20: "#f2f2f2",
  Gray30: "#4D4D4D",
  Gray40: "#C4C4C4",
  Gray50: "#A6A6A6", //gray300
  Gray60: "#333333", //gray500
  Gray70: "#f1f1f1", //light gray
  Gray80: "#D9D9D9", //gray100
  Gray90: "#666666", //gray900
  Gray100: "#b3b3b3",
  Gray200: "#e6e6e6",
  Gray300: "#e5e5e5",
  Gray400: "#cfcfcf",
  Gray500: "#cbbac9",
  Gray600: "#6d6d6d",
  Gray700: "#88ab92",
  Gray800: "#e9e9e9",
  Gray900: "#b2b2b2",
  Gray1000: "#7A7A7A", //gray400
  Gray1010: "#EAEEFB", //gray200// lightPurple100
  Gray1020: "#f4f4f5", //gray600 // grayWHite
  Gray1030: "#D3D3D3", //// gray800

  // Shadow Colors (RGBA)
  DefaultShadow: "rgba(203, 186, 201, 0.12)",
  SoftShadow: "rgba(109, 109, 109, 0.15)",
  RadioShadow: "rgba(136, 171, 146, 0.31)",
  TagShadow: "rgba(233, 233, 233, 0.5)",
  FloatingButtonShadow: "rgb(65, 65, 65, 0.24)",
  PressedButtonShadow: "rgba(253, 71, 18, 0.34)",
  FloatingButtonGrayShadow: "rgba(179, 179, 179, 0.24)",
  PressedButtonGrayShadow: "rgba(178, 178, 178, 0.44)",
  CardShadow: "rgba(210, 210, 210, 0.28)",
  ModalBackdrop: "rgba(0, 0, 0, 0.85)",
  RedLightTransparent: "rgba(235, 84, 84, 0.15)",
  Grey100Transparent: "#d9d9d91a", // rgba(217, 217, 217, 0.1) //
  Grey200Transparent: "#d9d9d933", // rgba(217, 217, 217, 0.2) //
  Grey300Transparent: "#d9d9d94d", // rgba(217, 217, 217, 0.3) //
  Grey400Transparent: "#d9d9d966", // rgba(217, 217, 217, 0.4) //
  Grey500Transparent: "#d9d9d980", // rgba(217, 217, 217, 0.5) //
  Grey600Transparent: "#d9d9d999", // rgba(217, 217, 217, 0.6) //
  Grey700Transparent: "#d9d9d9b3", // rgba(217, 217, 217, 0.7) //
  Grey800Transparent: "#d9d9d9cc", // rgba(217, 217, 217, 0.8) //
  Grey900Transparent: "#d9d9d9e6", // rgba(217, 217, 217, 0.9) //
  // secondary
  Secondary: "#5BBCC9",

  ChartColor1: "#66c782",
  ChartColor2: "#cf6565",
};

export const ChartColor = [COLORS?.ChartColor2, COLORS?.ChartColor1];
