import { ReactNode } from 'react';
import { HEADING_TAGS } from '../../constants/common';
import { TypographyType } from '../../types/common';
import { Text } from './Typography.styled';

type HeadingType = {
  variant: TypographyType;
  icon?: string | ReactNode;
  title: string | number | ReactNode;
  className?: string;
  disabled?: boolean;
  color?: string;
  fontWeight?: 400 | 500 | 600 | 700;
  onClick?: any;
};

const Heading = ({
  variant,
  icon,
  title,
  className,
  disabled,
  color,
  fontWeight,
  onClick,
  ...rest
}: HeadingType) => {
  const textTag = HEADING_TAGS.includes(variant) ? variant : 'span';
  return (
    <Text
      className={className}
      variant={variant}
      as={textTag}
      color={color}
      fontWeight={fontWeight}
      onClick={onClick}
      {...rest}
    >
      {icon}
      {title}
    </Text>
  );
};

export default Heading;
