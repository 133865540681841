/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASProjectDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASProjectDto_Drop_WMS_OutboundService_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HandheldVasConfirmService {

    /**
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASProjectDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldVAsConfirmVASProjectList(
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASProjectDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-vASConfirm/v-aSProject-list',
            query: {
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppHandheldVAsConfirmOrderDetail(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_VASOrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/handheld-vASConfirm/order-detail/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppHandheldVAsConfirmVASConfirmOrder(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/handheld-vASConfirm/v-aSConfirm-order/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
