import { useQuery } from "react-query";
import { OutboundService } from "../../services/drop-wms-outbound-services";

export const useGetQCImageData = (
    handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
    id: any
  ) => {
    const detailsData = useQuery(
      ["getQCImageData", id],
      async () => {
        return await handleRequest(
          OutboundService.getApiOutboundApiAppOutboundOutboundQCPhotoList(id)
        );
      },
      {
        enabled: !!id,
      }
    );
  
    return detailsData;
  };