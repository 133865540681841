import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { BaseStationService } from "../../services/drop-wms-location-services";

export const useGetLocationBaseStationListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { sorting, skipCount, maxResultCount, filter } = tableInfo; //Filter by IP
  const responseListData = useQuery(
    ["getLocationBaseStationList", sorting, skipCount, maxResultCount, filter],
    async () => {
      return await handleRequest(
        BaseStationService.getApiLocationApiAppBaseStation1(
          sorting,
          skipCount,
          maxResultCount,
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetAllLocationBaseStationListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllLocationBaseStationList"],
    async () => {
      return await handleRequest(
        BaseStationService.getApiLocationApiAppBaseStationBaseStations()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetLocationBaseStationDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseLocationBaseStationDetailView", id],
    async () => {
      return await handleRequest(
        BaseStationService.getApiLocationApiAppBaseStation(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};
