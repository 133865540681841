/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitInventoryReportDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitInventoryReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransitInventoryReportService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitInventoryReportDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitInventoryReportReport(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitInventoryReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-inventory-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitInventoryReportExportExcel(
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-inventory-report/export-excel',
            query: {
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitInventoryReportExportPdf(
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-inventory-report/export-pdf',
            query: {
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skuPartNumber
     * @param locationName
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptDate
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitInventoryReportExportCsv(
        skuPartNumber?: string,
        locationName?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptDate?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-inventory-report/export-csv',
            query: {
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptDate': receiptDate,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
