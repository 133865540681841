import { lazy } from "react";
import * as routeUrl from "./routeUrl";

export const RouteList = [
  {
    path: routeUrl.HOME.url,
    component: lazy(() => import("../pages/home")),
    exact: true,
  },
  {
    path: routeUrl.TAGS.url,
    component: lazy(() => import("../pages/warehouse/setup/tag")),
    exact: true,
  },
  {
    path: routeUrl.SKUS.url,
    component: lazy(() => import("../pages/warehouse/setup/sku")),
    exact: true,
  },
  {
    path: routeUrl.CUSTOMER.url,
    component: lazy(() => import("../pages/warehouse/setup/customer")),
    exact: true,
  },
  {
    path: routeUrl.INBOUND_QC_CHECKLIST.url,
    component: lazy(
      () => import("../pages/warehouse/setup/inbound-qc-checklist")
    ),
    exact: true,
  },
  {
    path: routeUrl.OUTBOUND_QC_CHECKLIST.url,
    component: lazy(
      () => import("../pages/warehouse/setup/outbound-qc-checklist")
    ),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_ROLE.url,
    component: lazy(() => import("../pages/warehouse/settings/warehouseRole")),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_USER.url,
    component: lazy(() => import("../pages/warehouse/settings/warehouseUser")),
    exact: true,
  },

  {
    path: routeUrl.BASE_STATION.url,
    component: lazy(
      () => import("../pages/warehouse/setup/locations/base-station")
    ),
    exact: true,
  },

  {
    path: routeUrl.MAP.url,
    component: lazy(() => import("../pages/warehouse/setup/locations/map")),
    exact: true,
  },

  {
    path: routeUrl.LOCATION.url,
    component: lazy(
      () => import("../pages/warehouse/setup/locations/location")
    ),
    exact: true,
  },

  // {
  //   path: routeUrl.HANDHELD_DASHBOARD.url,
  //   component: lazy(() => import("../pages/handheld/dashboard")),
  //   exact: true,
  // },

  {
    path: routeUrl.WAREHOUSE.url,
    component: lazy(() => import("../pages/warehouse/settings/warehouse")),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_OWNER.url,
    component: lazy(() => import("../pages/warehouse/settings/warehouseOwner")),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_ADMIN_USER.url,
    component: lazy(
      () => import("../pages/warehouse/settings/warehouseAdminUser")
    ),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_INBOUND.url,
    component: lazy(() => import("../pages/warehouse/inbounds/inbound")),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_PUTAWAY.url,
    component: lazy(() => import("../pages/warehouse/inbounds/putaway")),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_INBOUND_CRATE_WITHOUT_TAG.url,
    component: lazy(
      () => import("../pages/warehouse/inbounds/crate-without-tag")
    ),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_HOLD_UNHOLD.url,
    component: lazy(() => import("../pages/warehouse/holdUnhold")),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_OUTBOUND_ORDER.url,
    component: lazy(() => import("../pages/warehouse/outbound/order")),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_EDI_INCOMING_LOG.url,
    component: lazy(() => import("../pages/warehouse/edi/incomingLog")),
    exact: true,
  },

  {
    path: routeUrl.WAREHOUSE_EDI_OUTGOING_LOG.url,
    component: lazy(() => import("../pages/warehouse/edi/outgoingLog")),
    exact: true,
  },
  {
    path: routeUrl.OVERALL_INVENTORY_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/overall-inventory")),
    exact: true,
  },
  {
    path: routeUrl.INVENTORY_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/inventory")),
    exact: true,
  },
  {
    path: routeUrl.DAILY_OUTGOING_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/daily-outgoing")),
    exact: true,
  },
  {
    path: routeUrl.DAILY_RECEIVING_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/daily-receiving")),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_CYCLE_COUNT.url,
    component: lazy(() => import("../pages/warehouse/cycleCount")),
    exact: true,
  },
  {
    path: routeUrl.WAREHOUSE_CYCLE_COUNT_PRINT_PDF.url,
    component: lazy(() => import("../pages/warehouse/cycleCount/print-pdf")),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_PENDING_ORDER.url,
    component: lazy(() => import("../pages/transit-warehouse/pending")),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_RECEIVED_ORDER.url,
    component: lazy(() => import("../pages/transit-warehouse/received")),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_SHIPPED_OUT_ORDER.url,
    component: lazy(() => import("../pages/transit-warehouse/shipped-out")),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_INVENTORY_REPORT.url,
    component: lazy(
      () => import("../pages/transit-warehouse/inventory-report")
    ),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_TRANSFER_IN_REPORT.url,
    component: lazy(
      () => import("../pages/transit-warehouse/transfer-in-report")
    ),
    exact: true,
  },
  {
    path: routeUrl.TRANSIT_OUTGOING_REPORT.url,
    component: lazy(
      () => import("../pages/transit-warehouse/daily-outgoing-report")
    ),
    exact: true,
  },
  {
    path: routeUrl.TRANSFER_ORDER_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/transfer-order")),
    exact: true,
  },
  {
    path: routeUrl.TRANSFER_OUT_FUNCTIONAL_ORDER_REPORT.url,
    component: lazy(() => import("../pages/warehouse/report/transfer-out-functional-order")),
    exact: true,
  },
  {
    path: routeUrl.PROFILE_CHANGE_PASSWORD.url,
    component: lazy(() => import("../pages/profile/change-password")),
    exact: true,
  },
  // Page Not Found
  {
    path: "/page-not-found",
    component: lazy(() => import("../pages/PageNotFound")),
    exact: true,
  },
];

export const HandheldRouteList = [
  {
    path: routeUrl.HANDHELD_DAILY_SUMMARY.url,
    component: lazy(() => import("../pages/handheld/daily-summary")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_INBOUND.url,
    component: lazy(() => import("../pages/handheld/inbound")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_INBOUND_ASN.url,
    component: lazy(() => import("../pages/handheld/inbound/asn")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_INBOUND_RECEIVING.url,
    component: lazy(() => import("../pages/handheld/inbound/receiving")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_INBOUND_CHECKLIST.url,
    component: lazy(() => import("../pages/handheld/inbound-checklist")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_INBOUND_QC_CHECKLIST.url,
    component: lazy(
      () => import("../pages/handheld/inbound-checklist/qcChecklistQuestion")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_PUT_AWAY.url,
    component: lazy(() => import("../pages/handheld/put-away")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND.url,
    component: lazy(() => import("../pages/handheld/outbound")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_ORDER_DETAIL.url,
    component: lazy(() => import("../pages/handheld/outbound/orderDetail")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_ORDER_STAGING.url,
    component: lazy(
      () => import("../pages/handheld/outbound/orderDetail/staging")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_CHECKLIST.url,
    component: lazy(() => import("../pages/handheld/outbound-checklist")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_QC_CHECKLIST_BY_ORDER.url,
    component: lazy(
      () => import("../pages/handheld/outbound-checklist/qcChecklistByOrder")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_QC_CHECKLIST_QUESTION.url,
    component: lazy(
      () => import("../pages/handheld/outbound-checklist/qcChecklistQuestion")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_QC_PHOTO_BY_ORDER_DETAIL.url,
    component: lazy(
      () =>
        import("../pages/handheld/outbound-checklist/qcPhotoListByOrderDetail")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_QC_PHOTO_UPLOAD.url,
    component: lazy(
      () => import("../pages/handheld/outbound-checklist/uploadQCPhoto")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_VAS_CONFIRM.url,
    component: lazy(() => import("../pages/handheld/vas-confirm")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_OUTBOUND_VAS_CONFIRM_BY_ORDER.url,
    component: lazy(
      () => import("../pages/handheld/vas-confirm/vas-confirm-by-order")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_SPOTTER.url,
    component: lazy(() => import("../pages/handheld/spotter")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_SPOTTER_BY_ORDER.url,
    component: lazy(() => import("../pages/handheld/spotter/spotter-by-order")),
    exact: true,
  },
  // {
  //   path: routeUrl.HANDHELD_SPOTTER_TRANSFER_ORDER.url,
  //   component: lazy(() => import("../pages/handheld/spotter/transfer-order")),
  //   exact: true,
  // },
  {
    path: routeUrl.HANDHELD_CYCLE_COUNT.url,
    component: lazy(() => import("../pages/handheld/cycle-count")),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_CYCLE_COUNT_LOCATION.url,
    component: lazy(
      () => import("../pages/handheld/cycle-count/cycle-count-location")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_CYCLE_COUNT_BY_LP.url,
    component: lazy(
      () => import("../pages/handheld/cycle-count/cycle-count-by-lp")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_CYCLE_COUNT_SCAN_LP.url,
    component: lazy(
      () => import("../pages/handheld/cycle-count/cycle-count-lp-scan")
    ),
    exact: true,
  },
  {
    path: routeUrl.HANDHELD_DASHBOARD.url,
    component: lazy(() => import("../pages/handheld/dashboard")),
    exact: true,
  },
  {
    path: "/page-not-found",
    component: lazy(() => import("../pages/PageNotFound")),
    exact: true,
  },
];
