import { useQuery } from "react-query";
import { HandheldDailySummaryService } from "../../services/drop-wms-inbound-services";
import { HandheldDailySummaryService as HandheldOutboundDailySummaryService } from "../../services/drop-wms-outbound-services";

export const useGetHandheldInboundDailySummaryData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getHandheldDailySummaryData"],
    async () => {
      return await handleRequest(
        HandheldDailySummaryService.getApiInboundApiAppHandheldDailySummaryReport()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetHandheldOutboundDailySummaryData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getHandheldOutboundDailySummaryData"],
    async () => {
      return await handleRequest(
        HandheldOutboundDailySummaryService.getApiOutboundApiAppHandheldDailySummaryReport()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
