import _ from "lodash";
import { OptionType } from "../types/common";

const permissionIdentifier = "DropWMS.WarehouseUser.";
const permissionIdentifierHandheld = "DropWMS.WarehouseUser.Handheld.";
const administrationPermissionIdentifier = "DropWMS.Administration.";

export const getOptionLabel = (
  options: OptionType[],
  value: string | undefined
) => {
  return options.find((option) => option.value === value)?.label;
};

// Format data to object
export const restructureNonHandheldPermissions = (
  data: string[],
  permissionIdentifier: string,
  removeText?: string
) => {
  return data?.map((item: string) => {
    let permissionParts = item.replace(permissionIdentifier, "").split(".");
    return {
      groupKey: permissionParts[0],
      key: item,
      text:
        permissionParts.length > 1 ? permissionParts[1] : removeText || "View",
      checked: false,
    };
  });
};

// Filter data which has permissionIdentifier
const getWarehouseUserPolicies = (policies: any) =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      return item.startsWith(permissionIdentifier);
    });

// Filter data which don't have Handheld
export const getNonHandheldPermissions = (permissions: any) => {
  return permissions.filter((item: any) => {
    return !item.includes(".Handheld.");
  });
};

// Filter data which have Handheld
export const getHandheldPermissions = (permissions: any) => {
  return permissions.filter((item: any) => {
    return item.includes(".Handheld.");
  });
};

const getAdministrationPolicies = (policies: any) =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      return item.startsWith(administrationPermissionIdentifier);
    });

// Filter data which don't have Handheld
// export const getAdministrationPermissions = (permissions: any) => {
//   return permissions.filter((item: any) => {
//     // return item.includes(".Handheld.");
//   });
// };

// Converts Strings Data with value to groups objects
export const getFilteredPermissionData = (policies: any, isArray?: boolean) => {
  let vendorPolicies = isArray ? policies : getWarehouseUserPolicies(policies);
  let administrationPolicies = isArray
    ? policies
    : getAdministrationPolicies(policies);

  let nonHandheldPermissions = getNonHandheldPermissions(vendorPolicies);
  let HandheldPermissions = getHandheldPermissions(vendorPolicies);

  const nonHandheldPermissionsData = restructureNonHandheldPermissions(
    nonHandheldPermissions,
    permissionIdentifier
  );

  const handheldPermissionsData = restructureNonHandheldPermissions(
    HandheldPermissions,
    permissionIdentifierHandheld
  );

  const administrationPermissionsData = restructureNonHandheldPermissions(
    administrationPolicies,
    administrationPermissionIdentifier
  );

  const nonHandheldPermissionsGroups = _.groupBy(
    nonHandheldPermissionsData,
    (item) => item.groupKey
  );

  const handheldPermissionsGroups = _.groupBy(
    handheldPermissionsData,
    (item) => item.groupKey
  );

  const administrationPermissionsGroups = _.groupBy(
    administrationPermissionsData,
    (item) => item.groupKey
  );
  // let mergedPermissions = handheldPermissionsData
  //   .concat(nonHandheldPermissionsData)
  //   .concat(administrationPermissionsData);
  //   console.log(mergedPermissions)
  let mergedDesktopPermissions = {
    ...administrationPermissionsData.concat(nonHandheldPermissionsData),
  };
  const desktopPermissionsGroups = _.groupBy(
    mergedDesktopPermissions,
    (item) => item.groupKey
  );
  // const permissionsGroups = _.groupBy(permissions, (item) => item.groupKey);

  return {
    nonHandheldPermissionsGroups,
    handheldPermissionsGroups,
    // permissionsGroups,
    administrationPermissionsGroups,
    desktopPermissionsGroups,
  };
};

export const containsValueInArray = (
  valueToCheck: string,
  arrayToSearch: any[]
) => {
  if (arrayToSearch.length) {
    const matchingItems = arrayToSearch.filter(
      (item: any) => item.id === valueToCheck
    );
    return matchingItems.length > 0;
  }
  return false;
};
