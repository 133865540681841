import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinners from '../../components/Spinner';
import AuthService from '../../services/authService';

function Login() {
  const authService = new AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate('/');
    } else {
      authService.signInRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinners containerClassName='h-normal-screen' />;
}

export default Login;
