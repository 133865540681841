import { useQuery } from "react-query";
import { IncomingEdiLogService } from "../../services/drop-wms-edi-services";
import { TableParamsType } from "../../types/common";

export const useGetEdiIncomingLogListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  taskId?: string,
  ediType?: string,
  isConsumed?: boolean,
  consumedAt?: string,
  projectCode?: string,
  licensePlateNumber?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getEdiIncomingLogList",
      sorting,
      skipCount,
      maxResultCount,
      taskId,
      ediType,
      isConsumed,
      consumedAt,
      projectCode,
      licensePlateNumber,
    ],
    async () => {
      return await handleRequest(
        IncomingEdiLogService.getApiEdiApiAppIncomingEdiLog1(
          sorting,
          skipCount,
          maxResultCount,
          taskId?.trim(),
          ediType,
          isConsumed,
          consumedAt,
          projectCode?.trim(),
          licensePlateNumber?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetEdiIncomingLogDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseEdiIncomingLogDetailView", id],
    async () => {
      return await handleRequest(
        IncomingEdiLogService.getApiEdiApiAppIncomingEdiLog(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};

export const useGetIncomingEdiTypeListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getIncomingEdiTypeList"],
    async () => {
      return await handleRequest(
        IncomingEdiLogService.getApiEdiApiAppIncomingEdiLogEdiTypes()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
