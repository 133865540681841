import { useQuery } from "react-query";
import { SkuService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetSkuListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  partNumber: string,
  description: string,
  isMagnetized: any,
  unitOfMeasurement: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const SkuListData = useQuery(
    [
      "getSkuList",
      sorting,
      skipCount,
      maxResultCount,
      partNumber,
      description,
      isMagnetized,
      unitOfMeasurement,
    ],
    async () => {
      return await handleRequest(
        SkuService.getApiInboundApiAppSKU1(
          sorting,
          skipCount,
          maxResultCount,
          partNumber?.trim(),
          description?.trim(),
          isMagnetized,
          unitOfMeasurement?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return SkuListData;
};

export const useGetSkuDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const tagDetailsData = useQuery(
    ["skuDetailsView", id],
    async () => {
      return await handleRequest(SkuService.getApiInboundApiAppSKU(id));
    },
    {
      enabled: !!id,
    }
  );

  return tagDetailsData;
};
