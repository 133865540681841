import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { MapService } from "../../services/drop-wms-location-services";

export const useGetLocationMapListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  displayName: string,
  status: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getLocationMapList",
      sorting,
      skipCount,
      maxResultCount,
      displayName,
      status,
    ],
    async () => {
      return await handleRequest(
        MapService.getApiLocationApiAppMap1(
          sorting,
          skipCount,
          maxResultCount,
          displayName?.trim(),
          status
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetAllLocationMapListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllLocationMapList"],
    async () => {
      return await handleRequest(MapService.getApiLocationApiAppMapMaps());
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetLocationMapDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["warehouseLocationMapDetailView", id],
    async () => {
      return await handleRequest(MapService.getApiLocationApiAppMap(id));
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};
