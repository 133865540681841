/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_InboundService_Interfaces_Tags_TagCreateUpdateDto } from '../models/Drop_WMS_InboundService_Interfaces_Tags_TagCreateUpdateDto';
import type { Drop_WMS_Shared_Hosting_Enums_TagType } from '../models/Drop_WMS_Shared_Hosting_Enums_TagType';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppTag(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/tag/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiInboundApiAppTag(
        id: string,
        requestBody?: Drop_WMS_InboundService_Interfaces_Tags_TagCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/inbound/api/app/tag/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static deleteApiInboundApiAppTag(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/inbound/api/app/tag/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param tagMac
     * @param enrollmentStatus
     * @param status
     * @param tagTypeId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppTag1(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        tagMac?: string,
        enrollmentStatus?: boolean,
        status?: boolean,
        tagTypeId?: Drop_WMS_Shared_Hosting_Enums_TagType,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/tag',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TagMac': tagMac,
                'EnrollmentStatus': enrollmentStatus,
                'Status': status,
                'TagTypeId': tagTypeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiInboundApiAppTag(
        requestBody?: Drop_WMS_InboundService_Interfaces_Tags_TagCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_InboundService_Interfaces_Tags_TagDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
