import { ReactNode } from "react";
import { COLORS } from "../../constants/style/Color";
import { ModalStatusType } from "../../types/common";
// import CheckPermissions from '../../utils/checkPermission';
import Spinners from "../Spinner";
import { Btn } from "./Button.styled";

type ButtonType = {
  variant:
    | "primary"
    | "primary-outline"
    | "primary-dark"
    | "secondary"
    | "secondary-dark"
    | "secondary-outline"
    | "links"
    | "rounded"
    | string;
  icon?: string | ReactNode;
  title: string | ReactNode;
  onClick?: any;
  btnClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  as?: ModalStatusType;
  hasId?: string;
  type?: "button" | "submit" | "reset";
  fontColor?: string;
  borderColor?: string;
};

const Button = ({
  variant,
  icon,
  title,
  onClick,
  btnClassName,
  loading,
  type,
  disabled,
  hasId,
  as,
  fontColor,
  borderColor,
  ...rest
}: ButtonType) => {
  return (
    // <CheckPermissions type={as} urlName={hasId}>
    <Btn
      className={btnClassName}
      type={type}
      variant={variant}
      onClick={onClick}
      fontColor={fontColor}
      borderColor={borderColor}
      as="button"
      disabled={disabled || loading}
      id={`${title}`}
      {...rest}
    >
      {icon && <span className="material-symbols-outlined">{icon}</span>}
      {loading ? <Spinners borderColor={COLORS.Orange} size="sm" /> : title}
    </Btn>
    // </CheckPermissions>
  );
};

export default Button;
