import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { HandheldRouteList, RouteList } from "./RoutesList";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/auth/Login";
import AuthService from "../services/authService";
import Callback from "../pages/auth/Callback";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../pages/auth/authSlice";
import Spinners from "../components/Spinner";
import SignOutCallback from "../pages/auth/SignOutCallback";

const RoutesContainer = ({ isDesktop, isLoadingData }) => {
  const authService = new AuthService();
  const isAuthenticated =
    useSelector(selectIsAuthenticated) || authService.isAuthenticated();
  return (
    <Suspense fallback={<Spinners />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/signout-callback-oidc" element={<SignOutCallback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {
            !isLoadingData && (
              <>
                {isDesktop ? (
                  <>
                    {RouteList?.map((route, i) => (
                      <Route
                        path={route.path}
                        element={<route.component />}
                        key={i}
                        exact={route.exact}
                      />
                    ))}
                    <Route path="*" element={<Navigate to="/page-not-found" replace />} />
                  </>
                ) : (
                  <>
                    {HandheldRouteList?.map((route, i) => (
                      <Route
                        path={route.path}
                        element={<route.component />}
                        key={i}
                        exact={route.exact}
                      />
                    ))}
                    <Route path="*" element={<Navigate to="/page-not-found" replace />} />
                  </>
                )}
              </>
            )
          }
          <Route
            path="/"
            element={<Navigate to="/" replace key={isDesktop ? "desktop" : "handheld"} />}
          />
        </Route>
      </Routes>
    </Suspense >
  );
};

export default RoutesContainer;
