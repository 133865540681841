import { useQuery } from "react-query";
import { TableParamsType } from "../../types/common";
import { HandheldOutboundQcChecklistService } from "../../services/drop-wms-outbound-services";

export const useGetHandheldOutboundQCChecklistData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { skipCount, maxResultCount, filter } = tableInfo; //Filter by Project#
  const responseData = useQuery(
    ["getHandheldOutboundQCCheckList", skipCount, maxResultCount, filter],
    async () => {
      return await handleRequest(
        HandheldOutboundQcChecklistService.getApiOutboundApiAppHandheldOutboundQCChecklistProjectForOutboundQCChecklist(
          skipCount,
          maxResultCount,
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetHandheldOutboundQCChecklistLPOrderDetailByOrder = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string
) => {
  const responseData = useQuery(
    ["getHandheldOutboundQCChecklistLPOrderDetailByOrder", orderId],
    async () => {
      return await handleRequest(
        HandheldOutboundQcChecklistService.getApiOutboundApiAppHandheldOutboundQCChecklistLPOrderDetailByOrderId(
          orderId
        )
      );
    },
    {
      enabled: !!orderId,
    }
  );
  return responseData;
};

export const useGetHandheldOutboundQCChecklistLPOutboundQCChecklistByOrderDetail =
  (
    handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
    orderDetailId: string
  ) => {
    const responseData = useQuery(
      [
        "getHandheldOutboundQCChecklistLPOutboundQCChecklistByOrderDetail",
        orderDetailId,
      ],
      async () => {
        return await handleRequest(
          HandheldOutboundQcChecklistService.getApiOutboundApiAppHandheldOutboundQCChecklistLPOutboundQCChecklistByOrderDetailId(
            orderDetailId
          )
        );
      },
      {
        enabled: !!orderDetailId,
      }
    );
    return responseData;
  };

export const useGetHandheldOutboundQCChecklistOutboundQCPhotoListByOrderDetail =
  (
    handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
    orderDetailId: string
  ) => {
    const responseData = useQuery(
      ["getHandheldOutboundQCChecklistOutboundQCPhotoList", orderDetailId],
      async () => {
        return await handleRequest(
          HandheldOutboundQcChecklistService.getApiOutboundApiAppHandheldOutboundQCChecklistOutboundQCPhotoList(
            orderDetailId
          )
        );
      },
      {
        enabled: !!orderDetailId,
      }
    );
    return responseData;
  };
