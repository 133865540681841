import { useQuery } from "react-query";
import { UserService } from "../../services/drop-wms-identity-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseAdminUserListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  tenantId: any,
  employeeCode?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  email?: string,
  userStatus?: boolean
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getWarehouseAdminUserList",
      sorting,
      skipCount,
      maxResultCount,
      tenantId,
      employeeCode,
      firstName,
      middleName,
      lastName,
      email,
      userStatus,
    ],
    async () => {
      if (tenantId) {
        return await handleRequest(
          UserService.getApiIdentityApiAppUserWarehouseAdminUserListByFilter(
            tenantId,
            sorting,
            skipCount,
            maxResultCount,
            employeeCode?.trim(),
            firstName?.trim(),
            middleName?.trim(),
            lastName?.trim(),
            email?.trim(),
            userStatus
          )
        );
      }
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
