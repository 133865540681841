/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Interfaces_Users_WarehouseAdminUserCreateDto } from '../models/Drop_WMS_Interfaces_Users_WarehouseAdminUserCreateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';
import type { Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfVolo_Abp_Identity_IdentityUserDto_Volo_Abp_Identity_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfVolo_Abp_Identity_IdentityUserDto_Volo_Abp_Identity_Application_Contracts';
import type { Volo_Abp_Identity_IdentityUserCreateDto } from '../models/Volo_Abp_Identity_IdentityUserCreateDto';
import type { Volo_Abp_Identity_IdentityUserDto } from '../models/Volo_Abp_Identity_IdentityUserDto';
import type { Volo_Abp_Identity_IdentityUserUpdateDto } from '../models/Volo_Abp_Identity_IdentityUserUpdateDto';
import type { Volo_Abp_Identity_IdentityUserUpdateRolesDto } from '../models/Volo_Abp_Identity_IdentityUserUpdateRolesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id 
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsers(
id: string,
): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static putApiIdentityApiIdentityUsers(
id: string,
requestBody?: Volo_Abp_Identity_IdentityUserUpdateDto,
): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/identity/api/identity/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiIdentityApiIdentityUsers(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/identity/api/identity/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfVolo_Abp_Identity_IdentityUserDto_Volo_Abp_Identity_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsers1(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfVolo_Abp_Identity_IdentityUserDto_Volo_Abp_Identity_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiIdentityUsers(
requestBody?: Volo_Abp_Identity_IdentityUserCreateDto,
): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/identity/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsersRoles(
id: string,
): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users/{id}/roles',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiIdentityApiIdentityUsersRoles(
id: string,
requestBody?: Volo_Abp_Identity_IdentityUserUpdateRolesDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/identity/api/identity/users/{id}/roles',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsersAssignableRoles(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1OfOfVolo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users/assignable-roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userName 
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsersByUsername(
userName: string,
): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users/by-username/{userName}',
            path: {
                'userName': userName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param email 
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiIdentityUsersByEmail(
email: string,
): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/identity/users/by-email/{email}',
            path: {
                'email': email,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserWarehouseAdminUser(
requestBody?: Drop_WMS_Interfaces_Users_WarehouseAdminUserCreateDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user/warehouse-admin-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tenantId 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param employeeCode 
     * @param firstName 
     * @param middleName 
     * @param lastName 
     * @param email 
     * @param userStatus 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppUserWarehouseAdminUserListByFilter(
tenantId: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
employeeCode?: string,
firstName?: string,
middleName?: string,
lastName?: string,
email?: string,
userStatus?: boolean,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_Users_WarehouseAdminUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/user/warehouse-admin-user-list-by-filter',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TenantId': tenantId,
                'EmployeeCode': employeeCode,
                'FirstName': firstName,
                'MiddleName': middleName,
                'LastName': lastName,
                'Email': email,
                'UserStatus': userStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppUserResetPassword(
userId: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/user/reset-password/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
