import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ToastComponent from "../../components/Toast";
import { WarehouseRoleService } from "../../services/drop-wms-identity-services";

const usePostSystemRoles = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void,
  id?: string
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      if (id) {
        return await handleRequest(
          WarehouseRoleService.putApiIdentityApiAppWarehouseRole(id, payload)
        );
      }
      return await handleRequest(
        WarehouseRoleService.postApiIdentityApiAppWarehouseRole(payload)
      );
    },
    {
      onSuccess: (response: any) => {
        if (response) {
          queryClient.invalidateQueries("getWarehouseRoleList");
          closeModal();
          toast.success(
            <ToastComponent
              label="Success"
              message={`Role ${id ? "edited" : "added"}`}
            />
          );
        }
      },
      onError: (error: any) => {
        toast.error(
          <ToastComponent label="Error" message={error} />
        );
      },
    }
  );
};

export default usePostSystemRoles;
