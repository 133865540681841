/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Reports_UserDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Reports_UserDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_DailyReceivingReportDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_DailyReceivingReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DailyReceivingReportService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param asnNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param tagMac
     * @param crateNumber
     * @param tote
     * @param receiveFromDate
     * @param receiveToDate
     * @param receivedBy
     * @param inboundQcChecklistCompletedFromDate
     * @param inboundQcChecklistCompletedToDate
     * @param inboundQcChecklistCompletedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_DailyReceivingReportDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppDailyReceivingReportReport(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        asnNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        crateNumber?: string,
        tote?: string,
        receiveFromDate?: string,
        receiveToDate?: string,
        receivedBy?: string,
        inboundQcChecklistCompletedFromDate?: string,
        inboundQcChecklistCompletedToDate?: string,
        inboundQcChecklistCompletedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Reports_DailyReceivingReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/daily-receiving-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'AsnNumber': asnNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceiveFromDate': receiveFromDate,
                'ReceiveToDate': receiveToDate,
                'ReceivedBy': receivedBy,
                'InboundQCChecklistCompletedFromDate': inboundQcChecklistCompletedFromDate,
                'InboundQCChecklistCompletedToDate': inboundQcChecklistCompletedToDate,
                'InboundQCChecklistCompletedBy': inboundQcChecklistCompletedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param asnNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param tagMac
     * @param crateNumber
     * @param tote
     * @param receiveFromDate
     * @param receiveToDate
     * @param receivedBy
     * @param inboundQcChecklistCompletedFromDate
     * @param inboundQcChecklistCompletedToDate
     * @param inboundQcChecklistCompletedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppDailyReceivingReportExportExcel(
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        asnNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        crateNumber?: string,
        tote?: string,
        receiveFromDate?: string,
        receiveToDate?: string,
        receivedBy?: string,
        inboundQcChecklistCompletedFromDate?: string,
        inboundQcChecklistCompletedToDate?: string,
        inboundQcChecklistCompletedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/daily-receiving-report/export-excel',
            query: {
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'AsnNumber': asnNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceiveFromDate': receiveFromDate,
                'ReceiveToDate': receiveToDate,
                'ReceivedBy': receivedBy,
                'InboundQCChecklistCompletedFromDate': inboundQcChecklistCompletedFromDate,
                'InboundQCChecklistCompletedToDate': inboundQcChecklistCompletedToDate,
                'InboundQCChecklistCompletedBy': inboundQcChecklistCompletedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Reports_UserDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppDailyReceivingReportUsers(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_Reports_UserDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/daily-receiving-report/users',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param asnNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param tagMac
     * @param crateNumber
     * @param tote
     * @param receiveFromDate
     * @param receiveToDate
     * @param receivedBy
     * @param inboundQcChecklistCompletedFromDate
     * @param inboundQcChecklistCompletedToDate
     * @param inboundQcChecklistCompletedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppDailyReceivingReportExportPdf(
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        asnNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        crateNumber?: string,
        tote?: string,
        receiveFromDate?: string,
        receiveToDate?: string,
        receivedBy?: string,
        inboundQcChecklistCompletedFromDate?: string,
        inboundQcChecklistCompletedToDate?: string,
        inboundQcChecklistCompletedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/daily-receiving-report/export-pdf',
            query: {
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'AsnNumber': asnNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceiveFromDate': receiveFromDate,
                'ReceiveToDate': receiveToDate,
                'ReceivedBy': receivedBy,
                'InboundQCChecklistCompletedFromDate': inboundQcChecklistCompletedFromDate,
                'InboundQCChecklistCompletedToDate': inboundQcChecklistCompletedToDate,
                'InboundQCChecklistCompletedBy': inboundQcChecklistCompletedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param asnNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param tagMac
     * @param crateNumber
     * @param tote
     * @param receiveFromDate
     * @param receiveToDate
     * @param receivedBy
     * @param inboundQcChecklistCompletedFromDate
     * @param inboundQcChecklistCompletedToDate
     * @param inboundQcChecklistCompletedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiInboundApiAppDailyReceivingReportExportCsv(
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        asnNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        tagMac?: string,
        crateNumber?: string,
        tote?: string,
        receiveFromDate?: string,
        receiveToDate?: string,
        receivedBy?: string,
        inboundQcChecklistCompletedFromDate?: string,
        inboundQcChecklistCompletedToDate?: string,
        inboundQcChecklistCompletedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/daily-receiving-report/export-csv',
            query: {
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'AsnNumber': asnNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'TagMac': tagMac,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceiveFromDate': receiveFromDate,
                'ReceiveToDate': receiveToDate,
                'ReceivedBy': receivedBy,
                'InboundQCChecklistCompletedFromDate': inboundQcChecklistCompletedFromDate,
                'InboundQCChecklistCompletedToDate': inboundQcChecklistCompletedToDate,
                'InboundQCChecklistCompletedBy': inboundQcChecklistCompletedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
