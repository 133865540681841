const HOME = {
  url: "/",
  name: "Home",
};
const TAGS = {
  url: "/tag",
  name: "Tags",
};
const SKUS = {
  url: "/sku",
  name: "SKUs",
};
const CUSTOMER = {
  url: "/customer",
  name: "Customers",
};
const INBOUND_QC_CHECKLIST = {
  url: "/inbound-qc-checklist",
  name: "Inbound QC Checklist",
};
const OUTBOUND_QC_CHECKLIST = {
  url: "/outbound-qc-checklist",
  name: "Outbound QC Checklist",
};

const WAREHOUSE_ROLE = {
  url: "/warehouse-role",
  name: "Warehouse Roles",
};
const WAREHOUSE_USER = {
  url: "/warehouse-user",
  name: "Warehouse Users",
};

const BASE_STATION = {
  url: "/base-station",
  name: "Base Stations",
};

const MAP = {
  url: "/map",
  name: "Maps",
};

const LOCATION = {
  url: "/location",
  name: "Locations",
};

const WAREHOUSE_OWNER = {
  url: "/warehouse-owner",
  name: "Warehouse Owners",
};

const WAREHOUSE = {
  url: "/warehouse",
  name: "Warehouses",
};

const WAREHOUSE_ADMIN_USER = {
  url: "/warehouse/warehouse-admin-user",
  name: "Warehouses Admin User",
};

const WAREHOUSE_INBOUND = {
  url: "/warehouse-inbound-receiving",
  name: "Inbound Receiving",
};
const WAREHOUSE_INBOUND_CRATE_WITHOUT_TAG = {
  url: "/warehouse-inbound-crate-without-tag",
  name: "Crate W/O Tag",
};
const WAREHOUSE_PUTAWAY = {
  url: "/warehouse-inbound-putaway",
  name: "Putaway",
};

const WAREHOUSE_HOLD_UNHOLD = {
  url: "/warehouse-hold-unhold",
  name: "Hold/Unhold",
};
const WAREHOUSE_OUTBOUND_ORDER = {
  url: "/warehouse-outbound-order",
  name: "Order",
};

const WAREHOUSE_EDI_INCOMING_LOG = {
  url: "/warehouse-edi-incoming-log",
  name: "Incoming Log",
};

const WAREHOUSE_EDI_OUTGOING_LOG = {
  url: "/warehouse-edi-outgoing-log",
  name: "Outgoing Log",
};
const OVERALL_INVENTORY_REPORT = {
  url: "/overall-inventory-report",
  name: "Overall Inventory",
};

const INVENTORY_REPORT = {
  url: "/inventory-report",
  name: "Inventory",
};

const DAILY_RECEIVING_REPORT = {
  url: "/receiving-report",
  name: "Daily Receiving",
};

const DAILY_OUTGOING_REPORT = {
  url: "/daily-outgoing-report",
  name: "Daily Outgoing",
};

const WAREHOUSE_CYCLE_COUNT = {
  url: "/cycle-count",
  name: "Cycle Count",
};

const WAREHOUSE_CYCLE_COUNT_PRINT_PDF = {
  url: "/cycle-count/print-pdf",
  name: "View Report",
};

const TRANSFER_ORDER_REPORT = {
  url: "/transfer-out-transit-order-report",
  name: "Transfer Out Transit",
};

const TRANSFER_OUT_FUNCTIONAL_ORDER_REPORT = {
  url: "/transfer-out-functional-order-report",
  name: "Transfer Out Functional",
};

const TRANSIT_PENDING_ORDER = {
  url: "/transit-pending-order",
  name: "Pending",
};
const TRANSIT_RECEIVED_ORDER = {
  url: "/transit-received-order",
  name: "Inbound",
};
const TRANSIT_SHIPPED_OUT_ORDER = {
  url: "/transit-shipped-out-order",
  name: "Outbound",
};

const TRANSIT_INVENTORY_REPORT = {
  url: "/transit-inventory-report",
  name: "Inventory",
};

const TRANSIT_OUTGOING_REPORT = {
  url: "/transit-outgoing-report",
  name: "Outgoing",
};

const TRANSIT_TRANSFER_IN_REPORT = {
  url: "/transit-transfer-in-report",
  name: "Transfer In",
};

const PROFILE_CHANGE_PASSWORD = {
  url: "/profile/change-password",
  name: "Change Password",
};

// Handheld Route Url List
const HANDHELD_DASHBOARD = {
  url: "/",
  name: "Dashboard",
};

const HANDHELD_DAILY_SUMMARY = {
  url: "/handheld-daily-summary",
  name: "Daily Summary",
};

const HANDHELD_INBOUND = {
  url: "/handheld-inbound",
  name: "Inbound",
};

const HANDHELD_INBOUND_ASN = {
  url: "/handheld-inbound/asn",
  name: "ASN",
};
const HANDHELD_INBOUND_RECEIVING = {
  url: "/handheld-inbound/receiving",
  name: "Receiving",
};

const HANDHELD_INBOUND_CHECKLIST = {
  url: "/handheld-inbound/inbound-qc-checklist",
  name: "Inbound QC Checklist",
};

const HANDHELD_INBOUND_QC_CHECKLIST = {
  url: "/handheld-inbound/inbound-qc-checklist/qc-checklist-question",
  name: "Inbound QC Checklist Questions",
};

const HANDHELD_PUT_AWAY = {
  url: "/handheld/put-away",
  name: "Put Away | Move",
};

const HANDHELD_OUTBOUND = {
  url: "/handheld/outbound",
  name: "Outbound",
};

const HANDHELD_OUTBOUND_ORDER_DETAIL = {
  url: "/handheld/outbound/outbound-order-detail",
  name: "Outbound Order Detail",
};

const HANDHELD_OUTBOUND_ORDER_STAGING = {
  url: "/handheld/outbound/outbound-order-detail/staging",
  name: "Outbound Order Staging",
};
const HANDHELD_OUTBOUND_CHECKLIST = {
  url: "/handheld/outbound-checklist",
  name: "Outbound QC Checklist",
};
const HANDHELD_OUTBOUND_QC_CHECKLIST_BY_ORDER = {
  url: "/handheld/outbound-qc-checklist/outbound-qc-checklist-by-order",
  name: "Outbound QC Checklist By Order",
};
const HANDHELD_OUTBOUND_QC_CHECKLIST_QUESTION = {
  url: "/handheld/outbound-qc-checklist/outbound-qc-checklist-questions",
  name: "Outbound QC Checklist Question",
};
const HANDHELD_OUTBOUND_QC_PHOTO_BY_ORDER_DETAIL = {
  url: "/handheld/outbound-qc-checklist/outbound-qc-image-by-order-detail",
  name: "Outbound QC Image",
};
const HANDHELD_OUTBOUND_QC_PHOTO_UPLOAD = {
  url: "/handheld/outbound-qc-checklist/outbound-qc-photo-upload",
  name: "Capture QC Image",
};

const HANDHELD_OUTBOUND_VAS_CONFIRM = {
  url: "/handheld/outbound-vas-confirm",
  name: "VAS Confirm",
};
const HANDHELD_OUTBOUND_VAS_CONFIRM_BY_ORDER = {
  url: "/handheld/outbound-vas-confirm-by-order",
  name: "VAS Confirm By Order",
};

const HANDHELD_SPOTTER = {
  url: "/handheld/spotter",
  name: "Spotter",
};

const HANDHELD_SPOTTER_BY_ORDER = {
  url: "/handheld/spotter-by-order",
  name: "Spotter By Order",
};

// const HANDHELD_SPOTTER_TRANSFER_ORDER = {
//   url: "/handheld/spotter-by-order/transfer-order",
//   name: "Transfer Order",
// };

const HANDHELD_CYCLE_COUNT = {
  url: "/handheld/cycle-count",
  name: "Cycle Count",
};

const HANDHELD_CYCLE_COUNT_LOCATION = {
  url: "/handheld/cycle-count/cycle-count-location",
  name: "Cycle Count Location",
};

const HANDHELD_CYCLE_COUNT_BY_LP = {
  url: "/handheld/cycle-count/cycle-count-location/cycle-count-by-lp",
  name: "Cycle Count By LP#",
};

const HANDHELD_CYCLE_COUNT_SCAN_LP = {
  url: "/handheld/cycle-count/cycle-count-location/cycle-count-by-lp/scan-lp",
  name: "Cycle Count LP# Scan",
};

export {
  BASE_STATION,
  CUSTOMER,
  DAILY_OUTGOING_REPORT,
  DAILY_RECEIVING_REPORT,
  HANDHELD_CYCLE_COUNT,
  HANDHELD_CYCLE_COUNT_BY_LP,
  HANDHELD_CYCLE_COUNT_LOCATION,
  HANDHELD_CYCLE_COUNT_SCAN_LP,
  HANDHELD_DAILY_SUMMARY,
  //Handheld
  HANDHELD_DASHBOARD,
  HANDHELD_INBOUND,
  HANDHELD_INBOUND_ASN,
  HANDHELD_INBOUND_CHECKLIST,
  HANDHELD_INBOUND_QC_CHECKLIST,
  HANDHELD_INBOUND_RECEIVING,
  HANDHELD_OUTBOUND,
  HANDHELD_OUTBOUND_CHECKLIST,
  HANDHELD_OUTBOUND_ORDER_DETAIL,
  HANDHELD_OUTBOUND_ORDER_STAGING,
  HANDHELD_OUTBOUND_QC_CHECKLIST_BY_ORDER,
  HANDHELD_OUTBOUND_QC_CHECKLIST_QUESTION,
  HANDHELD_OUTBOUND_QC_PHOTO_BY_ORDER_DETAIL,
  HANDHELD_OUTBOUND_QC_PHOTO_UPLOAD,
  HANDHELD_OUTBOUND_VAS_CONFIRM,
  HANDHELD_OUTBOUND_VAS_CONFIRM_BY_ORDER,
  HANDHELD_PUT_AWAY,
  HANDHELD_SPOTTER,
  HANDHELD_SPOTTER_BY_ORDER,
  // HANDHELD_SPOTTER_TRANSFER_ORDER,
  HOME,
  INBOUND_QC_CHECKLIST,
  OVERALL_INVENTORY_REPORT,
  INVENTORY_REPORT,
  LOCATION,
  MAP,
  OUTBOUND_QC_CHECKLIST,
  PROFILE_CHANGE_PASSWORD,
  SKUS,
  TAGS,
  TRANSFER_ORDER_REPORT,
  TRANSFER_OUT_FUNCTIONAL_ORDER_REPORT,
  TRANSIT_INVENTORY_REPORT,
  TRANSIT_TRANSFER_IN_REPORT,
  TRANSIT_OUTGOING_REPORT,
  TRANSIT_PENDING_ORDER,
  TRANSIT_RECEIVED_ORDER,
  TRANSIT_SHIPPED_OUT_ORDER,
  WAREHOUSE,
  WAREHOUSE_ADMIN_USER,
  WAREHOUSE_CYCLE_COUNT,
  WAREHOUSE_CYCLE_COUNT_PRINT_PDF,
  WAREHOUSE_EDI_INCOMING_LOG,
  WAREHOUSE_EDI_OUTGOING_LOG,
  WAREHOUSE_HOLD_UNHOLD,
  WAREHOUSE_INBOUND,
  WAREHOUSE_INBOUND_CRATE_WITHOUT_TAG,
  WAREHOUSE_OUTBOUND_ORDER,
  WAREHOUSE_OWNER,
  WAREHOUSE_PUTAWAY,
  WAREHOUSE_ROLE,
  WAREHOUSE_USER,
};
