/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_OutboundService_Interfaces_Desktops_PrintOutboundQCChecklistDto } from '../models/Drop_WMS_OutboundService_Interfaces_Desktops_PrintOutboundQCChecklistDto';
import type { Drop_WMS_OutboundService_Interfaces_Desktops_TransferOrderDto } from '../models/Drop_WMS_OutboundService_Interfaces_Desktops_TransferOrderDto';
import type { Drop_WMS_Shared_Hosting_Enums_OrderStatus } from '../models/Drop_WMS_Shared_Hosting_Enums_OrderStatus';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OutboundService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param orderNumber
     * @param orderDate
     * @param orderDeliveryDate
     * @param customerName
     * @param customerCountry
     * @param customerLocation
     * @param freightForwarderName
     * @param salesOrder
     * @param schenkerdoRef
     * @param bluestain
     * @param vas
     * @param pullback
     * @param orderStatus
     * @param pickingInitializedAt
     * @param shippedOutAt
     * @param isOutboundQcChecklistCompleted
     * @param isTransferred
     * @param transferredToWarehouse
     * @param projectCode
     * @param licensePlateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundOrderList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        orderNumber?: string,
        orderDate?: string,
        orderDeliveryDate?: string,
        customerName?: string,
        customerCountry?: string,
        customerLocation?: string,
        freightForwarderName?: string,
        salesOrder?: string,
        schenkerdoRef?: string,
        bluestain?: boolean,
        vas?: boolean,
        pullback?: boolean,
        orderStatus?: Drop_WMS_Shared_Hosting_Enums_OrderStatus,
        pickingInitializedAt?: string,
        shippedOutAt?: string,
        isOutboundQcChecklistCompleted?: boolean,
        isTransferred?: boolean,
        transferredToWarehouse?: string,
        projectCode?: string,
        licensePlateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/order-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'OrderNumber': orderNumber,
                'OrderDate': orderDate,
                'OrderDeliveryDate': orderDeliveryDate,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'CustomerLocation': customerLocation,
                'FreightForwarderName': freightForwarderName,
                'SalesOrder': salesOrder,
                'SchenkerdoRef': schenkerdoRef,
                'Bluestain': bluestain,
                'VAS': vas,
                'Pullback': pullback,
                'OrderStatus': orderStatus,
                'PickingInitializedAt': pickingInitializedAt,
                'ShippedOutAt': shippedOutAt,
                'IsOutboundQCChecklistCompleted': isOutboundQcChecklistCompleted,
                'IsTransferred': isTransferred,
                'TransferredToWarehouse': transferredToWarehouse,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppOutboundInitializePicking(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/outbound/initialize-picking/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @param sorting
     * @param projectCode
     * @param licensePlateNumber
     * @param isMagnetized
     * @param isUrgent
     * @param isPicked
     * @param pickedAt
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundOrderDetail(
        orderId: string,
        sorting?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        isMagnetized?: boolean,
        isUrgent?: boolean,
        isPicked?: boolean,
        pickedAt?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Desktops_OrderDetailDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/order-detail',
            query: {
                'Sorting': sorting,
                'OrderId': orderId,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'IsMagnetized': isMagnetized,
                'IsUrgent': isUrgent,
                'IsPicked': isPicked,
                'PickedAt': pickedAt,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundOrderStatus(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/order-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_OutboundService_Interfaces_Desktops_PrintOutboundQCChecklistDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundLPOutboundQCChecklist(
        orderId: string,
    ): CancelablePromise<Drop_WMS_OutboundService_Interfaces_Desktops_PrintOutboundQCChecklistDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/l-pOutbound-qCChecklist/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppOutboundUnAllocate(
        orderId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/outbound/un-allocate/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiOutboundApiAppOutboundTransferOrder(
        requestBody?: Drop_WMS_OutboundService_Interfaces_Desktops_TransferOrderDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/outbound/api/app/outbound/transfer-order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param outboundQcPhotoId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundOutboundQCPhoto(
        outboundQcPhotoId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/outbound-qCPhoto/{outboundQCPhotoId}',
            path: {
                'outboundQCPhotoId': outboundQcPhotoId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderDetailId
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOutboundOutboundQCPhotoList(
        orderDetailId: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_OutboundService_Interfaces_Handhelds_OutboundQCPhotoDto_Drop_WMS_OutboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/outbound/outbound-qCPhoto-list/{orderDetailId}',
            path: {
                'orderDetailId': orderDetailId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
