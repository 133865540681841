import { useQuery } from "react-query";
import { HandheldCycleCountService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetHandheldCycleCount = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseData = useQuery(
    ["getHandheldCycleCount"],
    async () => {
      return await handleRequest(
        HandheldCycleCountService.getApiInboundApiAppHandheldCycleCountOpenCycleCountList()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetHandheldCycleCountByLp = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  cycleCountId: "",
  physicalLocationId: "",
  tableInfo: TableParamsType
) => {
  const { skipCount, maxResultCount, filter } = tableInfo;
  const responseData = useQuery(
    [
      "getHandheldCycleCountByLpList",
      cycleCountId,
      physicalLocationId,
      skipCount,
      maxResultCount,
      filter,
    ],
    async () => {
      return await handleRequest(
        HandheldCycleCountService.getApiInboundApiAppHandheldCycleCountScannedLPList(
          cycleCountId,
          physicalLocationId,
          skipCount,
          maxResultCount,
          filter.trim()
        )
      );
    },
    {
      enabled: !!cycleCountId && !!physicalLocationId,
      keepPreviousData: true,
    }
  );

  return responseData;
};
