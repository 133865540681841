import React from 'react';
import { TypographyType } from '../../types/common';
import { SkeletonLineMain, SkeletonWrapper } from './Skeleton.Styled';

interface SkeletonLineType {
  size?: TypographyType | 'table';
  width?: string;
  className?: string;
  containerClassName?: string;
}

const SkeletonLine = ({
  size = 'table',
  width,
  className,
  containerClassName,
}: SkeletonLineType) => {
  return (
    <SkeletonWrapper className={containerClassName}>
      <SkeletonLineMain size={size} width={width || '100%'} className={className} />
    </SkeletonWrapper>
  );
};

export default SkeletonLine;
