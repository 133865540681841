import { useQuery } from "react-query";
import { OutboundQcChecklistService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetOutboundQCChecklistListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  displayText: string,
  displayOrder: any,
  status: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const inboundQCChecklistListData = useQuery(
    [
      "getOutboundQCChecklistList",
      sorting,
      skipCount,
      maxResultCount,
      displayText,
      displayOrder,
      status,
    ],
    async () => {
      return await handleRequest(
        OutboundQcChecklistService.getApiOutboundApiAppOutboundQCChecklist1(
          sorting,
          skipCount,
          maxResultCount,
          displayText?.trim(),
          displayOrder?.trim(),
          status
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return inboundQCChecklistListData;
};

export const useGetOutboundQCChecklistDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const inboundQCChecklistDetailsData = useQuery(
    ["inboundQCChecklistDetailsView", id],
    async () => {
      return await handleRequest(
        OutboundQcChecklistService.getApiOutboundApiAppOutboundQCChecklist(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return inboundQCChecklistDetailsData;
};
