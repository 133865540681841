import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ToastComponent from "../components/Toast";
import { selectToken } from "../pages/auth/authSlice";

import { ApiError, OpenAPI } from "../services/drop-wms-inbound-services";

export default function useDropWmsInboundApi() {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = process.env.REACT_APP_DROP_WMS_API_ENDPOINT ?? "";
  OpenAPI.TOKEN = token?.toString();

  const handleRequest = useCallback(async function <T>(
    request: Promise<T>,
    toastMessage = true
  ) {
    setIsLoading(true);
    try {
      const response = await request;

      setError(undefined);
      if (!response) {
        toast.error(
          <ToastComponent label="Error" message={"Unable to fetch data"} />,
          {
            toastId: 2,
          }
        );
      }
      return response;
    } catch (exception: any) {
      setError(exception);
      if (exception?.body?.error?.validationErrors) {
        if (toastMessage) {
          const error = exception?.body?.error?.validationErrors?.map(
            (error: any) => {
              return error.message;
            }
          );
          toast.error(<ToastComponent label="Error" message={`${error}`} />, {
            toastId: 1,
          });
        } else {
          return exception?.body;
        }
      } else if (
        exception?.body?.error?.message.includes(
          "Index was outside the bounds of the array."
        )
      ) {
        toast.error(
          <ToastComponent label="Error" message={"Invalid data format"} />
        );
      } else if (exception?.body?.error) {
        toast.error(
          <ToastComponent
            label="Error"
            message={`${exception?.body?.error?.message}`}
          />
        );
      } else if (
        exception.status === 401 ||
        (exception.status === 403 && isEmpty(exception?.body))
      ) {
      } else {
        toast.error(
          <ToastComponent label="Error" message={"Unable to fetch data"} />,
          {
            toastId: 2,
          }
        );
      }
    } finally {
      setIsLoading(false);
    }
  },
  []);

  function dismissError() {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
}
