import { useQuery } from "react-query";

import { OutboundService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseOutboundOrderListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  orderNumber?: string,
  orderDate?: string,
  orderDeliveryDate?: string,
  customerName?: string,
  customerCountry?: string,
  customerLocation?: string,
  freightForwarderName?: string,
  salesOrder?: string,
  schenkerdoRef?: string,
  bluestain?: boolean,
  vas?: boolean,
  pullback?: boolean,
  orderStatus?: any,
  pickingInitializedAt?: string,
  shippedOutAt?: string,
  isOutboundQcChecklistCompleted?: boolean,
  isTransferred?: boolean,
  transferredToWarehouse?: string,
  projectCode?: string,
  licensePlateNumber?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseData = useQuery(
    [
      "getWarehouseOutboundOrderList",
      sorting,
      skipCount,
      maxResultCount,
      orderNumber,
      orderDate,
      orderDeliveryDate,
      customerName,
      customerCountry,
      customerLocation,
      freightForwarderName,
      salesOrder,
      schenkerdoRef,
      bluestain,
      vas,
      pullback,
      orderStatus,
      pickingInitializedAt,
      shippedOutAt,
      isOutboundQcChecklistCompleted,
      isTransferred,
      transferredToWarehouse,
      projectCode,
      licensePlateNumber
    ],
    async () => {
      return await handleRequest(
        OutboundService.getApiOutboundApiAppOutboundOrderList(
          sorting,
          skipCount,
          maxResultCount,
          orderNumber?.trim(),
          orderDate,
          orderDeliveryDate,
          customerName?.trim(),
          customerCountry?.trim(),
          customerLocation?.trim(),
          freightForwarderName?.trim(),
          salesOrder?.trim(),
          schenkerdoRef?.trim(),
          bluestain,
          vas,
          pullback,
          orderStatus,
          pickingInitializedAt,
          shippedOutAt,
          isOutboundQcChecklistCompleted,
          isTransferred,
          transferredToWarehouse?.trim(),
          projectCode?.trim(),
          licensePlateNumber?.trim(),
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetAllWarehouseOutboundOrderStatusListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getAllWarehouseOutboundOrderStatusList"],
    async () => {
      return await handleRequest(
        OutboundService.getApiOutboundApiAppOutboundOrderStatus()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseOutboundOrderDetailListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string,
  tableInfoII: TableParamsType,
  projectCode?: string,
  licensePlateNumber?: string,
  isMagnetized?: boolean,
  isUrgent?: boolean,
  isPicked?: boolean,
  pickedAt?: string
) => {
  const { sorting } = tableInfoII;
  const responseData = useQuery(
    [
      "getWarehouseOutboundOrderDetailList",
      orderId,
      sorting,
      projectCode,
      licensePlateNumber,
      isMagnetized,
      isUrgent,
      isPicked,
      pickedAt,
    ],
    async () => {
      if (orderId) {
        return await handleRequest(
          OutboundService.getApiOutboundApiAppOutboundOrderDetail(
            orderId,
            sorting,
            projectCode?.trim(),
            licensePlateNumber?.trim(),
            isMagnetized,
            isUrgent,
            isPicked,
            pickedAt
          )
        );
      }
    },
    { enabled: !!orderId }
  );

  return responseData;
};
