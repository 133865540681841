import { useQuery } from "react-query";
import { WarehouseUserService } from "../../services/drop-wms-identity-services";
import { TableParamsType } from "../../types/common";

export const useGetWarehouseUserListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  userStatus?: boolean,
  employeeCode?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  email?: string,
  roleName?: string
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const responseListData = useQuery(
    [
      "getWarehouseUserList",
      sorting,
      skipCount,
      maxResultCount,
      employeeCode,
      firstName,
      middleName,
      lastName,
      email,
      userStatus,
      roleName,
    ],
    async () => {
      return await handleRequest(
        WarehouseUserService.getApiIdentityApiAppWarehouseUser1(
          sorting,
          skipCount,
          maxResultCount,
          employeeCode?.trim(),
          firstName?.trim(),
          middleName?.trim(),
          lastName?.trim(),
          email?.trim(),
          userStatus,
          roleName?.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetWarehouseUserDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const detailsData = useQuery(
    ["customerDetailsView", id],
    async () => {
      return await handleRequest(
        WarehouseUserService.getApiIdentityApiAppWarehouseUser(id)
      );
    },
    {
      enabled: !!id,
    }
  );

  return detailsData;
};
