import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/auth/authSlice';
import notifyReducer from '../pages/auth/notify';
import routeReducer from '../pages/configuration/routeSlice';
import viewReducer from '../pages/configuration/viewSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    route: routeReducer,
    notify: notifyReducer,
    view: viewReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
