import { useQuery } from "react-query";
import { TagService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetTagListData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  tagMac: string,
  enrollmentStatus: any,
  status: any,
  tagTypeId: any
) => {
  const { sorting, skipCount, maxResultCount } = tableInfo;
  const tagListData = useQuery(
    [
      "getTagList",
      sorting,
      skipCount,
      maxResultCount,
      tagMac,
      enrollmentStatus,
      status,
      tagTypeId,
    ],
    async () => {
      return await handleRequest(
        TagService.getApiInboundApiAppTag1(
          sorting,
          skipCount,
          maxResultCount,
          tagMac?.trim(),
          enrollmentStatus,
          status,
          tagTypeId
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return tagListData;
};

export const useGetTagDetailData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: any
) => {
  const tagDetailsData = useQuery(
    ["tagDetailsView", id],
    async () => {
      return await handleRequest(TagService.getApiInboundApiAppTag(id));
    },
    {
      enabled: !!id,
    }
  );

  return tagDetailsData;
};
