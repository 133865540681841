import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo-main.svg";
import { useBoolean } from "../../hooks";
import { selectToken } from "../../pages/auth/authSlice";
import { selectAuthRoutesList } from "../../pages/configuration/routeSlice";
import * as routeUrl from "../../routes/routeUrl";
import AuthService from "../../services/authService";
import { getFilteredPermissionData } from "../../utils";
import CheckMenuPermissions from "../../utils/checkMenuPermission";
import CheckPermissions from "../../utils/checkPermission";
import { FirstTextUpperCase, GetImageBase } from "../../utils/global";
import Button from "../Button";
import HandheldAlertPopup from "../HandhledAlertPopup";
import { SkeletonCircle } from "../Skeleton";
import Heading from "../Typography";
import {
  DropDownMenuSuperAdmin,
  MobileNavItem,
  NavDropDown,
  NavMenuCon,
  NavMenuContainer,
  NavMenuMobileContainer,
  NavbarContainerMain,
  ProfileDrop,
  ProfileDropWrapper,
  ProfileDropdownContainer,
  ProfileDropdownMenu,
  ProfileDropdownSubMenu,
} from "./Navbar.styled";
import { setDesktopView } from "../../pages/configuration/viewSlice";
interface NavbarType {
  isAuth: boolean;
  logoData: any;
}

const Navbar = ({ isAuth, logoData }: NavbarType) => {
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const mobileWrapperRef: any = useRef(null);
  const token = useSelector(selectToken);
  const userAuthList = useSelector(selectAuthRoutesList);
  const { desktopPermissionsGroups, handheldPermissionsGroups } =
    getFilteredPermissionData(userAuthList?.auth?.grantedPolicies ?? {});
  const authService = new AuthService();
  const [openSideNav, setOpenSideNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [toggleSetup, setToggleSetup] = useState(false);
  const [toggleInboundSetup, setToggleInboundSetup] = useState(false);
  const [toggleOutboundSetup, setToggleOutboundSetup] = useState(false);
  const [toggleAdministrationSetup, setToggleAdministrationSetup] =
    useState(false);
  const [toggleWarehouseSetup, setToggleWarehouseSetup] = useState(false);
  const [toggleEDILogSetup, setToggleEDILogSetup] = useState(false);
  const [toggleReport, setToggleReport] = useState(false);
  const [toggleTransitOrder, setToggleTransitOrder] = useState(false);
  const [toggleTransitReport, setToggleTransitReport] = useState(false);

  const {
    value: isConfirmOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  const {
    value: isConfirmMobileViewOpen,
    setFalse: closeConfirmMobileViewModal,
    setTrue: openConfirmMobileViewModal,
  } = useBoolean(false);

  const location = useLocation();

  const administrationNavs = [routeUrl.WAREHOUSE, routeUrl.WAREHOUSE_OWNER];

  const warehouseSetupNavs = [
    routeUrl.CUSTOMER,
    routeUrl.SKUS,
    routeUrl.TAGS,
    routeUrl.BASE_STATION,
    routeUrl.MAP,
    routeUrl.LOCATION,
    routeUrl.WAREHOUSE_ROLE,
    routeUrl.WAREHOUSE_USER,
    routeUrl.INBOUND_QC_CHECKLIST,
    routeUrl.OUTBOUND_QC_CHECKLIST,
  ];

  const warehouseInboundNav = [
    routeUrl.WAREHOUSE_INBOUND,
    routeUrl.WAREHOUSE_PUTAWAY,
    routeUrl.WAREHOUSE_INBOUND_CRATE_WITHOUT_TAG,
  ];

  const warehouseOutboundNav = [routeUrl.WAREHOUSE_OUTBOUND_ORDER];

  const warehouseEDINav = [
    routeUrl.WAREHOUSE_EDI_INCOMING_LOG,
    routeUrl.WAREHOUSE_EDI_OUTGOING_LOG,
  ];

  const reportNavs = [
    routeUrl.OVERALL_INVENTORY_REPORT,
    routeUrl.INVENTORY_REPORT,
    routeUrl.DAILY_RECEIVING_REPORT,
    routeUrl.DAILY_OUTGOING_REPORT,
    routeUrl.TRANSFER_ORDER_REPORT,
    routeUrl.TRANSFER_OUT_FUNCTIONAL_ORDER_REPORT,
  ];

  const transitOrderNavs = [
    routeUrl.TRANSIT_PENDING_ORDER,
    routeUrl.TRANSIT_RECEIVED_ORDER,
    routeUrl.TRANSIT_SHIPPED_OUT_ORDER,
  ];

  const transitReportNavs = [
    routeUrl.TRANSIT_INVENTORY_REPORT,
    routeUrl.TRANSIT_TRANSFER_IN_REPORT,
    routeUrl.TRANSIT_OUTGOING_REPORT,
  ];

  const profileNav = [routeUrl.PROFILE_CHANGE_PASSWORD];

  const onSideNavClick = (isClose?: boolean) => {
    setOpenSideNav(!openSideNav);
    if (isClose) {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
  };

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenSideNav(false);
        setOpen(false);
        setToggleSetup(false);
        setToggleInboundSetup(false);
        setToggleOutboundSetup(false);
        setToggleEDILogSetup(false);
        setToggleReport(false);
        setToggleTransitOrder(false);
        setToggleTransitReport(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutsideMobile(event: Event) {
      if (
        mobileWrapperRef.current &&
        mobileWrapperRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setOpenSideNav(false);
        }, 200);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideMobile);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMobile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileWrapperRef]);

  const handleClick = (e: any) => {
    e.preventDefault();
  };
  const getActiveClass = (path: any, navBar: any) => {
    return navBar.some((item: any) => item.url === path);
  };

  return (
    <>
      <NavbarContainerMain hasNotify={false} className="nav">
        {logoData?.status === "loading" ? (
          <div style={{ width: "fit-content" }}>
            <SkeletonCircle size="lg" shape="rounded" />
          </div>
        ) : (
          <Link to={routeUrl.HOME.url} className="brand-logo-nav">
            {logoData?.logoData?.success && !isEmpty(logoData?.logoData) ? (
              <div
                className="flex justify-start align-center position-relative"
                style={{ height: "100%", width: "auto" }}
              >
                <img
                  alt=""
                  style={{ height: "100%", width: "auto" }}
                  src={`${GetImageBase(
                    logoData?.logoData?.data?.name,
                    logoData?.logoData?.data?.content
                  )}`}
                />
              </div>
            ) : (
              <Logo className="nav-brand" />
            )}
          </Link>
        )}

        {isAuth && token && (
          <>
            <div className="nav-mobile items-center">
              <Button
                title="Menu"
                variant="secondary"
                icon="menu"
                onClick={() => onSideNavClick(false)}
                btnClassName="nav-menu"
              />
              <NavMenuMobileContainer
                className={openSideNav ? "top-0" : "-top-[120%] opacity-0"}
                ref={mobileWrapperRef}
              >
                <MobileNavItem className="static navMenuItem">
                  <Heading
                    variant="h6"
                    title="Menu"
                    className="nav-mobile--heading"
                  />
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="/"
                      className={({ isActive }: any) =>
                        isActive ? "active" : ""
                      }
                    >
                      <Heading variant="body" title="Dashboard" />
                    </NavLink>
                  </NavMenuCon>
                </MobileNavItem>

                {CheckMenuPermissions(
                  desktopPermissionsGroups,
                  administrationNavs
                ) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Super Admin Setup"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {administrationNavs.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}

                {CheckMenuPermissions(
                  desktopPermissionsGroups,
                  warehouseSetupNavs
                ) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Warehouse Admin Setup"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {warehouseSetupNavs.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}
                {CheckMenuPermissions(
                  desktopPermissionsGroups,
                  warehouseInboundNav
                ) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Inbound"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {warehouseInboundNav.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}
                {/* hold/unhold  */}
                <CheckPermissions
                  type="View"
                  urlName={routeUrl?.WAREHOUSE_HOLD_UNHOLD.url}
                  key={routeUrl?.WAREHOUSE_HOLD_UNHOLD.url}
                >
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Hold/Unhold"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      <NavMenuCon className="static navMenuItem">
                        <NavLink
                          to={routeUrl?.WAREHOUSE_HOLD_UNHOLD.url}
                          className={({ isActive }: any) =>
                            isActive ? "active" : ""
                          }
                        >
                          {routeUrl?.WAREHOUSE_HOLD_UNHOLD.name}
                        </NavLink>
                      </NavMenuCon>
                    </div>
                  </MobileNavItem>
                </CheckPermissions>

                {CheckMenuPermissions(
                  desktopPermissionsGroups,
                  warehouseOutboundNav
                ) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Outbound"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {warehouseOutboundNav.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}

                {CheckMenuPermissions(desktopPermissionsGroups, warehouseEDINav) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="EDI"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {warehouseEDINav.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}

                {CheckMenuPermissions(desktopPermissionsGroups, reportNavs) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Report"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {reportNavs.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}
                {/* cycle count  */}
                <CheckPermissions
                  type="View"
                  urlName={routeUrl?.WAREHOUSE_CYCLE_COUNT.url}
                  key={routeUrl?.WAREHOUSE_CYCLE_COUNT.url}
                >
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Cycle Count"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      <NavMenuCon className="static navMenuItem">
                        <NavLink
                          to={routeUrl?.WAREHOUSE_CYCLE_COUNT.url}
                          className={({ isActive }: any) =>
                            isActive ? "active" : ""
                          }
                        >
                          {routeUrl?.WAREHOUSE_CYCLE_COUNT.name}
                        </NavLink>
                      </NavMenuCon>
                    </div>
                  </MobileNavItem>
                </CheckPermissions>

                {CheckMenuPermissions(desktopPermissionsGroups, transitOrderNavs) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Orders"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {transitOrderNavs.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}

                {CheckMenuPermissions(desktopPermissionsGroups, transitReportNavs) && (
                  <MobileNavItem className="static navMenuItem">
                    <Heading
                      variant="h6"
                      title="Report"
                      className="nav-mobile--heading"
                    />
                    <div className="nav-items">
                      {transitReportNavs.map((item) => (
                        <CheckPermissions
                          type="View"
                          urlName={item.url}
                          key={item.url}
                        >
                          <NavMenuCon className="static navMenuItem">
                            <NavLink
                              to={item.url}
                              className={({ isActive }: any) =>
                                isActive ? "active" : ""
                              }
                            >
                              {item.name}
                            </NavLink>
                          </NavMenuCon>
                        </CheckPermissions>
                      ))}
                    </div>
                  </MobileNavItem>
                )}

                <MobileNavItem className="static navMenuItem">
                  <Heading
                    variant="h6"
                    title={`${FirstTextUpperCase(
                      userAuthList?.currentUser?.name
                    )}`}
                    className="nav-mobile--heading"
                  />

                  <div className="nav-items">
                    <NavMenuCon className="static navMenuItem">
                      <NavLink
                        to={routeUrl.PROFILE_CHANGE_PASSWORD.url}
                        className={({ isActive }: any) =>
                          isActive ? "active" : ""
                        }
                      >
                        {routeUrl.PROFILE_CHANGE_PASSWORD.name}
                      </NavLink>
                    </NavMenuCon>
                    {Object.keys(handheldPermissionsGroups).length > 0 && (
                      <Heading
                        variant="body"
                        title="Switch to Handheld"
                        onClick={openConfirmMobileViewModal}
                        className="cursor-pointer"
                      />
                    )}
                    <Heading
                      variant="body"
                      title="Logout"
                      onClick={openConfirmModal}
                      className="cursor-pointer"
                    />
                  </div>
                </MobileNavItem>

                <div className="nav-close">
                  <Button
                    title="Close"
                    variant="primary-dark"
                    icon="close"
                    onClick={() => onSideNavClick(false)}
                    btnClassName="nav-mobile--close"
                  />
                </div>
              </NavMenuMobileContainer>
            </div>
            <NavMenuContainer className="relative nav-main">
              <NavMenuCon className="static navMenuItem">
                <NavLink
                  to="/"
                  className={({ isActive }: any) => (isActive ? "active" : "")}
                >
                  Dashboard
                </NavLink>
              </NavMenuCon>
              {CheckMenuPermissions(
                desktopPermissionsGroups,
                warehouseSetupNavs.concat(administrationNavs)
              ) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleSetup(!toggleSetup)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="/setup"
                      onClick={handleClick}
                      className={
                        getActiveClass(
                          location.pathname,
                          warehouseSetupNavs.concat(administrationNavs)
                        )
                          ? "active"
                          : ""
                      }
                    >
                      Setup
                    </NavLink>
                  </NavMenuCon>
                  {toggleSetup && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          administrationNavs
                        ) && (
                          <NavMenuCon
                            className="submenu"
                            onMouseOver={() => {
                              setToggleAdministrationSetup(true);
                              setToggleWarehouseSetup(false);
                            }}
                          >
                            <NavLink
                              to="#"
                              className={({ isActive }: any) =>
                                isActive && toggleAdministrationSetup
                                  ? "active"
                                  : ""
                              }
                            >
                              Super Admin Setup
                            </NavLink>
                          </NavMenuCon>
                        )}
                        {toggleAdministrationSetup && (
                          <ProfileDropdownSubMenu
                            ref={wrapperRef}
                            className="profiler-nav-subMenu"
                          >
                            <NavDropDown>
                              {administrationNavs.map((item) => (
                                <CheckPermissions
                                  type="View"
                                  urlName={item.url}
                                  key={item.url}
                                >
                                  <NavMenuCon className="submenu">
                                    <NavLink
                                      to={item.url}
                                      className={({ isActive }: any) =>
                                        isActive ? "active" : ""
                                      }
                                    >
                                      {item.name}
                                    </NavLink>
                                  </NavMenuCon>
                                </CheckPermissions>
                              ))}
                            </NavDropDown>
                          </ProfileDropdownSubMenu>
                        )}
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          warehouseSetupNavs
                        ) && (
                          <NavMenuCon
                            className="submenu"
                            onMouseOver={() => {
                              setToggleAdministrationSetup(false);
                              setToggleWarehouseSetup(true);
                            }}
                          >
                            <NavLink
                              to="#"
                              className={({ isActive }: any) =>
                                isActive && toggleWarehouseSetup ? "active" : ""
                              }
                            >
                              Warehouse Admin Setup
                            </NavLink>
                          </NavMenuCon>
                        )}
                        {toggleWarehouseSetup && (
                          <ProfileDropdownSubMenu
                            ref={wrapperRef}
                            className="profiler-nav-subMenu"
                          >
                            <NavDropDown>
                              {warehouseSetupNavs.map((item) => (
                                <CheckPermissions
                                  type="View"
                                  urlName={item.url}
                                  key={item.url}
                                >
                                  <NavMenuCon className="submenu">
                                    <NavLink
                                      to={item.url}
                                      className={({ isActive }: any) =>
                                        isActive && toggleWarehouseSetup
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      {item.name}
                                    </NavLink>
                                  </NavMenuCon>
                                </CheckPermissions>
                              ))}
                            </NavDropDown>
                          </ProfileDropdownSubMenu>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}
              {CheckMenuPermissions(desktopPermissionsGroups, warehouseInboundNav) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleInboundSetup(!toggleInboundSetup)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="inbound"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, warehouseInboundNav)
                          ? "active"
                          : ""
                      }
                    >
                      Inbound
                    </NavLink>
                  </NavMenuCon>
                  {toggleInboundSetup && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          warehouseInboundNav
                        ) && (
                          <NavDropDown>
                            {warehouseInboundNav.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}
              <CheckPermissions
                type="View"
                urlName={routeUrl.WAREHOUSE_HOLD_UNHOLD.url}
              >
                <NavMenuCon className="static navMenuItem">
                  <NavLink
                    to={routeUrl.WAREHOUSE_HOLD_UNHOLD.url}
                    className={({ isActive }: any) =>
                      isActive ? "active" : ""
                    }
                  >
                    {routeUrl.WAREHOUSE_HOLD_UNHOLD.name}
                  </NavLink>
                </NavMenuCon>
              </CheckPermissions>

              {CheckMenuPermissions(
                desktopPermissionsGroups,
                warehouseOutboundNav
              ) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleOutboundSetup(!toggleOutboundSetup)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="outbound"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, warehouseOutboundNav)
                          ? "active"
                          : ""
                      }
                    >
                      Outbound
                    </NavLink>
                  </NavMenuCon>
                  {toggleOutboundSetup && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          warehouseOutboundNav
                        ) && (
                          <NavDropDown>
                            {warehouseOutboundNav.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}
              {/* EDI Nav */}
              {CheckMenuPermissions(desktopPermissionsGroups, warehouseEDINav) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleEDILogSetup(!toggleEDILogSetup)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="edi"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, warehouseEDINav)
                          ? "active"
                          : ""
                      }
                    >
                      EDI
                    </NavLink>
                  </NavMenuCon>
                  {toggleEDILogSetup && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          warehouseEDINav
                        ) && (
                          <NavDropDown>
                            {warehouseEDINav.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}
              {/* Report Nav */}
              {CheckMenuPermissions(desktopPermissionsGroups, reportNavs) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleReport(!toggleReport)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="report"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, reportNavs)
                          ? "active"
                          : ""
                      }
                    >
                      Report
                    </NavLink>
                  </NavMenuCon>
                  {toggleReport && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          reportNavs
                        ) && (
                          <NavDropDown>
                            {reportNavs.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}

              {/* CycleCount Nav  */}
              <CheckPermissions
                type="View"
                urlName={routeUrl.WAREHOUSE_CYCLE_COUNT.url}
              >
                <NavMenuCon className="static navMenuItem">
                  <NavLink
                    to={routeUrl.WAREHOUSE_CYCLE_COUNT.url}
                    className={({ isActive }: any) =>
                      isActive ? "active" : ""
                    }
                  >
                    {routeUrl.WAREHOUSE_CYCLE_COUNT.name}
                  </NavLink>
                </NavMenuCon>
              </CheckPermissions>

              {/* Transit Report Nav */}
              {CheckMenuPermissions(desktopPermissionsGroups, transitOrderNavs) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleTransitOrder(!toggleTransitOrder)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="report"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, transitOrderNavs)
                          ? "active"
                          : ""
                      }
                    >
                      Orders
                    </NavLink>
                  </NavMenuCon>
                  {toggleTransitOrder && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          transitOrderNavs
                        ) && (
                          <NavDropDown>
                            {transitOrderNavs.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}

              {/* Transit Inventory Report Nav  */}
              {CheckMenuPermissions(desktopPermissionsGroups, transitReportNavs) && (
                <div
                  className="flex items-center gap-x-2 relative"
                  onClick={() => setToggleTransitReport(!toggleTransitReport)}
                >
                  <NavMenuCon className="static navMenuItem">
                    <NavLink
                      to="report"
                      onClick={handleClick}
                      className={
                        getActiveClass(location.pathname, transitReportNavs)
                          ? "active"
                          : ""
                      }
                    >
                      Report
                    </NavLink>
                  </NavMenuCon>
                  {toggleTransitReport && (
                    <ProfileDropdownMenu
                      ref={wrapperRef}
                      className="nav-subMenu"
                    >
                      <NavDropDown>
                        {CheckMenuPermissions(
                          desktopPermissionsGroups,
                          transitReportNavs
                        ) && (
                          <NavDropDown>
                            {transitReportNavs.map((item) => (
                              <CheckPermissions
                                type="View"
                                urlName={item.url}
                                key={item.url}
                              >
                                <NavMenuCon className="submenu">
                                  <NavLink
                                    to={item.url}
                                    className={({ isActive }: any) =>
                                      isActive ? "active" : ""
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavMenuCon>
                              </CheckPermissions>
                            ))}
                          </NavDropDown>
                        )}
                      </NavDropDown>
                    </ProfileDropdownMenu>
                  )}
                </div>
              )}
            </NavMenuContainer>

            <ProfileDropdownContainer className="nav-main">
              {/* <Notification
                NotificationCount={NotificationCount}
                isModalOpen={isModalOpen}
                openModal={() => openModal()}
              /> */}
              <ProfileDropWrapper>
                <ProfileDrop onClick={() => setOpen(!open)}>
                  <div
                    className={`font-semibold title ${
                      getActiveClass(location.pathname, profileNav)
                        ? "dropdownActive"
                        : ""
                    } `}
                  >
                    {`${FirstTextUpperCase(userAuthList?.currentUser?.name)}`}
                  </div>
                  <span>
                    <span className="material-symbols-rounded downIcon">
                      arrow_drop_down
                    </span>
                  </span>
                </ProfileDrop>
                {open && (
                  <ProfileDropdownMenu ref={wrapperRef}>
                    <NavLink to={`${routeUrl?.PROFILE_CHANGE_PASSWORD.url}`}>
                      <DropDownMenuSuperAdmin
                        className={`logoutBtn ${
                          getActiveClass(location.pathname, profileNav)
                            ? "dropdownActive"
                            : ""
                        }`}
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <h1 className="para" role="button">
                          {routeUrl?.PROFILE_CHANGE_PASSWORD.name}
                        </h1>
                      </DropDownMenuSuperAdmin>
                    </NavLink>
                    {Object.keys(handheldPermissionsGroups).length > 0 && (
                      <DropDownMenuSuperAdmin
                        className="logoutBtn"
                        onClick={() => {
                          openConfirmMobileViewModal();
                          // setOpen(!open);
                        }}
                      >
                        <h1 className="para" role="button">
                          Switch to Handheld
                        </h1>
                      </DropDownMenuSuperAdmin>
                    )}
                    <DropDownMenuSuperAdmin
                      className="logoutBtn"
                      onClick={() => {
                        openConfirmModal();
                        setOpen(!open);
                      }}
                    >
                      <h1 className="para" role="button">
                        Logout
                      </h1>
                    </DropDownMenuSuperAdmin>
                  </ProfileDropdownMenu>
                )}
              </ProfileDropWrapper>
            </ProfileDropdownContainer>
          </>
        )}
      </NavbarContainerMain>
      {isConfirmOpen && (
        <HandheldAlertPopup
          isShow={isConfirmOpen}
          closeModal={closeConfirmModal}
          title="Logout"
          handleSubmit={() => authService.logout()}
        />
      )}
      {isConfirmMobileViewOpen && (
        <HandheldAlertPopup
          isShow={isConfirmMobileViewOpen}
          closeModal={closeConfirmMobileViewModal}
          title="Switch to Handheld"
          handleSubmit={() => {
            window.sessionStorage.setItem("desktop", `${false}`);
            dispatch(
              setDesktopView({
                desktopView: false,
              })
            );
            closeConfirmMobileViewModal();
          }}
        />
      )}
    </>
  );
};

export default Navbar;
