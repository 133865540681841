import { useQuery } from "react-query";
import { HandheldVasConfirmService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetHandheldVASConfirmByProject = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { filter } = tableInfo; //Filter by Project#
  const responseData = useQuery(
    ["getHandheldVASConfirmByProject", filter],
    async () => {
      return await handleRequest(
        HandheldVasConfirmService.getApiOutboundApiAppHandheldVAsConfirmVASProjectList(
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetHandheldVASConfirmByOrder = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string
) => {
  const responseData = useQuery(
    ["getHandheldVASConfirmByOrder", orderId],
    async () => {
      return await handleRequest(
        HandheldVasConfirmService.getApiOutboundApiAppHandheldVAsConfirmOrderDetail(
          orderId
        )
      );
    },
    {
      enabled: !!orderId,
      keepPreviousData: true,
    }
  );
  return responseData;
};
