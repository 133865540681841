import { ROLES_PERMISSION_LIST } from "./../constants/common";
import _ from "lodash";
import * as Yup from "yup";
import { SUPPORTED_IMAGE_FORMATS } from "../constants/common";
import moment from "moment";
// import { useSelector } from 'react-redux';
// import { selectAuthRoutesList } from '../pages/configuration/routeSlice';

export const getFormData = (data: any) => {
  const formData = new FormData(); // Must be FormData so that the ajax request will be Form post
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

export const taxFormatWithDash = (value: string) => {
  const taxNumber = value?.replace(/[^\d]/g, "");
  const formattedTax = `${taxNumber.slice(0, 2)}-${taxNumber.slice(2, 9)}`;
  return formattedTax;
};
export const CommaFormatted = (
  amount: any,
  name?: string,
  setFieldValue?: any
) => {
  const numberValue = parseInt(amount?.toString().replaceAll(",", ""));
  const value = numberValue.toLocaleString();

  if (name && amount) setFieldValue(name, value);
  return value;
};

export const getNonUsCountryValidation = (
  isOtherCountry: boolean | undefined,
  fieldName: string
) => {
  if (isOtherCountry) {
    return Yup.string()
      .required("Required field")
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blank spaces")
      .nullable();
  } else {
    return Yup.string()
      .matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`)
      .matches(/^\s*\S[\s\S]*$/, "This field cannot contain only blank spaces")
      .nullable();
  }
};

export const getUsCountryValidation = (isCountryUS: boolean | undefined) => {
  if (!isCountryUS) {
    return Yup.string().required("Required field").nullable();
  } else {
    return Yup.string().nullable();
  }
};

export const setDashAsEmptyValue = (values: any) => values || "-";

export const phoneFormat = (
  name: string,
  setFieldValue: any,
  value: string
) => {
  if (!value) setFieldValue(name, value);
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    setFieldValue(name, phoneNumber);
  } else if (phoneNumberLength < 7) {
    setFieldValue(name, `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`
    );
  }
};

export const setObjectNullValue = (data: any) => {
  const nullableData = Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, v === false ? false : v || null])
  );
  return nullableData;
};

export const setArrayOfObjectNullValue = (arrData: Array<any>) => {
  const nullableArrayData = arrData.map((item: any) =>
    Object.fromEntries(
      Object.entries(item).map(([k, v]) => [k, v === false ? false : v || null])
    )
  );
  return nullableArrayData;
};

export const getFileExtension = (name: string) => {
  const fileArray = name.split(".");
  const fileExtension = fileArray[fileArray.length - 1];

  return fileExtension;
};

export const handleOpenFile = (fileName: string, fileContent: string) => {
  const fileExtension = getFileExtension(fileName);

  if (SUPPORTED_IMAGE_FORMATS.includes(fileExtension)) {
    var image = new Image();
    image.src = `data:image/${fileExtension};base64,` + fileContent;
    var w: any = window.open("");
    w.document.write(image.outerHTML);
    w.document.close();
  } else if (fileExtension === "pdf") {
    let pdfWindow: any = window.open("");
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ` +
        encodeURI(fileContent) +
        "'></iframe>"
    );
  } else {
    const aElement = document.createElement("a");
    let downloadLink =
      `data:application/${fileExtension};base64,` + fileContent;
    aElement.setAttribute("download", fileName);
    aElement.setAttribute("href", downloadLink);
    aElement.click();
  }
};

export const handleDownloadFile = (fileName: string, fileContent: string) => {
  const fileExtension = getFileExtension(fileName);

  const aElement = document.createElement("a");
  let downloadLink = `data:application/${fileExtension};base64,` + fileContent;
  aElement.setAttribute("download", fileName);
  aElement.setAttribute("href", downloadLink);
  aElement.click();
};

export const getPopoverGridSystemRow = (data: Array<any>) => {
  const dataLength = _.range(1, 11);
  let row: number = 1;
  dataLength.forEach((item) => {
    if (data.length >= item * 5) {
      row = row + 1;
    }
  });
  return row;
};

export const getPopoverGridSystemColumn = (data: Array<any>) => {
  if (data.length <= 2) {
    return 1;
  } else if (data.length <= 4) {
    return 2;
  } else if (data.length <= 6) {
    return 3;
  } else if (data.length > 6) {
    return 4;
  }
};

export const handleFilterPermissionList = (data: {
  [key: string]: {
    groupKey: string;
    key: string;
    text: string;
  }[];
}) => {
  let rolesObj: any = {};
  let permissionInfo = "DropWMS.WarehouseUser";
  Object.entries(data).forEach(([key]) => {
    const rolesAllData = data[`${key}`].map((itemData) => itemData.text);

    const newData: any = data[`${key}`];
    ROLES_PERMISSION_LIST.forEach((item) => {
      if (!rolesAllData.includes(item)) {
        newData.push({
          groupKey: data[`${key}`][0].groupKey,
          key: `${permissionInfo}${data[`${key}`][0].groupKey}.${item}`,
          text: item,
          isDisabled: true,
        });
      }
    });
    const filteredRoles = newData.sort((a: any, b: any) => {
      const A = a["text"];
      const B = b["text"];
      return ROLES_PERMISSION_LIST.indexOf(A) > ROLES_PERMISSION_LIST.indexOf(B)
        ? 1
        : -1;
    });
    rolesObj[key] = filteredRoles;
  });
  return rolesObj;
};

export const handleHandheldFilterPermissionList = (data: {
  [key: string]: {
    groupKey: string;
    key: string;
    text: string;
  }[];
}) => {
  let rolesObj: any = {};
  Object.entries(data).forEach(([key]) => {
    const newData: any = data[`${key}`];
    rolesObj[key] = newData;
  });
  return rolesObj;
};

export const MaterialIconFilled = {
  fontVariationSettings: `"FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48`,
};

export const handleFocus = (event: any, fieldName: string) => {
  event.preventDefault();
  if (event?.keyCode === 13) {
    document.getElementsByName(`${fieldName}`)[0].focus();
  }
};

export const handleKeyPress = (event: any) => {
  const regex = /^[a-zA-Z0-9#$%^&*()@!-/.,]*$/;
  const key = event.key;

  if (!regex.test(key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const handleKeyNumberPress = (event: any) => {
  const regex = /^[0-9]*$/;
  const key = event.key;

  if (!regex.test(key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const handleKeyDecimalNumberPress = (event: any) => {
  const regex = /^[0-9.]*$/;
  const key = event.key;

  if (!regex.test(key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const FontSizeHtml = 10;

export const rem = (size: number) => {
  const remSize = size / FontSizeHtml;
  //Default font size on html element is 100%, equivalent to 16px;
  return `${remSize}rem`;
};

export const GetImageBase = (name: any, image: any) => {
  const splittedText = name.split(".");
  let data = "data:image/jpeg;base64";
  if (splittedText[splittedText.length - 1] === "svg")
    data = "data:image/svg+xml;base64";
  return `${data},${image}`;
};

export const GetToday = moment().format("yyyy-MM-DD");

export const WeekStartDate = moment().startOf("week").format("yyyy-MM-DD");
export const WeekEndDate = moment().endOf("week").format("yyyy-MM-DD");

export const MonthlyDate = moment().startOf("month").format("yyyy-MM-DD");
export const LastDateOfMonth = moment().endOf("month").format("YYYY-MM-DD");

export const YearDate = moment().startOf("year").format("yyyy-MM-DD");

export const Today = moment().format("dddd DD MMMM, YYYY");

export const FirstTextUpperCase = (text: string | any) => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1);
};

export const ConvertToPercentage = (data: any) => {
  const total = data?.reduce((sum: number, value: number) => sum + value, 0);
  const percentages = data?.map((value: number) => {
    return `${Math.round((value / total) * 100)}`;
  });
  return percentages;
};

export const getFullName = (data: any) => {
  return `${data.firstName?? ""} ${data.middleName?? ""} ${data.lastName?? ""}`.trim();
}
