/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfAbp_Application_Services_Dto_PagedResultDto_1OfOfDrop_WMS_Shared_Hosting_Interfaces_Reports_OverallInventoryReportDto_Drop_WMS_Shared_Hosting_Abp_Version_7_3_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfAbp_Application_Services_Dto_PagedResultDto_1OfOfDrop_WMS_Shared_Hosting_Interfaces_Reports_OverallInventoryReportDto_Drop_WMS_Shared_Hosting_Abp_Version_7_3_0_0_Culture_neutral_PublicKeyToken_null';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OverallInventoryReportService {

    /**
     * @param tenantId
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param projectCode
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptFromDate
     * @param receiptToDate
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfAbp_Application_Services_Dto_PagedResultDto_1OfOfDrop_WMS_Shared_Hosting_Interfaces_Reports_OverallInventoryReportDto_Drop_WMS_Shared_Hosting_Abp_Version_7_3_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportReport(
        tenantId: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptFromDate?: string,
        receiptToDate?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfAbp_Application_Services_Dto_PagedResultDto_1OfOfDrop_WMS_Shared_Hosting_Interfaces_Reports_OverallInventoryReportDto_Drop_WMS_Shared_Hosting_Abp_Version_7_3_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TenantId': tenantId,
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptFromDate': receiptFromDate,
                'ReceiptToDate': receiptToDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tenantId
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param projectCode
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptFromDate
     * @param receiptToDate
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportExportExcel(
        tenantId: string,
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptFromDate?: string,
        receiptToDate?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/export-excel',
            query: {
                'TenantId': tenantId,
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptFromDate': receiptFromDate,
                'ReceiptToDate': receiptToDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tenantId
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param projectCode
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptFromDate
     * @param receiptToDate
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportExportPdf(
        tenantId: string,
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptFromDate?: string,
        receiptToDate?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/export-pdf',
            query: {
                'TenantId': tenantId,
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptFromDate': receiptFromDate,
                'ReceiptToDate': receiptToDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param tenantId
     * @param tagMac
     * @param skuPartNumber
     * @param locationName
     * @param projectCode
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param asnNumber
     * @param receiptFromDate
     * @param receiptToDate
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportExportCsv(
        tenantId: string,
        tagMac?: string,
        skuPartNumber?: string,
        locationName?: string,
        projectCode?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        asnNumber?: string,
        receiptFromDate?: string,
        receiptToDate?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/export-csv',
            query: {
                'TenantId': tenantId,
                'TagMac': tagMac,
                'SKUPartNumber': skuPartNumber,
                'LocationName': locationName,
                'ProjectCode': projectCode,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'AsnNumber': asnNumber,
                'ReceiptFromDate': receiptFromDate,
                'ReceiptToDate': receiptToDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportExportAllExcel(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/export-all-excel',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppOverallInventoryReportExportAllCsv(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/overall-inventory-report/export-all-csv',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
