import { useQuery } from "react-query";
import { HandheldDashboardService } from "../../services/drop-wms-inbound-services";
import { HandheldDashboardService as HandheldOutboundDashboardService } from "../../services/drop-wms-outbound-services";

export const useGetHandheldDashboardData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getHandheldDashboardData"],
    async () => {
      return await handleRequest(
        HandheldDashboardService.getApiInboundApiAppHandheldDashboardCount()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};

export const useGetHandheldOutboundDashboardData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const responseListData = useQuery(
    ["getHandheldOutboundDashboardData"],
    async () => {
      return await handleRequest(
        HandheldOutboundDashboardService.getApiOutboundApiAppHandheldDashboardCount()
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseListData;
};
