// Global font size
export const DEFAULT_FONT_SIZE = 10;

// Heading Tags
export const HEADING_TAGS = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];

// Supported Images Extension
export const SUPPORTED_IMAGE_FORMATS = ["jpg", "jpeg", "gif", "png"];

// Roles Permission List
export const ROLES_PERMISSION_LIST = ["View", "Create", "Edit", "Delete"];

export const initialHeight = window.innerHeight;

export const DATE_FORMAT = "dd/MM/yyyy";
export const INPUT_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_WITH_TIME = "dd-MM-yyyy LT";

export const FILE_SIZE = 1024 * 1024 * 10;
export const SUPPORTED_FORMATS = [
  { type: "jpg", format: "image/jpg" },
  { type: "jpeg", format: "image/jpeg" },
  { type: "png", format: "image/png" },
  { type: "pdf", format: "application/pdf" },
  { type: "doc", format: "application/msword" },
  {
    type: "docx",
    format:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
];

export const YesNoOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const UserStatusOptions = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Deactivated",
    value: false,
  },
];

export const STEP_OPTIONS = [
  {
    label: "Company Details",
    value: 1,
  },
  {
    label: "Company & Wealthlane Contacts",
    value: 2,
  },
  {
    label: "Review",
    value: 3,
  },
];

export const EnrollmentOptions = [
  {
    label: "Enrolled",
    value: "true",
  },
  {
    label: "Not Enrolled",
    value: "false",
  },
];

export const StatusOptions = [
  {
    label: "Active",
    value: "true",
  },
  {
    label: "Inactive",
    value: "false",
  },
];

export const StatusTypeOptions = [
  {
    label: "True",
    value: "true",
  },
  {
    label: "False",
    value: "false",
  },
];

export const StatusTypeYNOptions = [
  {
    label: "Y",
    value: "true",
  },
  {
    label: "N",
    value: "false",
  },
];

export const StatusInboundTypeOptions = [
  {
    label: "Open",
    value: "false",
  },
  {
    label: "Closed",
    value: "true",
  },
];

export const TagTypeOptions = [
  {
    label: "Eink",
    value: "1",
  },
  {
    label: "Paper",
    value: "2",
  },
];

export const LocationTypeOptions = [
  {
    label: "Floor",
    value: "1",
  },
  {
    label: "Rack",
    value: "2",
  },
];

export const WarehouseTypeOptions = [
  {
    label: "Functional",
    value: "1",
  },
  {
    label: "Transit",
    value: "2",
  },
];

export const QcChecklistStatusTypeOptions = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "2",
  },
  {
    label: "N/A",
    value: "3",
  },
];

export const PutawayStatusOptions = [
  {
    label: "Putaway Pending",
    value: "false",
  },
  {
    label: "Putaway Confirmed",
    value: "true",
  },
];

export const PutawayMoveStatusOptions = [
  {
    label: "Putaway",
    value: "P",
  },
  {
    label: "Move",
    value: "M",
  },
];

export const HoldStatusOptions = [
  {
    label: "Hold",
    value: "true",
  },
  {
    label: "Unhold",
    value: "false",
  },
];

export const LocalizationData: any = {
  receivedLPCount: "Received ",
  inboundQCChecklistCompletedCount: "Inbound QC Checklist Completed",
  putawayCount: "Putaways/Move",
  allocatedCount: "Allocated",
  pickedCount: "Picked",
  vasConfirmedCount: "VAS Confirmed",
  outboundQCChecklistCompletedCount: "Outbound QC Checklist Completed",
  shippedCount: "Shipped",
  transferredCount: "Transferred",

  receivedQty: "Received Qty",
  inboundQCChecklistQty: "Inbound QC Checklist Qty",
  putawayQty: "Putaway Qty",
  allocatedQty: "Allocated Qty",
  outboundQCChecklistQty: "Outbound QC Checklist Qty",
  pickedQty: "Picked Qty",
  shippedOutQty: "Shipped Out Qty",
  transferredQty: "Transferred Qty",
};
