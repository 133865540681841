/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_LocationService_Interfaces_Locations_CreateUpdateLocationDto } from '../models/Drop_WMS_LocationService_Interfaces_Locations_CreateUpdateLocationDto';
import type { Drop_WMS_Shared_Hosting_Enums_LocationType } from '../models/Drop_WMS_Shared_Hosting_Enums_LocationType';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationService {

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppLocation(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/location/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiLocationApiAppLocation(
id: string,
requestBody?: Drop_WMS_LocationService_Interfaces_Locations_CreateUpdateLocationDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/location/api/app/location/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiLocationApiAppLocation(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/location/api/app/location/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param displayName 
     * @param locationTypeId 
     * @param warehouseMapId 
     * @param baseStationId 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppLocation1(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
displayName?: string,
locationTypeId?: Drop_WMS_Shared_Hosting_Enums_LocationType,
warehouseMapId?: string,
baseStationId?: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/location',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'DisplayName': displayName,
                'LocationTypeId': locationTypeId,
                'WarehouseMapId': warehouseMapId,
                'BaseStationId': baseStationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiLocationApiAppLocation(
requestBody?: Drop_WMS_LocationService_Interfaces_Locations_CreateUpdateLocationDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_LocationService_Interfaces_Locations_LocationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/location/api/app/location',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param locationTypeId 
     * @param locationName 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppLocationLocations(
locationTypeId?: Drop_WMS_Shared_Hosting_Enums_LocationType,
locationName?: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/location/locations',
            query: {
                'LocationTypeId': locationTypeId,
                'LocationName': locationName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skipCount 
     * @param maxResultCount 
     * @param locationTypeId 
     * @param locationName 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiLocationApiAppLocationLocationList(
skipCount?: number,
maxResultCount?: number,
locationTypeId?: Drop_WMS_Shared_Hosting_Enums_LocationType,
locationName?: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_LocationService_Interfaces_Locations_GetLocationDto_Drop_WMS_LocationService_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/location/location-list',
            query: {
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'LocationTypeId': locationTypeId,
                'LocationName': locationName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiLocationApiAppLocationLocationTypes(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_Shared_Hosting_Dtos_DropdownDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/location/api/app/location/location-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
