/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerCreateUpdateDto } from '../models/Drop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerCreateUpdateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseOwnerService {

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseOwner(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse-owner/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiAdministrationApiAppWarehouseOwner(
        id: string,
        requestBody?: Drop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/administration/api/app/warehouse-owner/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiAdministrationApiAppWarehouseOwner(
        id: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/administration/api/app/warehouse-owner/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param warehouseOwnerName
     * @param storerKey
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseOwner1(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        warehouseOwnerName?: string,
        storerKey?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse-owner',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'WarehouseOwnerName': warehouseOwnerName,
                'StorerKey': storerKey,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiAdministrationApiAppWarehouseOwner(
        requestBody?: Drop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerCreateUpdateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/administration/api/app/warehouse-owner',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiAdministrationApiAppWarehouseOwnerWarehouseOwners(): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_AdministrationService_Interfaces_WarehouseOwners_WarehouseOwnerDto_Drop_WMS_AdministrationService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/administration/api/app/warehouse-owner/warehouse-owners',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
