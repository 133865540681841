/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateByProjectDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateByProjectDto';
import type { Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateDto } from '../models/Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Projects_ProjectDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Projects_ProjectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HoldService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param isHold
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Projects_ProjectDto Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHoldHoldUnholdProjectList(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        isHold?: boolean,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_InboundService_Interfaces_Projects_ProjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/hold/hold-unhold-project-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'IsHold': isHold,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param projectId
     * @param isHold
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiInboundApiAppHoldHoldUnholdLPListByProject(
        projectId: string,
        isHold?: boolean,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Collections_Generic_List_1OfOfDrop_WMS_InboundService_Interfaces_LicensePlates_LicensePlateDto_Drop_WMS_InboundService_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/inbound/api/app/hold/hold-unhold-lPList-by-project',
            query: {
                'ProjectId': projectId,
                'IsHold': isHold,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHoldHoldOrUnholdLicensePlate(
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/hold/hold-or-unhold-license-plate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiInboundApiAppHoldHoldOrUnholdLicensePlateByProject(
        requestBody?: Drop_WMS_InboundService_Interfaces_LicensePlates_HoldLicensePlateByProjectDto,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/inbound/api/app/hold/hold-or-unhold-license-plate-by-project',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
