import { useQuery } from "react-query";
import { HandheldOutboundService } from "../../services/drop-wms-outbound-services";
import { TableParamsType } from "../../types/common";

export const useGetHandheldOutboundData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { filter } = tableInfo; //Filter by Project#
  const responseData = useQuery(
    ["getHandheldOutboundList", filter],
    async () => {
      return await handleRequest(
        HandheldOutboundService.getApiOutboundApiAppHandheldOutboundInitializePickedOrderList(
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetHandheldOutboundByOrderData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  orderId: string
) => {
  const responseData = useQuery(
    ["getHandheldOutboundListByOrder", orderId],
    async () => {
      return await handleRequest(
        HandheldOutboundService.getApiOutboundApiAppHandheldOutboundOrderDetailByOrderId(
          orderId
        )
      );
    },
    {
      enabled: !!orderId,
      keepPreviousData: true,
    }
  );
  return responseData;
};
