/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserCreateDto } from '../models/Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserCreateDto';
import type { Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserUpdateDto } from '../models/Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserUpdateDto';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseUserService {

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppWarehouseUser(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/warehouse-user/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static putApiIdentityApiAppWarehouseUser(
id: string,
requestBody?: Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserUpdateDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/identity/api/app/warehouse-user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static deleteApiIdentityApiAppWarehouseUser(
id: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/identity/api/app/warehouse-user/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param employeeCode 
     * @param firstName 
     * @param middleName 
     * @param lastName 
     * @param email 
     * @param userStatus 
     * @param roleName 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppWarehouseUser1(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
employeeCode?: string,
firstName?: string,
middleName?: string,
lastName?: string,
email?: string,
userStatus?: boolean,
roleName?: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/warehouse-user',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'EmployeeCode': employeeCode,
                'FirstName': firstName,
                'MiddleName': middleName,
                'LastName': lastName,
                'Email': email,
                'UserStatus': userStatus,
                'RoleName': roleName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppWarehouseUser(
requestBody?: Drop_WMS_Interfaces_WarehouseUsers_WarehouseUserCreateDto,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Interfaces_WarehouseUsers_WarehouseUserDto_Drop_WMS_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/warehouse-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId 
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppWarehouseUserResetPassword(
userId: string,
): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfSystem_Boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/warehouse-user/reset-password/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
