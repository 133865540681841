import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinners from '../../components/Spinner';
import AuthService from '../../services/authService';

const SignOutCallback = () => {
  const navigate = useNavigate();
  const authService = new AuthService();
  useEffect(() => {
    if (!authService.isAuthenticated()) {
        navigate('/login');
    } else {
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Spinners />;
};

export default SignOutCallback;
