import { useQuery } from "react-query";
import { HandheldInboundQcChecklistService } from "../../services/drop-wms-inbound-services";
import { TableParamsType } from "../../types/common";

export const useGetHandheldInboundQCChecklistData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  licensePlate: string
) => {
  const responseData = useQuery(
    ["getHandheldInboundQCCheckList", licensePlate],
    async () => {
      if (licensePlate) {
        return await handleRequest(
          HandheldInboundQcChecklistService.getApiInboundApiAppHandheldInboundQCChecklistLPInboundQCChecklistByLPNumber(
            licensePlate.trim()
          )
        );
      }
    },
    {
      keepPreviousData: false,
    }
  );

  return responseData;
};

export const useGetAllInCompletedHandheldInboundQCChecklistData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
) => {
  const { skipCount, maxResultCount, filter } = tableInfo; //Filter by LP#
  const responseData = useQuery(
    [
      "getAllInCompletedHandheldInboundQCCheckList",
      skipCount,
      maxResultCount,
      filter,
    ],
    async () => {
      return await handleRequest(
        HandheldInboundQcChecklistService.getApiInboundApiAppHandheldInboundQCChecklistIncompleteInboundLPList(
          skipCount,
          maxResultCount,
          filter.trim()
        )
      );
    },
    {
      keepPreviousData: true,
    }
  );

  return responseData;
};

export const useGetInboundQCChecklistImageData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  licensePlate: any
) => {
  const inboundQCChecklistImagesData = useQuery(
    ["inboundQCChecklistImagesView", licensePlate],
    async () => {
      return await handleRequest(
        HandheldInboundQcChecklistService.getApiInboundApiAppHandheldInboundQCChecklistInboundQCChecklistImage(
          licensePlate?.trim()
        )
      );
    },
    {
      enabled: !!licensePlate,
    }
  );

  return inboundQCChecklistImagesData;
};

export const useGetInboundQCChecklistLPInboundQCChecklistByLPData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  licensePlateId: string
) => {
  const responseData = useQuery(
    ["getHandheldInboundQCChecklistLPInboundQCChecklistByLP", licensePlateId],
    async () => {
      return await handleRequest(
        HandheldInboundQcChecklistService.getApiInboundApiAppHandheldInboundQCChecklistLPInboundQCChecklistByLP(
          licensePlateId
        )
      );
    },
    {
      enabled: !!licensePlateId,
    }
  );
  return responseData;
};
