/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting';
import type { Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitTransferInReportDto } from '../models/Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitTransferInReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransitTransferInReportService {

    /**
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param receivedFromDate
     * @param receivedToDate
     * @param receivedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitTransferInReportDto Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitTransferInReportReport(
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        receivedFromDate?: string,
        receivedToDate?: string,
        receivedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfVolo_Abp_Application_Dtos_PagedResultDto_1OfOfDrop_WMS_OutboundService_Interfaces_Reports_TransitTransferInReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-transfer-in-report/report',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceivedFromDate': receivedFromDate,
                'ReceivedToDate': receivedToDate,
                'ReceivedBy': receivedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param receivedFromDate
     * @param receivedToDate
     * @param receivedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitTransferInReportExportExcel(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        receivedFromDate?: string,
        receivedToDate?: string,
        receivedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-transfer-in-report/export-excel',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceivedFromDate': receivedFromDate,
                'ReceivedToDate': receivedToDate,
                'ReceivedBy': receivedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param receivedFromDate
     * @param receivedToDate
     * @param receivedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitTransferInReportExportPdf(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        receivedFromDate?: string,
        receivedToDate?: string,
        receivedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-transfer-in-report/export-pdf',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceivedFromDate': receivedFromDate,
                'ReceivedToDate': receivedToDate,
                'ReceivedBy': receivedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param warehouse
     * @param locationName
     * @param customerName
     * @param customerCountry
     * @param orderNumber
     * @param skuPartNumber
     * @param licensePlateNumber
     * @param crateNumber
     * @param tote
     * @param receivedFromDate
     * @param receivedToDate
     * @param receivedBy
     * @param putawayFromDate
     * @param putawayToDate
     * @param putawayBy
     * @param projectCode
     * @returns Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting Success
     * @throws ApiError
     */
    public static getApiOutboundApiAppTransitTransferInReportExportCsv(
        warehouse?: string,
        locationName?: string,
        customerName?: string,
        customerCountry?: string,
        orderNumber?: string,
        skuPartNumber?: string,
        licensePlateNumber?: string,
        crateNumber?: string,
        tote?: string,
        receivedFromDate?: string,
        receivedToDate?: string,
        receivedBy?: string,
        putawayFromDate?: string,
        putawayToDate?: string,
        putawayBy?: string,
        projectCode?: string,
    ): CancelablePromise<Drop_WMS_Shared_Hosting_Models_ResponseModel_1OfOfDrop_WMS_Shared_Hosting_Dtos_ExportFileBlobDto_Drop_WMS_Shared_Hosting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/outbound/api/app/transit-transfer-in-report/export-csv',
            query: {
                'Warehouse': warehouse,
                'LocationName': locationName,
                'CustomerName': customerName,
                'CustomerCountry': customerCountry,
                'OrderNumber': orderNumber,
                'SKUPartNumber': skuPartNumber,
                'LicensePlateNumber': licensePlateNumber,
                'CrateNumber': crateNumber,
                'Tote': tote,
                'ReceivedFromDate': receivedFromDate,
                'ReceivedToDate': receivedToDate,
                'ReceivedBy': receivedBy,
                'PutawayFromDate': putawayFromDate,
                'PutawayToDate': putawayToDate,
                'PutawayBy': putawayBy,
                'ProjectCode': projectCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
